import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useEffect, useState } from 'react';

import { FinanceData, LoansFinanceData } from '../../types';
import { Logger } from '../../utils';

interface UseLoansDataParams {
  estate: EstateDetail;
  bankCode: string;
  financeData: FinanceData;
  handleNoFinancingData: () => void;
}

interface UseLoansDataReturn {
  loansData: LoansFinanceData | null;
  isFetchingLoansData: boolean;
}

export const useFetchLoansData = ({
  estate,
  bankCode,
  financeData,
  handleNoFinancingData,
}: UseLoansDataParams): UseLoansDataReturn => {
  const [loansData, setLoansData] = useState<LoansFinanceData | null>(null);
  const [isFetchingLoansData, setIsFetchingLoansData] = useState(false);

  useEffect(() => {
    if (estate && financeData && !!bankCode) {
      setIsFetchingLoansData(true);

      const estatePrice = estate.priceNumeric;
      const downPayment = Math.round(estatePrice * 0.2);
      const buyEstate = 1;
      const financingParams = JSON.parse(JSON.stringify(financeData));

      const query = {
        bankCode,
        price: estatePrice,
        downPayment: downPayment,
        creditIntention: buyEstate,
        ...financingParams,
      };

      ApiClientProvider.getApiClient()
        .getLoans(query)
        .then(({ data }) => {
          setLoansData(data);
          if (!data.loansConditions) {
            handleNoFinancingData();
          }
        })
        .catch((e) => {
          Logger.error(e);
          setLoansData(null);
          handleNoFinancingData();
        })
        .finally(() => {
          setIsFetchingLoansData(false);
        });
    }
  }, [estate, bankCode, financeData, handleNoFinancingData]);

  return { loansData, isFetchingLoansData };
};
