import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const BoyCap: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 25 25" {...props}>
    <path
      d="M9.5 13.7305C9.91421 13.7305 10.25 13.3947 10.25 12.9805C10.25 12.5663 9.91421 12.2305 9.5 12.2305C9.08579 12.2305 8.75 12.5663 8.75 12.9805C8.75 13.3947 9.08579 13.7305 9.5 13.7305Z"
      fill="inherit"
    />
    <path
      d="M15.5 13.7305C15.9142 13.7305 16.25 13.3947 16.25 12.9805C16.25 12.5663 15.9142 12.2305 15.5 12.2305C15.0858 12.2305 14.75 12.5663 14.75 12.9805C14.75 13.3947 15.0858 13.7305 15.5 13.7305Z"
      fill="inherit"
    />
    <path
      d="M9.16406 15.7735C9.48844 16.3685 9.97254 16.8611 10.5618 17.1959C11.1511 17.5306 11.8221 17.6941 12.4993 17.668C13.1765 17.6941 13.8475 17.5306 14.4368 17.1959C15.0261 16.8611 15.5102 16.3685 15.8346 15.7735L15.1641 15.4375C14.9014 15.9076 14.5129 16.2951 14.0422 16.5567C13.5715 16.8182 13.0372 16.9433 12.4993 16.918C11.9614 16.9433 11.4271 16.8182 10.9564 16.5567C10.4857 16.2951 10.0972 15.9076 9.83456 15.4375L9.16406 15.7735Z"
      fill="inherit"
    />
    <path
      d="M21.4985 9.34118H19.8912C19.2295 7.99577 18.2136 6.85613 16.9527 6.04476C15.6919 5.23339 14.2338 4.78096 12.7351 4.73611C11.2364 4.69126 9.7538 5.05568 8.44669 5.7902C7.13957 6.52472 6.05734 7.60157 5.31631 8.90501C4.57528 10.2084 4.20347 11.6892 4.24085 13.1881C4.27822 14.687 4.72337 16.1473 5.52844 17.4122C6.33351 18.6771 7.46807 19.6987 8.81016 20.3671C10.1523 21.0356 11.6512 21.3257 13.1457 21.2062C14.6428 21.067 16.0747 20.5268 17.2906 19.6425C18.5066 18.7582 19.4617 17.5625 20.0555 16.1812C20.8133 14.5028 20.9556 12.6111 20.4575 10.8382H21.4985C21.6974 10.8382 21.8882 10.7592 22.0288 10.6185C22.1695 10.4779 22.2485 10.2871 22.2485 10.0882C22.2477 9.88979 22.1683 9.6998 22.0278 9.55979C21.8872 9.41979 21.6969 9.34118 21.4985 9.34118ZM18.1745 9.34118H15.4985V6.94343C16.5897 7.48782 17.5141 8.31609 18.1745 9.34118ZM13.9985 6.40493V9.34118H10.9985V6.40493C11.985 6.17294 13.012 6.17294 13.9985 6.40493ZM9.4985 6.94343V9.34343H6.8225C7.48263 8.31751 8.40704 7.48844 9.4985 6.94343ZM19.2485 12.9809C19.2507 13.9604 19.0397 14.9285 18.6302 15.8183C18.2207 16.708 17.6225 17.498 16.8771 18.1333C16.1317 18.7686 15.257 19.2342 14.3136 19.4975C13.3703 19.7609 12.3809 19.8158 11.4142 19.6585C10.4474 19.5011 9.52655 19.1353 8.7154 18.5864C7.90425 18.0374 7.22228 17.3185 6.71682 16.4796C6.21136 15.6407 5.89453 14.7018 5.78832 13.7281C5.68211 12.7545 5.78906 11.7694 6.10175 10.8412H18.8952C19.1281 11.5306 19.2475 12.2533 19.2485 12.9809Z"
      fill="inherit"
    />
  </SvgIcon>
);
