import { Box, InputAdornment } from '@mui/material';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import { theme } from '@portals/core/src/themes/sde/main';
import { ChevronLeft, ChevronRight, Close } from '@portals/icons';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { usePreventSafariAutozoom } from '../../../hooks';
import { SimpleEstateDetails } from '../../SimpleEstateDetails/SimpleEstateDetails';

interface Props {
  onClose: () => void;
  estates?: Array<EstateItem>;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const MapEstateDialog: React.FunctionComponent<Props> = ({ onClose, estates }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const preventSafariAutozoom = usePreventSafariAutozoom();

  useEffect(() => {
    setCurrentIndex(0);
  }, [estates]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const showPreviousEstate = useCallback(() => {
    setCurrentIndex((prevState) => {
      return prevState - 1;
    });
  }, []);

  const showNextEstate = useCallback(() => {
    setCurrentIndex((prevState) => {
      return prevState + 1;
    });
  }, []);

  if (estates.length === 0) {
    return null;
  }

  const estate = estates[currentIndex];

  return (
    <Box
      position="absolute"
      bottom={30}
      left={0}
      right={0}
      zIndex={40}
      display="flex"
      justifyContent="center"
      sx={{ pointerEvents: 'none' }}
    >
      <Box
        width={{
          xs: 'auto',
          lg: '732px',
        }}
      >
        <Box display="flex" justifyContent="flex-end" pb={4}>
          {estates.length > 1 && (
            <Box mx={1} sx={{ pointerEvents: 'auto' }} width="100%" textAlign="center">
              <TextField
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '90px',
                    paddingLeft: theme.spacing(4),
                    paddingRight: theme.spacing(4),
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: 'center',
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                value={t('objectPagination', { current: currentIndex + 1, max: estates.length })}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start" sx={{ '&.MuiInputAdornment-positionStart': { marginRight: 0 } }}>
                      <IconButton onClick={showPreviousEstate} disabled={currentIndex === 0}>
                        <ChevronLeft />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ '&.MuiInputAdornment-positionEnd': { marginLeft: 0 } }}>
                      <IconButton onClick={showNextEstate} disabled={currentIndex === estates.length - 1}>
                        <ChevronRight />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...preventSafariAutozoom}
              ></TextField>
            </Box>
          )}
          <Box marginRight={{ xs: 5, lg: 0 }} sx={{ pointerEvents: 'auto' }}>
            <IconButton
              onClick={handleClose}
              sx={{
                borderRadius: '50%',
                backgroundColor: '#FFF',
                border: '1px solid',
                borderColor: 'grey.500',
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box p={5} bgcolor="#FFFFFF" sx={{ pointerEvents: 'auto' }}>
          <SimpleEstateDetails
            title={estate.title}
            id={estate.id}
            price={estate.price}
            eyeCatcher={estate.eyeCatcher}
            images={estate.images}
            partnerLogo={estate.partnerLogo}
            mainFacts={estate.mainFacts}
            subtitle={estate.subtitle}
          />
        </Box>
      </Box>
    </Box>
  );
};

MapEstateDialog.displayName = 'MapEstateDialog';
