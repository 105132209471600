import { Option } from './estateAd';

export enum StructureOptions {
  NOT_SPECIFIED = 'not_specified',
  OLD_BUILDING = 'old_building',
  NEW_BUILDING = 'new_building',
}

export const getAllStructureOptions = (t: (a: string) => string): Option[] => {
  return [
    {
      value: StructureOptions.NOT_SPECIFIED,
      label: t('forms.estateAd.structureData.structure.radioGroup.opt1'),
    },
    {
      value: StructureOptions.OLD_BUILDING,
      label: t('forms.estateAd.structureData.structure.radioGroup.opt2'),
    },
    {
      value: StructureOptions.NEW_BUILDING,
      label: t('forms.estateAd.structureData.structure.radioGroup.opt3'),
    },
  ];
};
