import { alpha, Box, Grid, Paper } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import { Location } from '@portals/icons';
import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { find, get, map } from 'lodash-es';
import React from 'react';

import { CustomizeSearchButton } from '../../components';
import { Criteria } from '../../config';
import { useLocationFormatter, usePriceFormatter, useRoomFormatter, useSquareMetersFormatter } from '../../hooks';
import { Area, Currency, PropertySize, Room } from '../../icons';
import { type FilterValuesProps } from '../../types';
import { type ShowResultFilterDrawerType } from '../EstateResult/useFilterDrawer/useFilterDrawer';
import { ReadonlyFilterInput } from './ReadonlyFilterInput/ReadonlyFilterInput';

const filterConfig = [
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.FLAT,
    fields: [Criteria.PRICE, Criteria.LIVING_SPACE, Criteria.ROOMS],
  },
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.HOUSE,
    fields: [Criteria.PRICE, Criteria.LIVING_SPACE, Criteria.ROOMS],
  },
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.PROPERTY,
    fields: [Criteria.PRICE, Criteria.PROPERTY_SIZE],
  },
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.FORECLOSURE,
    fields: [Criteria.MARKET_VALUE],
  },
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.INTEREST,
    fields: [Criteria.PRICE],
  },
  {
    marketingType: MarketingType.BUY,
    objectType: ObjectType.BUSINESS,
    fields: [Criteria.PRICE, Criteria.TOTAL_SPACE],
  },
  {
    marketingType: MarketingType.RENT,
    objectType: ObjectType.FLAT,
    fields: [Criteria.RENT, Criteria.LIVING_SPACE, Criteria.ROOMS],
  },
  {
    marketingType: MarketingType.RENT,
    objectType: ObjectType.HOUSE,
    fields: [Criteria.RENT, Criteria.LIVING_SPACE, Criteria.ROOMS],
  },
  {
    marketingType: MarketingType.RENT,
    objectType: ObjectType.PROPERTY,
    fields: [Criteria.LEASE, Criteria.PROPERTY_SIZE],
  },
  {
    marketingType: MarketingType.RENT,
    objectType: ObjectType.BUSINESS,
    fields: [Criteria.RENT, Criteria.TOTAL_SPACE],
  },
];

interface EstateResultFilterViewProps {
  filterValues: FilterValuesProps;
  numberOfActiveFilters: number;
  showResultFilterDrawer: ShowResultFilterDrawerType;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateResultFilterView: React.FC<EstateResultFilterViewProps> = ({
  filterValues,
  numberOfActiveFilters,
  showResultFilterDrawer,
}: EstateResultFilterViewProps) => {
  const priceFormatter = usePriceFormatter();
  const squareMetersFormatter = useSquareMetersFormatter();
  const roomFormatter = useRoomFormatter();
  const locationFormatter = useLocationFormatter();

  const renderDynamicFilterInputs = () => {
    const singleFilterConfig = find(filterConfig, {
      marketingType: filterValues.marketingType,
      objectType: filterValues.objectType,
    });
    if (get(singleFilterConfig, 'fields', []).length === 0) {
      return null;
    }

    return map(singleFilterConfig.fields, (field) => {
      switch (field) {
        case Criteria.PRICE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Currency}
                label={t('price')}
                value={priceFormatter(filterValues.minPrice, filterValues.maxPrice, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.PRICE)}
              />
            </Grid>
          );
        case Criteria.MARKET_VALUE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Currency}
                label={t('price')}
                value={priceFormatter(filterValues.minMarketValue, filterValues.maxMarketValue, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.MARKET_VALUE)}
              />
            </Grid>
          );
        case Criteria.RENT:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Currency}
                label={t('price')}
                value={priceFormatter(filterValues.minRent, filterValues.maxRent, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.RENT)}
              />
            </Grid>
          );
        case Criteria.LEASE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Currency}
                label={t('price')}
                value={priceFormatter(filterValues.minLease, filterValues.maxLease, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.LEASE)}
              />
            </Grid>
          );
        case Criteria.LIVING_SPACE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Area}
                label={t('livingSpace')}
                value={squareMetersFormatter(
                  filterValues.minLivingSpace,
                  filterValues.maxLivingSpace,
                  t('livingSpace')
                )}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.LIVING_SPACE)}
              />
            </Grid>
          );
        case Criteria.PROPERTY_SIZE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={PropertySize}
                label={t('propertySize')}
                value={squareMetersFormatter(
                  filterValues.minPropertySize,
                  filterValues.maxPropertySize,
                  t('propertySize')
                )}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.PROPERTY_SIZE)}
              />
            </Grid>
          );
        case Criteria.TOTAL_SPACE:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Area}
                label={t('totalSpace')}
                value={squareMetersFormatter(filterValues.minTotalSpace, filterValues.maxTotalSpace, t('totalSpace'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.TOTAL_SPACE)}
              />
            </Grid>
          );
        case Criteria.ROOMS:
          return (
            <Grid item xs key={field}>
              <ReadonlyFilterInput
                Icon={Room}
                label={t('room')}
                value={roomFormatter(filterValues.minNumberRooms, filterValues.maxNumberRooms, t('room'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.ROOMS)}
              />
            </Grid>
          );
        default:
          return null;
      }
    });
  };

  return (
    <Paper
      sx={{
        width: '100%',
        boxShadow: `0px 2px 3px ${alpha(theme.palette.grey['main'], 0.1)}`,
        borderRadius: 1,
        pb: 2,
        mb: 2,
      }}
    >
      <Box px={5} pt={6} pb={5}>
        <Grid container columnSpacing={5}>
          <Grid item xs>
            <ReadonlyFilterInput
              Icon={Location}
              label={t('where')}
              value={locationFormatter(filterValues.zipCityEstateId, t('where'))}
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => showResultFilterDrawer(false, Criteria.ZIP_CITY_ESTATE_ID)}
            />
          </Grid>
          {renderDynamicFilterInputs()}
          <Grid item xs="auto">
            <CustomizeSearchButton
              numberOfActiveFilters={numberOfActiveFilters}
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                showResultFilterDrawer(true, null);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

EstateResultFilterView.displayName = 'EstateResultFilterView';
