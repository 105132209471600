import { Box, GlobalStyles } from '@mui/material';
import { FitToBoundsHandler } from '@portals/maps/src/components/FitToBoundsHandler/FitToBoundsHandler';
import { MapContainer } from '@portals/maps/src/containers/MapContainer/MapContainer';
import { MarkerClusterGroup } from '@portals/maps/src/containers/MarkerClusterGroup/MarkerClusterGroup';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { LatLngExpression } from 'leaflet';
import { filter, map } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from '../../../components';
import { useIsBookmarked } from '../../../hooks';
import { Heart } from '../../../icons';
import { MapEstateDialog } from '../MapEstateDialog/MapEstateDialog';
import { EstateMarker } from './Marker/EstateMarker';

interface Props {
  estates?: Array<EstateItem>;
}

const markerGlobalStyles = (
  <GlobalStyles
    styles={`
      .estate-maps .marker-cluster div {
        width: 38px;
        height: 38px;
        margin-left: 1px;
        margin-top: 1px;
        border-radius: 19px;
      }
      
      .estate-maps .marker-cluster-small,
      .estate-maps .marker-cluster-medium, 
      .estate-maps .marker-cluster-large,
      {      
        background-color: #FFFFFF;
      }   
    
      .estate-maps .marker-cluster-small div,
      .estate-maps .marker-cluster-medium div,
      .estate-maps .marker-cluster-large div {
        background-color: #FF0000;
      }
      
      .estate-maps .marker-cluster span {
        font-family: Sparkasse Medium;
        font-style: normal;
        font-weight: normal;
        color: #FFFFFF;
        font-size: 20px;
        line-height: 38px;
      }
    `}
  />
);

const getCoordinatesFromEstates = (estates) =>
  map(estates, ({ lat, lng }) => ({
    lat,
    lng,
  }));

export const EstateResultMapView: React.FunctionComponent<Props> = ({ estates }: Props) => {
  const [filteredClusterEstates, setFilteredClusterEstates] = useState([]);
  const TILE_URL = ConfigProvider.getConfig().get('YELLOWMAP_TILE_URL');
  const { toggleBookmark } = useIsBookmarked();
  const { bookmarks } = useContext(UserContext);

  useEffect(() => {
    hideEstateDetails();
  }, [estates]);

  // todo: we need the savedSearchIncluded
  const savedSearchedEstates = map(estates, ({ id }) => {
    return id;
  });

  const coordinates = useMemo<LatLngExpression[]>(() => {
    return getCoordinatesFromEstates(estates);
  }, [estates]);

  const center = coordinates.length > 0 ? coordinates[0] : undefined;

  const findEstatesByBounds = (currentBounds) => {
    return filter(estates, ({ lat, lng }) => {
      return currentBounds.contains({
        lat,
        lng,
      });
    });
  };

  const findEstatesByLatAndLng = (markerLat, markerLng) => {
    return filter(estates, ({ lat, lng }) => {
      return lat === markerLat && lng === markerLng;
    });
  };

  const showFilteredClusterEstate = ({ lat, lng }) => {
    const filteredEstates = findEstatesByLatAndLng(lat, lng);
    setFilteredClusterEstates(filteredEstates);
  };

  const onClickEstateMarker = useCallback(({ latlng }) => {
    showFilteredClusterEstate(latlng);
  }, []);

  const renderMarker = (estateItem) => {
    const isBookmarked = bookmarks?.includes(estateItem.id);
    return (
      <EstateMarker
        key={estateItem.id}
        position={{
          lat: estateItem.lat,
          lng: estateItem.lng,
        }}
        onClick={onClickEstateMarker}
      >
        <Box display="flex" alignItems="center" whiteSpace="nowrap">
          {savedSearchedEstates[estateItem.id] !== undefined && (
            <Box
              display="flex"
              pr={1}
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                toggleBookmark(estateItem.id);
              }}
            >
              <Heart fontSize="inherit" color={isBookmarked ? 'primary' : 'secondary'} />
            </Box>
          )}
          {estateItem.price}
        </Box>
      </EstateMarker>
    );
  };

  const hideEstateDetails = useCallback(() => {
    setFilteredClusterEstates([]);
  }, []);

  const showFilteredClusterEstates = (e) => {
    const parentChildClusterLength = e.propagatedFrom.__parent._childClusters.length;
    const hasNoChilCclusters = e.propagatedFrom._childClusters.length === 0;
    if (parentChildClusterLength === 1 && hasNoChilCclusters) {
      const currentBounds = e.propagatedFrom.getBounds();
      const filteredEstates = findEstatesByBounds(currentBounds);
      setFilteredClusterEstates(filteredEstates);
    }
  };

  const onClickMarkerClusterGroup = useCallback((e) => {
    showFilteredClusterEstates(e);
  }, []);

  if (estates.length === 0) {
    return null;
  }

  return (
    <Box position="relative" height={1} overflow="hidden">
      <Box position="absolute" left={0} top={0} height={1} width={1}>
        <MapContainer
          className="estate-maps"
          containerProps={{
            width: '100%',
            height: '100%',
          }}
          style={{ width: '100%', height: '100%' }}
          center={center}
          zoom={12}
          minZoom={7}
          maxZoom={18}
          url={TILE_URL}
          zoomControl={true}
          scrollWheelZoom={false}
        >
          <FitToBoundsHandler coordinates={coordinates} padding={12} />
          {markerGlobalStyles}
          <MarkerClusterGroup spiderfyOnMaxZoom={false} showCoverageOnHover={false} onClick={onClickMarkerClusterGroup}>
            {map(estates, (estate) => {
              return renderMarker(estate);
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </Box>
      <MapEstateDialog onClose={hideEstateDetails} estates={filteredClusterEstates} />
    </Box>
  );
};

EstateResultMapView.displayName = 'EstateResultMapView';
