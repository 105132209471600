import {
  Autocomplete as MuiAutocomplete,
  InputAdornment,
  Popper,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { Location } from '@portals/icons';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import i18next from 'i18next';
import { debounce, isEqual, sortBy, trim } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { if6CssPrefix, maxZipCityEstateIds } from '../../../config';
import { usePreventSafariAutozoom } from '../../../hooks';
import { Logger } from '../../../utils';
import AutocompleteListItem from './AutocompleteListItem/AutocompleteListItem';
import AutocompletePills from './AutocompletePills/AutocompletePills';

interface Option {
  value: number;
  label: string;
}

export interface AutocompleteProps {
  preSelectedOptions?: Option[];
  onSelectedOptions: (selectedOptions: Option[]) => void;
  textFieldProps?: TextFieldProps;
  onNothingSelected?: (searchIsEmpty: boolean) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const Autocomplete: React.FC<AutocompleteProps> = ({
  preSelectedOptions,
  onSelectedOptions,
  textFieldProps,
  onNothingSelected,
}: AutocompleteProps) => {
  const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions ? preSelectedOptions : []);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const preventSafariAutozoom = usePreventSafariAutozoom();

  useEffect(() => {
    if (!isEqual(sortBy(preSelectedOptions), sortBy(selectedOptions))) {
      onSelectedOptions(selectedOptions);
    }
  }, [selectedOptions, onSelectedOptions]);

  const fetchCities = async (term: string) => {
    const trimmedTerm = trim(term);
    if (!trimmedTerm) {
      setOptions([]);
      return;
    }

    try {
      const citiesResponse = await ApiClientProvider.getApiClient().getAutoCompleteOptions(trimmedTerm);
      setOptions(citiesResponse);
    } catch (e) {
      Logger.error(e);
    }
  };

  const debounceFetch = useRef(debounce((term) => fetchCities(term), 300)).current;
  useEffect(() => {
    debounceFetch(value);
  }, [value, debounceFetch]);

  const handleOnBlur = useCallback(() => {
    if (selectedOptions.length === 0) {
      onNothingSelected(value === '');
    }
  }, [onNothingSelected, selectedOptions.length, value]);

  const handleDelete = useCallback(
    (optionKey) => {
      setSelectedOptions((selectedOptions) => selectedOptions.filter((option) => option.value !== optionKey));
      if (selectedOptions.length === 1) {
        setValue('');
        onNothingSelected(true);
      }
    },
    [onNothingSelected, selectedOptions.length]
  );

  const handleOnChange = useCallback(
    (event, newValue, reason) => {
      if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Backspace' && reason === 'removeOption') {
        return;
      }
      if ((event as React.KeyboardEvent).key === 'Enter' && reason === 'createOption') {
        return;
      }
      if (newValue.length > maxZipCityEstateIds) {
        enqueueSnackbar(t('whereOptionsMaximum'));
        return;
      }
      setSelectedOptions(newValue);
    },
    [enqueueSnackbar, t]
  );

  const renderPopperList = useCallback((params, option, state) => {
    return (
      <Fragment key={option.value}>
        <AutocompleteListItem label={option.label} params={params} state={state.selected} />
      </Fragment>
    );
  }, []);

  const getOptionLabel = useCallback((option) => option.label, []);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onBlur = useCallback(() => handleOnBlur(), [handleOnBlur]);

  const isOptionEqualToValue = useCallback((option, selectedOption) => option.value === selectedOption.value, []);

  const renderInput = useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Location />
              </InputAdornment>
            ),
            endAdornment: null,
          }}
          onChange={onChange}
          onBlur={onBlur}
          label={t('where')}
          placeholder={t('where')}
          {...textFieldProps}
          {...preventSafariAutozoom}
          sx={{
            '&.MuiFormControl-root>div>.MuiAutocomplete-input': { width: 'auto' },
          }}
          className="zip-city-input"
        />
      );
    },
    [onBlur, onChange, preventSafariAutozoom, t, textFieldProps]
  );

  const CustomPopper = useCallback(
    (props) => {
      if (trim(value) === '') {
        return null;
      }

      return (
        <Popper
          {...props}
          placement="bottom-start"
          modifiers={[{ name: 'flip', enabled: false }]}
          sx={{
            [`& .${autocompleteClasses.paper}`]: {
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '12px',
            },
            [`& .${autocompleteClasses.listbox} li:last-child hr`]: {
              display: 'none',
            },
          }}
        />
      );
    },
    [theme.palette.grey, value]
  );

  return (
    <>
      <MuiAutocomplete
        freeSolo
        data-testid="zipCityEstateIdAutocomplete"
        fullWidth
        disableCloseOnSelect
        multiple
        inputValue={value}
        renderOption={renderPopperList}
        value={selectedOptions}
        options={options ?? []}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        onChange={handleOnChange}
        isOptionEqualToValue={isOptionEqualToValue}
        componentsProps={{ paper: { className: if6CssPrefix } }}
        ListboxProps={{ className: 'city-autocomplete' }}
        PopperComponent={CustomPopper}
        sx={{
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': { paddingLeft: theme.spacing(4) },
        }}
      />
      <AutocompletePills options={selectedOptions} onDelete={handleDelete} />
    </>
  );
};

Autocomplete.displayName = 'Autocomplete';
