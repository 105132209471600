import { Box, Typography } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { ArrowRight } from '@portals/icons';
import i18next from 'i18next';
import React, { FunctionComponent } from 'react';

import { getExternalFinanceCertificateUrl } from '../../../../../utils/financeCertificateUtils';
import InfoSection from '../InfoSection/InfoSection';

interface FinanceCertificateProps {
  bankCode: string;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const FinanceCertificate: FunctionComponent<FinanceCertificateProps> = ({ bankCode }) => {
  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box bgcolor="#292929" borderRadius={2} p={9}>
        <Typography variant="h4" color="#FFFFFF">
          {t('detailPage.financeCertificate')}
        </Typography>
        <Typography variant="body2" color="#BEBEBE" mb={9}>
          {t('detailPage.financeCertificateDescription')}
        </Typography>
        <Button fullWidth endIcon={<ArrowRight />} component="a" href={getExternalFinanceCertificateUrl(bankCode)}>
          {t('detailPage.financeButtonLabel')}
        </Button>
      </Box>
      <InfoSection>{t('detailPage.financeText')}</InfoSection>
    </Box>
  );
};

FinanceCertificate.displayName = 'FinanceCertificate';

export default FinanceCertificate;
