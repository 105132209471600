import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CheckCircleOutline, ChevronDown } from '@portals/icons';
import i18next from 'i18next';
import { find } from 'lodash-es';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { if6CssPrefix } from '../../../config';
import { usePreventSafariAutozoom } from '../../../hooks/usePreventSafariAutozoom/usePreventSafariAutozoom';
import { stylesFn } from './EstateSelect.styles';

interface Props {
  items: any;
  onChange: (any) => void;
  formatValue: (any) => string;
  label?: string;
  selectProps?: SelectProps;
  customInput?: boolean;
  customInputPlaceholder?: string;
  initialValue?: string | number;
  testId?: string;
  selectStyles?: SxProps;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const CustomSelect: FunctionComponent<Props> = ({
  items,
  onChange,
  formatValue,
  selectProps,
  label,
  customInput,
  customInputPlaceholder,
  initialValue,
  testId,
  selectStyles,
}: Props) => {
  const theme = useTheme();
  const styles = stylesFn(theme);

  const getInitialValueForCustomUserInput = () => {
    const initialValueExistsInDropdown = find(items, ['value', initialValue]);
    if (initialValue && !initialValueExistsInDropdown) {
      return {
        value: initialValue.toString(),
        label: formatValue(initialValue),
      };
    }
    return { value: '', label: '' };
  };

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue ? initialValue : '');
  const [userValue, setUserValue] = useState(getInitialValueForCustomUserInput());

  const preventSafariAutozoom = usePreventSafariAutozoom();

  const handleChange = useCallback(
    (event) => {
      if (event.target.value !== '') {
        setSelectedValue(event.target.value);
        setOpen(false);
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  const handleCustomText = useCallback(
    (event) => {
      setSelectedValue(event.target.value);

      setUserValue({
        value: event.target.value,
        label: formatValue(event.target.value),
      });
    },
    [formatValue]
  );

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        setOpen(false);
        onChange(selectedValue);
      }
    },
    [onChange, selectedValue]
  );

  const handleOnClose = (event, reason) => {
    onChange(selectedValue);
    if (reason === 'backdropClick') {
      setOpen(false);
    }
  };

  const handleOnOpen = useCallback(() => setOpen(true), []);

  const checkIfTypeIsOther = useCallback(
    (value) => {
      return value === Number.MAX_VALUE ? t('estateSearch.any') : formatValue(value);
    },
    [formatValue, t]
  );

  const menuListProps = {
    MenuListProps: {
      className: 'estate-custom-select-menu-list',
    },
  };

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel id={`estate-search-extended-filter-label_${label}`} sx={styles.inputLabel}>
          {label}
        </InputLabel>
      )}
      <Select
        data-testid={testId}
        className="estate-custom-select"
        variant="standard"
        label={label ? label : undefined}
        labelId={`estate-search-extended-filter-label_${label}`}
        value={selectedValue}
        IconComponent={ChevronDown}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true,
          marginThreshold: null,
          disableAutoFocusItem: true,
          onClose: handleOnClose,
          className: if6CssPrefix,
          ...menuListProps,
        }}
        open={open}
        renderValue={checkIfTypeIsOther}
        onOpen={handleOnOpen}
        {...selectProps}
        sx={{ ...styles.select, ...selectStyles } as SxProps}
      >
        {items.map((item) => {
          return (
            <MenuItem sx={styles.menuItem} key={item.value} value={item.value} disableRipple disableTouchRipple>
              <Typography variant="button">{item.label}</Typography>
              {selectedValue === item.value && <CheckCircleOutline display="inline-flex" />}
            </MenuItem>
          );
        })}
        {customInput && (
          <MenuItem sx={styles.hiddenMenuItem} value={userValue.value}>
            {userValue.label}
          </MenuItem>
        )}
        {customInput && (
          <MenuItem
            value=""
            disableRipple
            disableTouchRipple
            className={userValue.value ? 'menu-item-textfield-highlighted' : 'menu-item-textfield-default'}
            sx={styles.customInputMenuItem}
          >
            <TextField
              className="menu-item-textfield"
              fullWidth
              variant="outlined"
              value={userValue.value}
              onChange={handleCustomText}
              onKeyDown={handleKeyDown}
              placeholder={customInputPlaceholder}
              {...preventSafariAutozoom}
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

CustomSelect.displayName = 'CustomSelect';
