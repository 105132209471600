import { Avatar, Box, Grid, Link, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { CheckboxInput } from '@portals/forms/src/components/CheckboxInput/CheckboxInput';
import { EmailInput } from '@portals/forms/src/components/EmailInput/EmailInput';
import { PhoneInput } from '@portals/forms/src/components/PhoneInput/PhoneInput';
import { TextInput } from '@portals/forms/src/components/TextInput/TextInput';
import { ZipCodeInput } from '@portals/forms/src/components/ZipCodeInput/ZipCodeInput';
import { CheckCircleOutline } from '@portals/icons';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateContactMessageProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { Form, Formik } from 'formik';
import i18next from 'i18next';
import { Trans } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { boolean, object, string } from 'yup';

import { useIF6Context } from '../../../../../../context';
import { useBankData, useBankDataFromConfig, usePreventSafariAutozoom } from '../../../../../../hooks';
import { getExternalFinanceCertificateUrl, isFinanceCertificateEnabled, Logger } from '../../../../../../utils';
import { EstateLegalModal, LegalDialogTypes } from '../../../EstateLegalModal/EstateLegalModal';

export interface Props {
  estateId: string;
  isUserEstate: boolean;
  instituteCode?: string;
  instituteName: string;
  brokerFirstName: string;
  brokerLastName: string;
  brokerFullSalutation: string;
  brokerImageUrl?: string;
  phoneRequired: boolean;
  addressRequired: boolean;
  privacyPolicy: string;
  consumerInformation?: string;
  showHeadline: boolean;
  onSubmit?: () => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateContactForm = (props: Props): React.ReactElement => {
  const isIF6Scope = useIF6Context();
  const useBankDataHook = isIF6Scope ? useBankDataFromConfig : useBankData;
  const { bankCode } = useBankDataHook();

  const [legalDialogProps, setLegalDialogProps] = useState({
    open: false,
    dialogType: null as LegalDialogTypes,
    content: null as string,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const preventSafariAutozoom = usePreventSafariAutozoom();

  const closeLegalDialog = useCallback(() => {
    setLegalDialogProps((prevState) => {
      return { ...prevState, open: false };
    });
  }, []);

  const showPrivacyPolicy = useCallback(
    (event) => {
      event.preventDefault();
      setLegalDialogProps({
        open: true,
        dialogType: 'privacyPolicy',
        content: props.privacyPolicy,
      });
    },
    [props.privacyPolicy]
  );

  const showConsumerInformation = useCallback(
    (event) => {
      event.preventDefault();
      setLegalDialogProps({
        open: true,
        dialogType: 'consumerInformation',
        content: props.consumerInformation,
      });
    },
    [props.consumerInformation]
  );

  const AddressInputFields = () => (
    <>
      <Box mb={{ xs: 4, lg: 5 }}>
        <TextInput
          fullWidth
          label={t('street')}
          placeholder={t('street')}
          name="street"
          type="single"
          {...preventSafariAutozoom}
        />
      </Box>
      <Box mb={{ xs: 4, lg: 5 }}>
        <TextInput
          fullWidth
          label={t('streetNumber')}
          placeholder={t('streetNumber')}
          name="streetNumber"
          type="single"
          {...preventSafariAutozoom}
        />
      </Box>
      <Box mb={{ xs: 4, lg: 5 }}>
        <ZipCodeInput
          fullWidth
          label={t('zip')}
          placeholder={t('zip')}
          name="zip"
          required={props.addressRequired}
          {...preventSafariAutozoom}
        />
      </Box>
      <Box mb={{ xs: 4, lg: 5 }}>
        <TextInput
          fullWidth
          label={t('city')}
          placeholder={t('city')}
          name="city"
          type="single"
          {...preventSafariAutozoom}
        />
      </Box>
    </>
  );

  const renderLegalCheckBoxLabel = () => {
    const privacyPolicyLink = <Link onClick={showPrivacyPolicy} underline="none" color={theme.palette.primary.main} />;
    const consumerInformationLink = (
      <Link onClick={showConsumerInformation} underline="none" color={theme.palette.primary.main} />
    );

    const privacyPolicyLabel = <Trans i18nKey="detailPage.privacyPolicyRead" t={t} components={[privacyPolicyLink]} />;
    const privacyPolicyAndConsumerInformationLabel = (
      <Trans
        i18nKey="detailPage.privacyPolicyConsumerInformationRead"
        t={t}
        components={[privacyPolicyLink, consumerInformationLink]}
      />
    );

    return (
      <Box sx={{ whiteSpace: 'normal' }}>
        {props.consumerInformation ? privacyPolicyAndConsumerInformationLabel : privacyPolicyLabel}
      </Box>
    );
  };

  const LegalCheckbox = () => (
    <Box pb={{ xs: 4, lg: 8 }} className="legal-checkbox">
      <CheckboxInput name="accept" color="secondary" label={renderLegalCheckBoxLabel()} />
    </Box>
  );

  const getValidationSchema = () => {
    const validationSchema = {
      message: string().strict().required(),
      firstName: string().strict().required(),
      lastName: string().strict().required(),
      email: string().email().strict().required(),
      phone: string().strict(),
      street: string().strict(),
      streetNumber: string().strict(),
      zip: string().strict(),
      city: string().strict(),
      accept: boolean().strict().oneOf([true], t('detailPage.acceptPrivacyPolicyInfo')),
    };

    if (props.phoneRequired) {
      validationSchema.phone = validationSchema.phone.required();
    }

    if (props.addressRequired) {
      validationSchema.street = validationSchema.street.required();
      validationSchema.streetNumber = validationSchema.streetNumber.required();
      validationSchema.zip = validationSchema.zip.required();
      validationSchema.city = validationSchema.city.required();
    }

    if (props.isUserEstate) {
      validationSchema.accept = validationSchema.accept.oneOf([false]);
    }

    return validationSchema;
  };

  const sendContactMessage = useCallback(
    async (contactMessage: EstateContactMessageProps) => {
      const isSendSuccessful = await ApiClientProvider.getApiClient().clientSendEstateContactMessage(contactMessage);

      if (isSendSuccessful) {
        setIsSubmitted(true);
      } else {
        enqueueSnackbar(t('contactForm.generalError'), { variant: 'error' });
        Logger.error({ Message: '[IMMOBILIEN] unable to send estate contact message' });
      }
    },
    [enqueueSnackbar, t]
  );

  const onSubmit = useCallback(
    async (values) => {
      await sendContactMessage({
        estateId: props.estateId,
        instituteCode: '',
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        message: values.message,
        street: values.street,
        streetNumber: values.streetNumber,
        zip: values.zip,
        city: values.city,
      });
      if (props.onSubmit) {
        props.onSubmit();
      }
    },
    [props, sendContactMessage]
  );

  const renderForm = () => {
    return (
      <>
        <Formik
          initialValues={{
            message: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            street: '',
            streetNumber: '',
            zip: '',
            city: '',
            accept: false,
          }}
          validateOnMount
          validateOnBlur={true}
          validationSchema={object().shape(getValidationSchema())}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <>
              {props.showHeadline && (
                <Typography variant="h3" mb={7}>
                  {t('detailPage.contractProvider')}
                </Typography>
              )}
              <Box mb={{ xs: 6, lg: 8 }} display="flex" alignItems="center" justifyContent="flex-start">
                <Avatar
                  sx={{ width: 90, height: 90, mr: 5 }}
                  alt={props.brokerFullSalutation}
                  src={props.brokerImageUrl}
                />
                <Box>
                  <Typography
                    className="estate-contact-form-broker"
                    variant="h5"
                    mb={3}
                    color={theme.palette.text.headline}
                  >
                    {props.brokerFirstName} {props.brokerLastName}
                  </Typography>
                  <Typography
                    className="estate-contact-form-institute"
                    variant="h6"
                    mb={0}
                    color={{ xs: theme.palette.secondary.dark, lg: theme.palette.text.secondary }}
                  >
                    {props.instituteName}
                  </Typography>
                </Box>
              </Box>
              <Form>
                <Box mb={{ xs: 4, lg: 5 }}>
                  <TextInput
                    fullWidth
                    label={t('yourMessage')}
                    placeholder={t('yourMessage')}
                    type="single"
                    name="message"
                    minRows={4}
                    maxRows={7}
                    multiline
                    sx={{
                      '& .MuiInputBase-multiline': {
                        padding: 0,
                      },
                    }}
                    {...preventSafariAutozoom}
                  />
                </Box>
                <Box mb={{ xs: 4, lg: 5 }}>
                  <TextInput
                    fullWidth
                    label={t('firstName')}
                    placeholder={t('firstName')}
                    name="firstName"
                    type="single"
                    {...preventSafariAutozoom}
                  />
                </Box>
                <Box mb={{ xs: 4, lg: 5 }}>
                  <TextInput
                    fullWidth
                    label={t('lastName')}
                    placeholder={t('lastName')}
                    name="lastName"
                    type="single"
                    {...preventSafariAutozoom}
                  />
                </Box>
                <Box mb={{ xs: 4, lg: 5 }}>
                  <EmailInput
                    fullWidth
                    label={t('email')}
                    placeholder={t('email')}
                    name="email"
                    type="single"
                    {...preventSafariAutozoom}
                  />
                </Box>
                <Box mb={{ xs: 4, lg: 5 }}>
                  <PhoneInput
                    fullWidth
                    label={t('phone')}
                    placeholder={t('phone')}
                    name="phone"
                    type="single"
                    required={props.phoneRequired}
                    {...preventSafariAutozoom}
                  />
                </Box>
                {!props.isUserEstate && (
                  <>
                    <AddressInputFields />
                    <LegalCheckbox />
                  </>
                )}
                <Button
                  variant="standard"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                  type="submit"
                  fullWidth
                >
                  {t('sendMessage')}
                </Button>
              </Form>
            </>
          )}
        </Formik>
        <EstateLegalModal
          open={legalDialogProps.open}
          onClose={closeLegalDialog}
          dialogType={legalDialogProps.dialogType}
          content={legalDialogProps.content}
        />
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <Grid container className="contact-success-container">
        <Grid item xs={12}>
          <Box fontSize="36px" lineHeight="36px" mb={6} textAlign="center">
            <CheckCircleOutline htmlColor="green" fontSize="inherit" />
          </Box>
          <Typography variant="h5" textAlign="center" px={5}>
            {t('contactMessageSent', { firstName: props.brokerFirstName, lastName: props.brokerLastName })}
          </Typography>
        </Grid>
        {isFinanceCertificateEnabled(bankCode) && (
          <>
            <Grid item xs={12} py={6}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">{t('contactForm.financeCertificate')}</Typography>
              <Typography variant="body2" pt={6}>
                {t('contactForm.financeCertificateDescription')}
              </Typography>
              <Box py={6}>
                <Button fullWidth component="a" href={getExternalFinanceCertificateUrl(bankCode)}>
                  {t('contactForm.financeButtonLabel')}
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  if (isSubmitted) {
    return renderSuccess();
  }

  return renderForm();
};

EstateContactForm.displayName = 'EstateContactForm';
