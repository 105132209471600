import { TFunction } from 'i18next';
import { number, object, ref, string } from 'yup';

export enum Criteria {
  ZIP_CITY_ESTATE_ID = 'zip_city_estate_id',
  PERIMETER = 'perimeter',
  MARKETING_TYPE_OBJECT_TYPE = 'marketing_type_object_type',
  PRICE = 'price',
  MARKET_VALUE = 'market_value',
  RENT = 'rent',
  LEASE = 'lease',
  ROOMS = 'number_rooms',
  PROPERTY_SIZE = 'property_size',
  LIVING_SPACE = 'living_space',
  TOTAL_SPACE = 'total_space',
}

export const getZipCityEstateIdValidationSchema = (t: TFunction) => {
  return object({
    zipCityEstateId: string().trim().required(t('estateSearch.errorRequiredWhere')),
  });
};

export const getRentRangeValidationSchema = () => {
  return object({
    minRent: number().integer().min(0).max(999999999).nullable(),
    maxRent: number()
      .integer()
      .min(1)
      .max(999999999)
      .when('minRent', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minRent')),
      })
      .nullable(),
  });
};

export const getLeaseRangeValidationSchema = () => {
  return object({
    minLease: number().integer().min(0).max(999999999).nullable(),
    maxLease: number()
      .integer()
      .min(1)
      .max(999999999)
      .when('minLease', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minLease')),
      })
      .nullable(),
  });
};

export const getMarketValueRangeValidationSchema = () => {
  return object({
    minMarketValue: number().integer().min(0).max(999999999).nullable(),
    maxMarketValue: number()
      .integer()
      .min(1)
      .max(999999999)
      .when('minMarketValue', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minMarketValue')),
      })
      .nullable(),
  });
};

export const getPriceRangeValidationSchema = () => {
  return object({
    minPrice: number().integer().min(0).max(999999999).nullable(),
    maxPrice: number()
      .integer()
      .min(1)
      .max(999999999)
      .when('minPrice', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minPrice')),
      })
      .nullable(),
  });
};

export const getLivingSpaceRangeValidationSchema = () => {
  return object({
    minLivingSpace: number().min(0).max(10000).nullable().integer(),
    maxLivingSpace: number()
      .min(1)
      .max(10000)
      .when('minLivingSpace', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minLivingSpace')),
      })
      .nullable()
      .integer(),
  });
};

export const getPropertySizeRangeValidationSchema = () => {
  return object({
    minPropertySize: number().min(0).max(10000).nullable(),
    maxPropertySize: number()
      .min(1)
      .max(10000)
      .when('minPropertySize', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minPropertySize')),
      })
      .nullable(),
  });
};

export const getTotalSpaceRangeValidationSchema = () => {
  return object({
    minTotalSpace: number().min(0).max(10000).nullable(),
    maxTotalSpace: number()
      .min(1)
      .max(10000)
      .when('minTotalSpace', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minTotalSpace')),
      })
      .nullable(),
  });
};

export const getRoomsRangeValidationSchema = () => {
  return object({
    minNumberRooms: number().min(0).max(10000).nullable(),
    maxNumberRooms: number()
      .min(1)
      .max(10000)
      .when('minNumberRooms', {
        is: (value) => !isNaN(value),
        then: (schema) => schema.min(ref('minNumberRooms')),
      })
      .nullable(),
  });
};
