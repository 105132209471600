import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, Record<string, SxProps<Theme>>> => ({
  brokerAndProvider: {
    divider: {
      borderColor: theme.palette.grey[300],
      mb: { xs: 5, lg: 9 },
    },
    contactHeaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      pb: { xs: 8, lg: 10 },
      gap: { xs: 6, sm: 8, lg: 9 },
    },
    categoryLinkListContainer: { displayPrint: 'none' },
  },

  contactHeader: {
    partnerOuterContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    partnerInnerContainer: {
      width: { xs: 90, sm: 'unset', maxHeight: 120 },
    },
    partnerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    companyContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: { xs: 3, lg: 4 },
    },
    companyTypography: {
      '@media print': { fontSize: '16px' },
    },
    addressTypography: {
      color: theme.palette.text.primary,
      '@media print': { fontSize: '16px' },
      fontSize: { lg: '24px' },
    },
  },

  contactInformation: {
    brokerContainer: {
      display: 'flex',
      '@media print': { minWidth: '100%' },
    },
    avatar: {
      width: { xs: 90, lg: 120 },
      height: { xs: 90, lg: 120 },
    },
    phoneNumberContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
    },
    brokerTypography: {
      ml: 6,
      pb: 2,
      color: 'text.primary',
      '@media print': { fontSize: '13px' },
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      displayPrint: 'none',
    },
    button: {
      minWidth: 250,
    },
  },
});
