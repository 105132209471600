import { SvgIconProps } from '@mui/material';
import React from 'react';

import { labeledBranches } from '../../../../../config/poi';
import { POIControlPanelType } from '../../../../../config/types/estateMapPOIType';
import { Asklepios, BoyCap, CookingHut, Shop } from '../../../../../icons';

export const getActiveBranchLabel = (selectedGroup: number): string => {
  if (selectedGroup >= 0) {
    return labeledBranches[selectedGroup].label;
  }
  return '';
};

export const renderIcon = (type: string): React.FC<SvgIconProps> => {
  switch (type) {
    case POIControlPanelType.SUPERMARKETS:
      return Shop;
    case POIControlPanelType.PHARMACY:
      return Asklepios;
    case POIControlPanelType.CHILD_DAYCARE:
      return BoyCap;
    case POIControlPanelType.RESTAURANTS:
      return CookingHut;
    default:
      return null;
  }
};

export const clearSelectedMarkers = (): void => {
  const estateMapOverlay = document.querySelector('.estate-map-overlay');
  estateMapOverlay?.querySelectorAll('.Mui-selected').forEach((el) => el.classList.remove('Mui-selected'));
};

export const selectMarkerByPoiId = (poiId: string): void => {
  const estateMapOverlay = document.querySelector('.estate-map-overlay');
  estateMapOverlay?.querySelector(`.${poiId}`)?.classList.add('Mui-selected');
};
