import { Box, ToggleButton as MUIToggleButton, toggleButtonClasses, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import i18next from 'i18next';
import React from 'react';

import { ResultListTypes } from '../../../config';
import { DottedList, Map } from '../../../icons';

interface EstateResultTypeToggleProps {
  value?: ResultListTypes;
  onChange: ToggleButtonGroupProps['onChange'];
}

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  borderRadius: '90px',
  border: 'none !important', //todo: sorry for that - doch s.de theme benutzt keinen border, sondern boxShadow....
  boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary['main']}`,
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(5),
  margin: 0,
  fontSize: 16,
  [theme.breakpoints.down('lg')]: {
    fontSize: 13,
  },
  '&:hover': {
    color: '#fff',
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[800]}`,
    backgroundColor: theme.palette.grey[800],
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: '#ffffff',
    color: theme.palette.primary.main,
  },
  [`&.${toggleButtonClasses.selected}:hover`]: {
    backgroundColor: theme.palette.grey[800],
    color: '#fff',
  },
  '& .MuiSvgIcon-fontSizeMedium': {
    fontSize: 20,
  },
}));

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateResultTypeToggle: React.FC<EstateResultTypeToggleProps> = ({
  value,
  onChange,
}: EstateResultTypeToggleProps) => {
  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChange}>
      <ToggleButton disableRipple value={ResultListTypes.LIST}>
        <Box display="flex" alignItems="center">
          <Box pr={3} display={{ xs: 'none', sm: 'inherit' }}>
            {t('resultType.list')}
          </Box>
          <Box display="flex" alignItems="center">
            <DottedList />
          </Box>
        </Box>
      </ToggleButton>
      <ToggleButton disableRipple value={ResultListTypes.MAP}>
        <Box display="flex" alignItems="center">
          <Box pr={3} display={{ xs: 'none', sm: 'inherit' }}>
            {t('resultType.card')}
          </Box>
          <Box display="flex" alignItems="center">
            <Map />
          </Box>
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

EstateResultTypeToggle.displayName = 'EstateResultTypeToggle';
