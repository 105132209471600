import { Box, BoxProps } from '@mui/material';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { theme } from '@portals/core/src/themes/sde/main';
import React, { useCallback } from 'react';

import { useIsBookmarked } from '../../../hooks';
import { Heart, HeartOutline } from '../../../icons';

type Props = BoxProps;

export const SaveButton: React.FunctionComponent<Props> = ({ ...props }: Props) => {
  const { isBookmarked, toggleBookmark } = useIsBookmarked(props.id);
  const icon = isBookmarked ? <Heart color="primary" /> : <HeartOutline />;

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      toggleBookmark();
    },
    [toggleBookmark]
  );

  return (
    <Box
      position="absolute"
      right={{ xs: theme.spacing(2), md: theme.spacing(2), lg: theme.spacing(4) }}
      bottom={{ xs: theme.spacing(2), md: theme.spacing(2), lg: theme.spacing(4) }}
      zIndex={1}
      {...props}
    >
      <IconButton
        size="small"
        onClick={onClick}
        sx={{
          borderRadius: '50%',
          backgroundColor: '#FFF',
          border: '1px solid',
          borderColor: 'grey.500',
          height: { xs: '24px', md: '30px', lg: '36px' },
          width: { xs: '24px', md: '30px', lg: '36px' },
          '&.MuiIconButton-root': {
            padding: { xs: 1, md: 2, lg: 2 },
          },
          '& .MuiSvgIcon-root': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        {icon}
      </IconButton>
    </Box>
  );
};

SaveButton.displayName = 'SaveButton';
