import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (): Record<string, SxProps<Theme>> => ({
  contentSection: {
    '@media print': {
      padding: '0',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  brokerSectionWrapper: {
    '@media print': { display: 'none' },
  },
  brokerSectionContainer: {
    paddingY: { xs: 5, lg: 0 },
  },
  brokerAndProviderSectionWrapper: {
    paddingTop: 7,
    marginBottom: 8,
  },
});
