import { Box } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { FinalGridItem } from '@portals/core/src/components/FinalGrid/FinalGrid';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import {
  EstateItem,
  EstateObjectTypeOptions,
  EstateSearchProps,
  MarketingType,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { merge } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  estateObjectTypesToQueryString,
  Logger,
  removeBrackets,
  removeEmptyParams,
  trackEstateListItem,
} from '../../../utils';
import { EstateCardVertical } from '../../EstateCard/EstateCardVertical';
import { ListViewRenderProps } from '../../ListView/ListView.types';

type ListWrapperProps = {
  totalItems: number;
  estates?: Array<EstateItem>;
  showLoader: boolean;
  currentPage: React.MutableRefObject<number>;
  setShowLoader: (value: ((prevState: boolean) => boolean) | boolean) => void;
  searchParams: EstateSearchProps;
  objectTypes?: EstateObjectTypeOptions;
  setEstates: React.Dispatch<React.SetStateAction<EstateItem[]>>;
} & ListViewRenderProps;

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const ListItems = ({
  totalItems,
  estates,
  showLoader,
  currentPage,
  setShowLoader,
  searchParams,
  objectTypes,
  setEstates,
  renderBookmarkButton,
  renderEstateLink,
}: ListWrapperProps): React.ReactElement => {
  const [isLoadMoreButtonClicked, setIsLoadMoreButtonClicked] = useState(false);
  const [alreadyTrackedEstates, setAlreadyTrackedEstates] = useState([]);

  const estateListItems = useMemo(() => {
    return estates.map((estate: EstateItem, index: number) => {
      const estateItem = (
        <EstateCardVertical
          title={estate.title}
          subtitle={estate.subtitle}
          images={estate.images}
          priceData={estate.priceData}
          eyecatcher={estate.eyeCatcher}
          mainFacts={estate.mainFacts}
          partnerLogo={estate.partnerLogo}
          bookmarkButton={renderBookmarkButton && renderBookmarkButton(estate.id, 'small')}
          isRentObject={searchParams?.marketingType === MarketingType.RENT}
        />
      );
      return (
        <FinalGridItem role="listitem" key={`${estate.id}-${index}`} xs={12} sm={4} md={4} lg={4}>
          {renderEstateLink ? renderEstateLink(estate.id, estateItem) : estateItem}
        </FinalGridItem>
      );
    });
  }, [estates]);

  useEffect(() => {
    for (const listItem of estates) {
      if (alreadyTrackedEstates.includes(listItem.id)) {
        continue;
      }

      trackEstateListItem(listItem.id);
      setAlreadyTrackedEstates((prevState) => {
        return [...prevState, listItem.id];
      });
    }
  }, [alreadyTrackedEstates, estates]);

  useEffect(() => {
    const fetchEstates = async () => {
      currentPage.current = currentPage.current + 1;
      const estateTypes = estateObjectTypesToQueryString(objectTypes);
      const query = removeEmptyParams(
        merge({}, removeBrackets(searchParams), {
          page: currentPage.current,
          objectType: estateTypes,
        })
      );

      try {
        setShowLoader(true);
        const estatesResponse = await ApiClientProvider.getApiClient().getEstates(query);
        const newEstates = estatesResponse.estates;
        const pageCount = estatesResponse.pageCount;

        setEstates((prevState) => {
          return [...prevState, ...newEstates];
        });
        currentPage.current = Math.min(pageCount, currentPage.current);
      } catch (e) {
        Logger.error(e);
      } finally {
        setShowLoader(false);
        if (isLoadMoreButtonClicked) {
          setIsLoadMoreButtonClicked(false);
        }
      }
    };

    if (fetchEstates && !showLoader && estates.length > 0 && isLoadMoreButtonClicked) {
      fetchEstates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding missing deps will cause re-rendering loop
  }, [isLoadMoreButtonClicked]);

  const triggerLoadMore = useCallback(() => {
    setIsLoadMoreButtonClicked(true);
  }, []);

  const ShowMoreResultsButton = () => (
    <Box alignSelf="stretch" paddingX={{ xs: 5, lg: 6 }}>
      <Button
        sx={{ width: { xs: '100%', lg: 'auto' } }}
        loading={showLoader}
        color="secondary"
        onClick={triggerLoadMore}
      >
        {t('showMoreResults')}
      </Button>
    </Box>
  );

  return (
    <Box role="list" data-testid="estate-result-list" display="contents">
      {estateListItems}
      {totalItems > (estates?.length || 0) && (
        <FinalGridItem md={12} lg={12} textAlign="center">
          <ShowMoreResultsButton />
        </FinalGridItem>
      )}
    </Box>
  );
};

ListItems.displayName = 'ListItems';
