import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { EstateMainFact, MainfactCategory, MainFactProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React from 'react';

import { getMainfactCategoryIcon } from '../../../config';

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const MainFacts: React.FC<MainFactProps> = ({ list }: MainFactProps) => {
  if (list?.length === 0) {
    return null;
  }

  const getLabel = (mainfact: EstateMainFact) => {
    if (mainfact.category === MainfactCategory.ROOMS) {
      return t('estateCard.roomCount');
    }
    return mainfact.label;
  };

  const getIcon = (category: MainfactCategory) => {
    const iconStyles = { marginRight: { xs: theme.spacing(2), lg: theme.spacing(4) } };
    const Icon = getMainfactCategoryIcon(category);

    if (Icon) {
      return <Icon sx={{ ...iconStyles }} />;
    }
    return undefined;
  };

  const renderMainFact = (mainfact: EstateMainFact) => {
    return (
      <Typography
        variant="h6"
        component="div"
        color={theme.palette.secondary.darker}
        display="flex"
        alignItems="flex-end"
      >
        {getIcon(mainfact.category)}
        <span>{`${mainfact.value} ${getLabel(mainfact)}`}</span>
      </Typography>
    );
  };

  return (
    <>
      {list.map((mainfact: EstateMainFact, key) => (
        <Box key={key} alignSelf="stretch">
          {renderMainFact(mainfact)}
        </Box>
      ))}
    </>
  );
};

MainFacts.displayName = 'MainFacts';
