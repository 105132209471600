import { EstateDetail as EstateDetailType, GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { filter } from 'lodash-es';

import { GalleryFilterType } from '../../Gallery/GalleryButton/GalleryButton';

export const filterGalleryItemsByType = (items: GalleryItems[], type: GalleryFilterType): GalleryItems[] => {
  return filter(items, { type: type });
};

export const getVideos = (moreInformationData: EstateDetailType['moreInformationData']): GalleryFilterType.VIDEO[] => {
  return filter(moreInformationData, (data) =>
    ['.mp4', '.mov', '.avi', '.mkv'].some((extension) => data.url.endsWith(extension))
  ).map((data) => ({
    type: 'video',
    description: data.anhangtitel,
    resources: [
      {
        url: data.url,
      },
    ],
  }));
};

export const getImagePath = (): string => {
  return ConfigProvider.getConfig().get('BANK_LOGO_PATH') ?? '';
};
