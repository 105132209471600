import { Grid } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Criteria } from '../../../../config';

interface Props {
  criteria: Criteria | null | boolean;
  handleCancel: (e) => void;
  close: (goToMap?: boolean) => void;
  handleSubmitFromProps?: (e) => void;
  showResultFilterDrawer?: (showAllFilter?: boolean, criteria?: Criteria) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const Buttons: React.FunctionComponent<Props> = ({
  handleCancel,
  criteria,
  close,
  handleSubmitFromProps,
}: Props) => {
  const { isValid, values, errors, submitForm } = useFormikContext();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isValid) {
      setCount(0);
    }
  }, [isValid]);

  const fetchEstateCount = (values, errors) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true);
    ApiClientProvider.getApiClient()
      .getEstateCount(values)
      .then((response) => {
        setCount(response.totalItems);
      })
      .catch(() => {
        setCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceFetch = useRef(debounce((values, errors) => fetchEstateCount(values, errors), 300)).current;

  useEffect(() => {
    debounceFetch(values, errors);
  }, [values, errors]);

  const handleSubmit = useCallback(() => {
    submitForm();
    close(true);
  }, [close, submitForm]);

  if (criteria === null) {
    return (
      <Grid item xs={12}>
        <Button
          sx={{
            paddingLeft: {
              xs: 3,
              lg: 3,
            },
            paddingRight: {
              xs: 3,
              lg: 3,
            },
          }}
          loading={loading}
          color="primary"
          onClick={handleSubmit}
          fullWidth
          disabled={!isValid}
        >
          {t('use', { count: count })}
        </Button>
      </Grid>
    );
  }

  const getClickHandler = handleSubmitFromProps !== undefined ? handleSubmitFromProps : handleSubmit;
  return (
    <>
      <Grid item xs={6}>
        <Button color="secondary" onClick={handleCancel} disabled={!isValid} fullWidth>
          {t('cancel')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          sx={{
            paddingLeft: {
              xs: 3,
              lg: 3,
            },
            paddingRight: {
              xs: 3,
              lg: 3,
            },
          }}
          loading={loading}
          color="primary"
          onClick={getClickHandler}
          fullWidth
          disabled={!isValid}
        >
          {t('searchResultFilter.showResults', { count: count })}
        </Button>
      </Grid>
    </>
  );
};

Buttons.displayName = 'Buttons';
