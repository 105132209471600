import { TextField } from '@portals/core/src/components/TextField/TextField';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';

import { usePreventSafariAutozoom } from '../../../../hooks';

interface Props {
  label: string;
  fieldName: string;
  replaceRegex?: RegExp;
}

export const FilterTextField: React.FunctionComponent<Props> = ({
  label,
  fieldName,
  replaceRegex = undefined,
}: Props) => {
  const { values, errors, setFieldTouched, setFieldValue, handleBlur } = useFormikContext();

  const preventSafariAutozoom = usePreventSafariAutozoom();

  const handleChange = useCallback(
    (e) => {
      let value = e.target.value;
      if (replaceRegex) {
        value = value.replace(replaceRegex, '');
      }

      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, value, true);
    },
    [fieldName, replaceRegex, setFieldTouched, setFieldValue]
  );

  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      name={fieldName}
      value={get(values, fieldName)}
      onBlur={handleBlur}
      onChange={handleChange}
      error={Boolean(errors[fieldName])}
      helperText={errors[fieldName]}
      InputLabelProps={{
        shrink: true,
      }}
      {...preventSafariAutozoom}
    />
  );
};

FilterTextField.displayName = 'FilterTextField';
