/* eslint-disable import/no-extraneous-dependencies */
import { SxProps } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';
import React, { useCallback } from 'react';

import { useIsBookmarked } from '../../../../hooks';
import { Heart, HeartOutline } from '../../../../icons';
import { trackEstateBookmarkAdd, trackEstateBookmarkRemove } from '../../../../utils';
import { ActionButton } from '../ActionButton';

interface Props {
  id: string;
  sx?: SxProps<Theme>;
  size?: 'default' | 'small';
}

export const ActionFavoriteButton: React.FunctionComponent<Props> = ({ ...props }: Props) => {
  const { isBookmarked, toggleBookmark } = useIsBookmarked(props.id);

  const icon = isBookmarked ? <Heart fontSize="inherit" color="primary" /> : <HeartOutline fontSize="inherit" />;

  const handleAction = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      toggleBookmark();
      if (isBookmarked) {
        trackEstateBookmarkRemove(props.id);
      } else {
        trackEstateBookmarkAdd(props.id);
      }
    },
    [isBookmarked, props.id, toggleBookmark]
  );

  return <ActionButton icon={icon} onClick={handleAction} {...props} />;
};

ActionFavoriteButton.displayName = 'ActionFavoriteButton';
