import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { mapEstateDetailApiResponse } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import { EstateDetail as EstateDetailType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useEffect, useState } from 'react';

interface UseFetchEstateDetailsProps {
  estateId: string;
}

interface UseFetchEstateDetailsReturn {
  estate: EstateDetailType | null;
  isLoading: boolean;
}

export const useFetchEstateDetails = ({ estateId }: UseFetchEstateDetailsProps): UseFetchEstateDetailsReturn => {
  const [estate, setEstate] = useState<EstateDetailType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!estateId) {
      setIsLoading(false);
      return;
    }

    ApiClientProvider.getApiClient()
      .getEstateDetail(estateId)
      .then((data) => {
        setEstate(mapEstateDetailApiResponse(data));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [estateId]);

  return { estate, isLoading };
};
