import { Box } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { theme } from '@portals/core/src/themes/sde/main';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React, { FunctionComponent } from 'react';

import { LoansFinanceData } from '../../../../../types/loans';
import { CenteredContainer } from '../../Shared/CenteredContainer/CenteredContainer';
import InfoSection from '../InfoSection/InfoSection';
import { FinanceConditionsStyles } from './FinanceConditions.styles';
import FinanceConditionsSectionTitle from './FinanceConditionsSectionTitle';
import FinanceConditionsTable from './FinanceConditionsTable';
import FinanceLoanAmountTable from './FinanceLoanAmountTable';

export interface FinanceConditionsProps {
  estate: EstateDetail;
  loansData: LoansFinanceData;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const FinanceConditions: FunctionComponent<FinanceConditionsProps> = ({ estate, loansData }) => {
  const tableStyles = FinanceConditionsStyles(theme);

  return (
    <>
      <Box sx={tableStyles}>
        <Box className="table-container">
          <FinanceConditionsSectionTitle text="detailPage.nonBindingConditions" />
          <CenteredContainer disableDefaultPadding>
            <FinanceConditionsTable loansData={loansData} />
          </CenteredContainer>
          <CenteredContainer>
            {loansData.calculateConditionsURL && (
              <Box textAlign="center">
                <Button
                  className="calculate-conditions-button"
                  href={loansData.calculateConditionsURL}
                  color="secondary"
                >
                  {t('detailPage.calculateConditionsButtonLabel')}
                </Button>
              </Box>
            )}
          </CenteredContainer>
        </Box>
      </Box>
      <Box sx={tableStyles}>
        <Box className="table-container">
          <FinanceConditionsSectionTitle text="detailPage.estimatedLoanAmount" />
          <CenteredContainer disableDefaultPadding>
            <FinanceLoanAmountTable estate={estate} loansData={loansData} />
          </CenteredContainer>
          <CenteredContainer>
            <InfoSection>{loansData.texts.representativeExample}</InfoSection>
          </CenteredContainer>
        </Box>
      </Box>
    </>
  );
};

FinanceConditions.displayName = 'FinanceConditions';

export default FinanceConditions;
