/* eslint-disable import/no-extraneous-dependencies */
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ArrowRight } from '@portals/icons';
import { MapContainer } from '@portals/maps/src/containers/MapContainer/MapContainer';
import { Address } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import i18next from 'i18next';
import React, { useCallback } from 'react';

import { useLocationHash } from '../../../../hooks';
import { CenteredContainer } from '../Shared/CenteredContainer/CenteredContainer';
import { RequestFullAddressLink } from '../Shared/RequestFullAddressLink/RequestFullAddressLink';
import { getStyles } from './EstateLocationMap.styles';
import { EstateMapOverlay } from './EstateMapOverlay/EstateMapOverlay';

export interface EstateLocationMapProps {
  address?: Address;
  lat: number;
  lng: number;
  onMapOpen?: () => void;
  requestContactDetails: () => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');
const defaultHeight = '350px';

export const EstateLocationMap = ({
  address,
  lat,
  lng,
  onMapOpen,
  requestContactDetails,
}: EstateLocationMapProps): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const TILE_URL = ConfigProvider.getConfig().get('YELLOWMAP_TILE_URL');
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const lessThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const height = lessThanSm ? '95px' : lessThanMd ? '250px' : defaultHeight;
  const icon = <ArrowRight />;
  const { isActive: showDialog, toggleHash: toggleDialog } = useLocationHash('map');

  const onClick = useCallback(() => {
    toggleDialog();
    if (onMapOpen) {
      onMapOpen();
    }
  }, [toggleDialog]);

  const onClose = useCallback(() => {
    toggleDialog();
  }, [toggleDialog]);

  return (
    <Box sx={styles.addressContainer}>
      <CenteredContainer>
        {!address.street && (
          <Typography variant="body2" sx={styles.addressNotDisclosedTypography}>
            {t('addressNotDisclosed')}
          </Typography>
        )}
        {address.street && (
          <Typography variant="h5" sx={styles.streetTypography}>
            {address.street} {address.streetNumber}
          </Typography>
        )}
        <Box sx={styles.cityContainer}>
          <Typography component="div" variant="h4" sx={styles.cityTypography}>
            {address.zip} {address.city}
          </Typography>
          {!address.street && <RequestFullAddressLink onClick={requestContactDetails} />}
        </Box>
      </CenteredContainer>
      <Box sx={{ ...styles.mapContainer, height: height }}>
        <MapContainer
          className="estate-location"
          center={[lat, lng]}
          zoom={12}
          minZoom={7}
          maxZoom={18}
          style={{ height: '100%', zIndex: 0 }}
          url={TILE_URL}
          zoomControl={false}
          touchZoom={false}
          scrollWheelZoom={false}
          dragging={false}
          containerProps={{ height: height, width: '100%' }}
        >
          <Grid container sx={{ ...styles.mapButtonContainer, height: height }}>
            <Button onClick={onClick} sx={styles.mapButton} color="secondary" endIcon={icon}>
              {t('showOnMap')}
            </Button>
          </Grid>
        </MapContainer>
      </Box>
      <EstateMapOverlay open={showDialog} onClose={onClose} address={address} lat={lat} lng={lng} />
    </Box>
  );
};

EstateLocationMap.displayName = 'EstateLocationMap';
