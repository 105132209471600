import { Box, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const MobileOnly = styled('div')(() => ({
  '@media (min-width: 1080px)': {
    display: 'none',
  },
}));

const DesktopOnly = styled('div')(() => ({
  '@media (max-width: 1079px)': {
    display: 'none',
  },
}));

export const EstateSearchSkeleton: React.FC = () => {
  return (
    <>
      <MobileOnly>
        <Box
          className="search-form"
          sx={{
            width: '100%',
            minWidth: 'calc(100vw - 2 * 18px)',
            '@media (min-width: 425px)': {
              minWidth: '395px',
            },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7}>
              <Skeleton variant="rounded" width="100%" height={42} />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Skeleton variant="rounded" width="100%" height={42} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ marginTop: 2, p: '10px' }}>
            <Skeleton variant="rectangular" width={150} height={15} />
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={45} sx={{ borderRadius: '50px' }} />
            </Grid>
          </Grid>
        </Box>
      </MobileOnly>

      <DesktopOnly>
        <Box
          className="search-form"
          sx={{
            width: '100%',
            '@media (min-width: 425px)': {
              minWidth: '395px',
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item md={7}>
              <Box>
                <Skeleton variant="rounded" sx={{ mr: 3, height: { xs: 42, lg: 62 } }} />
              </Box>
            </Grid>
            <Grid item md={5}>
              <Box>
                <Skeleton variant="rounded" sx={{ ml: 3, height: { xs: 42, lg: 62 } }} />
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }}>
            <Grid container item md={8} spacing={3} sx={{ pr: 5 }}>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rounded" width="100%" sx={{ height: { xs: 42, lg: 50 } }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rounded" width="100%" sx={{ height: { xs: 42, lg: 50 } }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rounded" width="100%" sx={{ height: { xs: 42, lg: 50 } }} />
              </Grid>
            </Grid>

            <Grid container item md={4} spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ borderRadius: '50px', height: { xs: 45, lg: 62 } }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DesktopOnly>
    </>
  );
};

EstateSearchSkeleton.displayName = 'EstateSearchSkeleton';
