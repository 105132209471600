import { useTheme } from '@mui/material';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Close } from '@portals/icons';
import React, { Fragment } from 'react';

interface Option {
  value: number;
  label: string;
}

interface Props {
  options: Array<Option>;
  onDelete: (optionKey: number) => void;
}

const AutocompletePills: React.FC<Props> = ({ options, onDelete }: Props) => {
  const theme = useTheme();
  return (
    <>
      {options.slice(0, 2).map((option) => (
        <Fragment key={option.value}>
          <Pill
            sx={{
              marginRight: theme.spacing(2),
              marginTop: theme.spacing(2),
              '.MuiChip-deleteIcon': { fontSize: 'inherit', marginRight: '0' },
              '.MuiChip-label': { paddingLeft: 0 },
            }}
            size="small"
            label={option.label}
            // eslint-disable-next-line react/jsx-no-bind
            onDelete={() => onDelete(option.value)}
            deleteIcon={<Close />}
          />
        </Fragment>
      ))}
      {options.length > 2 ? (
        <Typography
          variant="button"
          key={3}
          position="relative"
          top={theme.spacing(1)}
          color={theme.palette.text.primary}
          className="selected-city-counts"
        >{`+${options.length - 2}`}</Typography>
      ) : null}
    </>
  );
};

AutocompletePills.displayName = 'AutocompletePills';

export default AutocompletePills;
