import { SxProps } from '@mui/material';

export const getStyles = (): { [key: string]: SxProps } => {
  return {
    link: {
      display: 'block',
      underline: 'none',
      color: 'unset',
    },
    box: { aspectRatio: '3 / 2' },
    carouselImage: {
      objectFit: 'cover',
      height: '226px',
      width: '100%',
      aspectRation: 'auto',
    },
    image: {
      objectFit: 'cover',
      height: 'auto',
      width: '100%',
      aspectRation: 'auto',
    },
    estateImage: {
      display: { xs: 'inherit', lg: 'none' },
    },
  };
};
