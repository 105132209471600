import { Option } from './estateAd';

export enum HouseType {
  TOWNHOUSE = 'townhouse',
  SEMI_DETACHED_HOUSE = 'semi_detached_house',
  SINGLE_FAMILY_HOUSE = 'single_family_house',
  BUNGALOW = 'bungalow',
  VILLA = 'villa',
  MULTI_FAMILY_HOUSE = 'multi_family_house',
  HOLIDAY_HOME = 'holiday_home', // to discuss
}

export const getAllHouseTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: HouseType.TOWNHOUSE,
      label: t('forms.estateAd.estateData.estateTypeOption.townhouse'),
    },
    {
      value: HouseType.SEMI_DETACHED_HOUSE,
      label: t('forms.estateAd.estateData.estateTypeOption.semiDetachedHouse'),
    },
    {
      value: HouseType.SINGLE_FAMILY_HOUSE,
      label: t('forms.estateAd.estateData.estateTypeOption.singleFamilyHouse'),
    },
    {
      value: HouseType.BUNGALOW,
      label: t('forms.estateAd.estateData.estateTypeOption.bungalow'),
    },
    {
      value: HouseType.VILLA,
      label: t('forms.estateAd.estateData.estateTypeOption.villa'),
    },
    {
      value: HouseType.MULTI_FAMILY_HOUSE,
      label: t('forms.estateAd.estateData.estateTypeOption.multiFamilyHouse'),
    },
    {
      value: HouseType.HOLIDAY_HOME,
      label: t('forms.estateAd.estateData.estateTypeOption.holidayHome'),
    },
  ];
};
