import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

interface PrivacyPolicyProps {
  text: string;
}

export default function PrivacyPolicy({ text }: PrivacyPolicyProps): React.ReactElement {
  return (
    <Box>
      <Typography variant="body2" component="p" mt={6} dangerouslySetInnerHTML={{ __html: text || null }} />
    </Box>
  );
}

PrivacyPolicy.displayName = 'PrivacyPolicy';
