import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  travelContainer: {
    display: 'flex',
  },
  travelIcons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pb: { xs: 3, lg: 5 },
  },
  travelLine: {
    pb: -5,
    my: 1,
    flexGrow: 1,
    width: '1px',
    borderLeft: '3px dotted #000000',
  },
  travelInputContainer: {
    pl: { xs: 4, lg: 5 },
    flexGrow: 1,
  },
  travelInput: {
    '& .MuiInputAdornment-positionEnd': {
      xs: { marginRight: theme.spacing(4) },
      lg: { marginRight: theme.spacing(5) },
    },
  },
  travelAddressLabelContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    pl: 5,
    pb: 6,
  },
  inputAdornmentIcon: {
    color: 'secondary.darker',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inputAdornmentClearIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  travelBodyText: {
    my: 6,
  },
  errorOuterContainer: {
    pt: 3,
    pl: 5,
  },
  errorInnerContainer: {
    pl: 8,
  },
  submitButtonContainer: {
    mt: { xs: 5, lg: 6 },
    pl: { xs: 7, lg: 8 },
  },
  travelTimeBadgeContainer: {
    mb: 4,
  },
  travelTimeBadgesOuterContainer: {
    pl: { xs: 7, lg: 8 },
  },
  travelTimeBadgesInnerContainer: {
    py: { xs: 5, lg: 6 },
    display: 'flex',
    flexWrap: 'wrap',
  },

  travelTimeBadgePill: {
    boxShadow: 'none',
    backgroundColor: 'grey.100',
    marginRight: 3,
    padding: 2,
    fontSize: theme.typography.fontSize,
  },
});
