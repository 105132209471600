import { Option } from './estateAd';

export enum EquipmentQualityTypes {
  NOT_SPECIFIED = 'not_specified',
  NORMAL = 'normal',
  SOPHISTICATED = 'sophisticated',
  LUXURIOUS = 'luxurious',
}

export const getAllFurnishingQualityOptions = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EquipmentQualityTypes.NOT_SPECIFIED,
      label: t('forms.estateAd.estateData.furnishingQualityOption.notSpecified'),
    },
    {
      value: EquipmentQualityTypes.NORMAL,
      label: t('forms.estateAd.estateData.furnishingQualityOption.normal'),
    },
    {
      value: EquipmentQualityTypes.SOPHISTICATED,
      label: t('forms.estateAd.estateData.furnishingQualityOption.sophisticated'),
    },
    {
      value: EquipmentQualityTypes.LUXURIOUS,
      label: t('forms.estateAd.estateData.furnishingQualityOption.luxurious'),
    },
  ];
};
