import { Box } from '@mui/material';
import { GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import {
  Gallery,
  GalleryButtons,
  GalleryFilterType,
  HorizontalSwipeBar,
  HorizontalSwipeBarStyles as styles,
} from '../../../../components';

export const GallerySection = (props: {
  items: Array<GalleryItems>;
  onClick: (filter: GalleryFilterType | null, galleryPosition: number | null) => void;
  predicate: (image) => boolean;
  galleryOptions: { filter: null; index: number; open: boolean };
  onClose: () => void;
}): JSX.Element => {
  const galleryTypes = Object.values(GalleryFilterType) as string[];
  const selectedGalleryType = props.galleryOptions.filter || 'image';

  return (
    <Box className="header-gallery-section">
      <HorizontalSwipeBar>
        <Box sx={styles.buttonContainer}>
          <GalleryButtons items={props.items} onClick={props.onClick} />
        </Box>
      </HorizontalSwipeBar>

      {galleryTypes.map((type) =>
        type === selectedGalleryType ? (
          <Gallery
            key={type}
            items={props.items.filter(props.predicate)}
            galleryOpen={props.galleryOptions.open}
            onClose={props.onClose}
            currentImagePosition={props.galleryOptions.index}
          />
        ) : null
      )}
    </Box>
  );
};
GallerySection.displayName = 'GallerySection';
