import { Box, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { ExternalLink } from '@portals/icons';
import i18next from 'i18next';
import React from 'react';

import { ThreeSixtyPlaceholder, VideoPlaceholder } from '../../../icons';
import { getStyles } from './../GalleryModal/GalleryModal.styles';

export type GalleryCarouselItemType = {
  url: string;
  description?: string;
};

type GalleryCarouselMediaItemProps = {
  url: string;
  buttonText: string;
  Placeholder: React.ElementType;
  className: string;
};

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const GalleryCarouselMediaItem = ({ url, buttonText, Placeholder, className }: GalleryCarouselMediaItemProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.placeholderContainer}>
      <Placeholder className={className} sx={styles.placeholder} />
      <Button href={url} component="a" target="_blank" endIcon={<ExternalLink />}>
        {t(buttonText)}
      </Button>
    </Box>
  );
};

export const GalleryCarouselItem = ({ url, description }: GalleryCarouselItemType): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.imageContainer}>
      <Box component="img" sx={styles.imageContent} src={url} alt={description} />
    </Box>
  );
};

GalleryCarouselItem.displayName = 'GalleryCarouselItem';

export const GalleryCarouselThreeSixtyItem = ({ url }: GalleryCarouselItemType): React.ReactElement => {
  return (
    <GalleryCarouselMediaItem
      url={url}
      buttonText="gallery.threeSixtyViewOpen"
      Placeholder={ThreeSixtyPlaceholder}
      className="gallery-threesixtyplaceholder"
    />
  );
};

GalleryCarouselThreeSixtyItem.displayName = 'GalleryCarouselThreeSixtyItem';

export const GalleryCarouselVideoItem = ({ url }: GalleryCarouselItemType): React.ReactElement => {
  return (
    <GalleryCarouselMediaItem
      url={url}
      buttonText="gallery.videoOpen"
      Placeholder={VideoPlaceholder}
      className="gallery-videoplaceholder"
    />
  );
};

GalleryCarouselVideoItem.displayName = 'GalleryCarouselVideoItem';
