import { Box } from '@mui/material';
import { ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import { filter, get, indexOf, map } from 'lodash-es';
import React from 'react';

import type { FilterValuesProps } from '../../../../types';
import { EstateFeatureBox } from './EstateFeatureBox/EstateFeatureBox';

interface EstateFeatureBoxProps {
  filterValues: FilterValuesProps;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const FeatureFilter: React.FC<EstateFeatureBoxProps> = ({ filterValues }: EstateFeatureBoxProps) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();

  const getFlatOptions = () => {
    return [
      {
        value: 'ground_floor',
        label: t('flatOptions.ground_floor'),
      },
      {
        value: 'flat',
        label: t('flatOptions.flat'),
      },
      {
        value: 'attic_flat',
        label: t('flatOptions.attic_flat'),
      },
      {
        value: 'maisonette',
        label: t('flatOptions.maisonette'),
      },
      {
        value: 'loft',
        label: t('flatOptions.loft'),
      },
      {
        value: 'penthouse',
        label: t('flatOptions.penthouse'),
      },
    ];
  };

  const getHouseOptions = () => {
    return [
      {
        value: 'single_family_house',
        label: t('houseOptions.single_family_house'),
      },
      {
        value: 'semi_detached_house',
        label: t('houseOptions.semi_detached_house'),
      },
      {
        value: 'townhouse',
        label: t('houseOptions.townhouse'),
      },
      {
        value: 'bungalow',
        label: t('houseOptions.bungalow'),
      },
      {
        value: 'villa',
        label: t('houseOptions.villa'),
      },
      {
        value: 'multi_family_house',
        label: t('houseOptions.multi_family_house'),
      },
    ];
  };

  const getPropertyOptions = () => {
    return [
      {
        value: 'residential',
        label: t('propertyOptions.residential'),
      },
      {
        value: 'leisure',
        label: t('propertyOptions.leisure'),
      },
      {
        value: 'commercial',
        label: t('propertyOptions.commercial'),
      },
      {
        value: 'industrial',
        label: t('propertyOptions.industrial'),
      },
      {
        value: 'agriculture',
        label: t('propertyOptions.agriculture'),
      },
      {
        value: 'mixed',
        label: t('propertyOptions.mixed'),
      },
    ];
  };

  const getInterestOptions = () => {
    return [
      {
        value: 'multi_family_house',
        label: t('interestOptions.multi_family_house'),
      },
      {
        value: 'living_business_building',
        label: t('interestOptions.living_business_building'),
      },
    ];
  };

  const getEquipmentOptions = () => {
    return [
      {
        value: 'balcony_terrace',
        label: t('equipmentOptions.balcony_terrace'),
      },
      {
        value: 'tube',
        label: t('equipmentOptions.tube'),
      },
      {
        value: 'guests_wc',
        label: t('equipmentOptions.guests_wc'),
      },
      {
        value: 'garden',
        label: t('equipmentOptions.garden'),
      },
      {
        value: 'kitchen_builtin',
        label: t('equipmentOptions.kitchen_builtin'),
      },
      {
        value: 'parking',
        label: t('equipmentOptions.parking'),
      },
      {
        value: 'cellar',
        label: t('equipmentOptions.cellar'),
      },
      {
        value: 'elevator_passenger',
        label: t('equipmentOptions.elevator_passenger'),
      },
      {
        value: 'barrier_free',
        label: t('equipmentOptions.barrier_free'),
      },
    ];
  };

  const getConditionDevelopmentOptions = () => {
    return [
      {
        value: '',
        label: t('conditionDevelopmentOptions.noMatter'),
      },
      {
        value: 'none',
        label: t('conditionDevelopmentOptions.none'),
      },
      {
        value: 'partly',
        label: t('conditionDevelopmentOptions.partly'),
      },
      {
        value: 'full',
        label: t('conditionDevelopmentOptions.full'),
      },
    ];
  };

  const getNewBuildingOptions = () => {
    return [
      {
        value: '',
        label: t('newBuildingOptions.noMatter'),
      },
      {
        value: 'true',
        label: t('newBuildingOptions.newConstructionOnly'),
      },
      {
        value: 'false',
        label: t('newBuildingOptions.noNewBuilding'),
      },
    ];
  };

  const getSelectedOptions = (fieldName, options) => {
    const selectedValues = get(values, fieldName, []);
    return filter(options, (option) => {
      return indexOf(selectedValues, option.value) > -1;
    });
  };

  const getSelectedOption = (fieldName, options) => {
    const selectedValue = get(values, fieldName, '');
    return filter(options, (option) => {
      return selectedValue === option.value;
    });
  };

  const setSelectedOptions = (fieldName, selectedOptions) => {
    const options = map(selectedOptions, (option) => {
      return option.value;
    });

    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, options, true);
  };

  const setSelectedOption = (fieldName, selectedOption) => {
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, selectedOption.value, true);
  };

  const renderFeatures = () => {
    switch (filterValues.objectType) {
      case ObjectType.FLAT:
        return (
          <EstateFeatureBox
            multiple
            headline={t('flatOptions.headline')}
            options={getFlatOptions()}
            selectedOptions={getSelectedOptions('flatTypes', getFlatOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOptions('flatTypes', selectedOptions);
            }}
          />
        );
      case ObjectType.HOUSE:
        return (
          <EstateFeatureBox
            multiple
            headline={t('houseOptions.headline')}
            options={getHouseOptions()}
            selectedOptions={getSelectedOptions('houseTypes', getHouseOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOptions('houseTypes', selectedOptions);
            }}
          />
        );
      case ObjectType.PROPERTY:
        return (
          <EstateFeatureBox
            multiple
            headline={t('propertyOptions.headline')}
            options={getPropertyOptions()}
            selectedOptions={getSelectedOptions('propertyTypes', getPropertyOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOptions('propertyTypes', selectedOptions);
            }}
          />
        );
      case ObjectType.INTEREST:
        return (
          <EstateFeatureBox
            multiple
            headline={t('interestOptions.headline')}
            options={getInterestOptions()}
            selectedOptions={getSelectedOptions('interestTypes', getInterestOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOptions('interestTypes', selectedOptions);
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderEquipment = () => {
    switch (filterValues.objectType) {
      case ObjectType.FLAT:
      case ObjectType.HOUSE:
        return (
          <EstateFeatureBox
            multiple
            headline={t('equipmentOptions.headline')}
            options={getEquipmentOptions()}
            selectedOptions={getSelectedOptions('equipment', getEquipmentOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOptions('equipment', selectedOptions);
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderConditionDevelopment = () => {
    if (filterValues.objectType === ObjectType.PROPERTY) {
      return (
        <EstateFeatureBox
          headline={t('conditionDevelopmentOptions.headline')}
          options={getConditionDevelopmentOptions()}
          selectedOptions={getSelectedOption('conditionDevelopment', getConditionDevelopmentOptions())}
          /* eslint-disable-next-line react/jsx-no-bind */
          setSelectedOptions={(selectedOptions) => {
            setSelectedOption('conditionDevelopment', selectedOptions[0]);
          }}
        />
      );
    }

    return null;
  };

  const renderNewBuilding = () => {
    switch (filterValues.objectType) {
      case ObjectType.FLAT:
      case ObjectType.HOUSE:
        return (
          <EstateFeatureBox
            headline={t('newBuildingOptions.headline')}
            options={getNewBuildingOptions()}
            selectedOptions={getSelectedOption('isNewBuilding', getNewBuildingOptions())}
            /* eslint-disable-next-line react/jsx-no-bind */
            setSelectedOptions={(selectedOptions) => {
              setSelectedOption('isNewBuilding', selectedOptions[0]);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box px={6}>
      {renderFeatures()}
      {renderEquipment()}
      {renderConditionDevelopment()}
      {renderNewBuilding()}
    </Box>
  );
};

FeatureFilter.displayName = 'FeatureFilter';
