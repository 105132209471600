import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  addressContainer: { width: 1, '@media print': { pl: '0' } },
  addressNotDisclosedTypography: { color: '#565656', '@media print': { fontSize: '13px' } },
  streetTypography: { pb: '10px', '@media print': { fontSize: '14px' } },
  cityContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    '@media print': {
      flexDirection: 'column',
    },
  },
  cityTypography: {
    display: 'inline-block',
    pt: theme.spacing(5),
    mr: theme.spacing(3),
    '@media print': { pb: '12px', fontSize: '14px' },
  },
  mapContainer: {
    displayPrint: 'none',
    width: '100%',
    mt: theme.spacing(6),
    mb: { xs: theme.spacing(6), sm: theme.spacing(6), md: theme.spacing(9) },
  },
  mapButtonContainer: { justifyContent: 'center', alignItems: 'center' },
  mapButton: {
    zIndex: 41,
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.15)',
    bgcolor: theme.palette.background.default,
    '&:focus': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.button.secondary['color'],
      boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.15)',
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.button.secondary['colorFocus'],
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.button.secondary['shadowFocus']}`,
    },
  },
});
