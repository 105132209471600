import { Box } from '@mui/material';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { theme } from '@portals/core/src/themes/sde/main';
import { EstateEyecatcher, EstateMainFact } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React from 'react';

import { Badges } from './Badges/Badges';
import { Cost } from './Cost/Cost';
import { ImageContainer } from './ImageContainer/ImageContainer';
import { MainFacts } from './MainFacts/MainFacts';
import { SectionWrapper } from './SectionWrapper/SectionWrapper';
import { Subtitle } from './Subtitle/Subtitle';
import { Title } from './Title/Title';

export type EstateCardProps = {
  title: string;
  subtitle: string;
  images: string[];
  priceData: EstateMainFact;
  eyecatcher?: Array<EstateEyecatcher>;
  mainFacts: Array<EstateMainFact>;
  partnerLogo?: string;
  isRentObject?: boolean;
  bookmarkButton?: React.ReactNode;
};

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateCardVertical: React.FC<EstateCardProps> = ({
  title,
  subtitle,
  images,
  priceData,
  eyecatcher,
  mainFacts,
  partnerLogo,
  isRentObject,
  bookmarkButton,
}: EstateCardProps) => {
  const getPaymentCycle = () => {
    const rentLabel = t('estateList.substring_rentObject');
    const isRentLabel = priceData?.label.includes(rentLabel);

    if (!priceData || (!isRentObject && !isRentLabel) || priceData?.value === 'auf Anfrage') {
      return '';
    }

    return t('estateCard.paymentCycle.monthly');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        gap: { xs: theme.spacing(5), lg: theme.spacing(6) },
      }}
    >
      <SectionWrapper data-testid="image-section" disableInnerPadding>
        <ImageContainer title={title} imageList={images} partnerLogo={partnerLogo} bookmarkButton={bookmarkButton} />
      </SectionWrapper>

      <SectionWrapper data-testid="title-section">
        <Subtitle subtitle={subtitle} />
        <Title title={title} />
      </SectionWrapper>

      <SectionWrapper
        data-testid="mainfacts-section"
        isColumnFlexBox
        rowGap={theme.spacing(4)}
        paddingTop={{ xs: theme.spacing(2), lg: 0 }}
      >
        <MainFacts list={mainFacts} />
      </SectionWrapper>

      {eyecatcher.length > 0 && (
        <SectionWrapper
          data-testid="eyecatcher-section"
          isColumnFlexBox
          flexWrap="wrap"
          gap={{ xs: theme.spacing(2), lg: theme.spacing(4) }}
          marginTop="auto"
        >
          <Badges badgeItems={eyecatcher} />
        </SectionWrapper>
      )}

      <SectionWrapper
        data-testid="divider-section"
        disableInnerPadding
        width="100%"
        display={typeof priceData?.value === 'undefined' ? 'none' : 'unset'}
        sx={{ ...(eyecatcher?.length === 0 ? { marginTop: 'auto' } : null) }}
      >
        <Divider />
      </SectionWrapper>

      <SectionWrapper data-testid="cost-section" isRowFlexBox>
        <Cost costType={priceData?.label} priceWithCurrency={priceData?.value} paymentCycle={getPaymentCycle()} />
      </SectionWrapper>
    </Box>
  );
};

EstateCardVertical.displayName = 'EstateCardVertical';
