import { FrontendContent, FrontendItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { TFunction } from 'i18next';
import React from 'react';

import { Logger } from '../../../../utils';
import { isLocationBlock, isOtherDataBlock } from '../../../../utils/estateDetailsUtils';
import { ContentAttributeTable, ContentCheckedList, ContentText } from '../../..';

export const renderContent = (content: FrontendContent, key: string): JSX.Element | null => {
  switch (content.type) {
    case 'attributeTable':
      return <ContentAttributeTable key={key} data={content.data} />;
    case 'contentBoxes':
      return <ContentText key={key} content={content.data.content} />;
    case 'checkedList':
      return <ContentCheckedList key={key} data={content.data} />;
    default:
      Logger.error('could not parse type: ', content);
      return null;
      break;
  }
};

export const insertLocationBlockIfMissing = (frontendItems: FrontendItem[], t: TFunction): void => {
  if (frontendItems.filter(isLocationBlock).length === 0) {
    const locationFrontendItem = {
      label: t('locationDataLabel'),
      name: 'locationData',
      contents: [],
    } as FrontendItem;
    frontendItems.splice(1, 0, locationFrontendItem);
  }
};

export const updateOtherDataBlockLabels = (frontendItems: FrontendItem[], t: TFunction): void => {
  frontendItems.forEach((frontendItem) => {
    if (isOtherDataBlock(frontendItem)) {
      frontendItem.label = t('moreInformationLabel');
    }
  });
};
