import { notSpecifiedOptionValue } from '../../utils';
import { Option } from './estateAd';

export enum PropertyBuildableTypes {
  LIKE_NEIGHBOUR = 'like_neighbour',
  EXTERNAL_TERRITORY = 'external_territory',
  DEVELOPMENT_PLAN = 'development_plan',
  NO_BUILDING_LAND = 'no_building_land',
  IN_EXPACTATION_OF_BUILDING_LAND = 'in_expactation_of_building_land',
  COUNTRY_SPECIFIC = 'country_specific',
  BUILDING_LAND_WITHOUT_DEVELOPMENT_PLAN = 'building_land_without_development_plan',
}

export const getAllPropertyBuildableOptions = (t: (a: string) => string): Option[] => {
  return [
    {
      value: notSpecifiedOptionValue,
      label: t('forms.estateAd.data.notSpecified'),
    },
    {
      value: PropertyBuildableTypes.LIKE_NEIGHBOUR,
      label: t('propertyBuildableOptions.likeNeighbour'),
    },
    {
      value: PropertyBuildableTypes.EXTERNAL_TERRITORY,
      label: t('propertyBuildableOptions.externalTerritory'),
    },
    {
      value: PropertyBuildableTypes.DEVELOPMENT_PLAN,
      label: t('propertyBuildableOptions.developmentPlan'),
    },
    {
      value: PropertyBuildableTypes.NO_BUILDING_LAND,
      label: t('propertyBuildableOptions.noBuildingLand'),
    },
    {
      value: PropertyBuildableTypes.IN_EXPACTATION_OF_BUILDING_LAND,
      label: t('propertyBuildableOptions.inExpectationOfBuildingLand'),
    },
    {
      value: PropertyBuildableTypes.COUNTRY_SPECIFIC,
      label: t('propertyBuildableOptions.countrySpecific'),
    },
    {
      value: PropertyBuildableTypes.BUILDING_LAND_WITHOUT_DEVELOPMENT_PLAN,
      label: t('propertyBuildableOptions.buildingLandWithoutDevelopmentPlan'),
    },
  ];
};
