import { Option } from './estateAd';

export enum ConditionOptions {
  NOT_SPECIFIED = 'not_specified',
  MODERNIZED = 'modernised',
  RENOVATED = 'renovated',
  NEED_OF_RENOVATION = 'need_of_renovation',
}

export const getAllConditionOptions = (t: (a: string) => string): Option[] => {
  return [
    {
      value: ConditionOptions.NOT_SPECIFIED,
      label: t('forms.estateAd.structureData.condition.radioGroup.opt1'),
    },
    {
      value: ConditionOptions.MODERNIZED,
      label: t('forms.estateAd.structureData.condition.radioGroup.opt2'),
    },
    {
      value: ConditionOptions.RENOVATED,
      label: t('forms.estateAd.structureData.condition.radioGroup.opt3'),
    },
    {
      value: ConditionOptions.NEED_OF_RENOVATION,
      label: t('forms.estateAd.structureData.condition.radioGroup.opt4'),
    },
  ];
};
