import React, { Suspense } from 'react';

import type { Props as EstateMarkerProps } from './EstateMarker.ui';

const EstateMarkerUI = React.lazy(() => import('./EstateMarker.ui'));

export const EstateMarker: React.FC<EstateMarkerProps> = ({ children, ...props }: EstateMarkerProps) => (
  <Suspense fallback="">
    <EstateMarkerUI {...props}>{children}</EstateMarkerUI>
  </Suspense>
);

EstateMarker.displayName = 'EstateMarker';
