import { SxProps } from '@mui/material';

export const getEstateDetailsStyles = (): Record<string, SxProps> => ({
  '@media print': {
    '@page': {
      size: 'A4 portrait',
      margin: '1cm',
    },
    [`body > *:not(.estate-details-container), .estate-details-container > *:not(.estate-details-print-container)`]: {
      visibility: 'hidden',
      position: 'initial',
    },
    [`body .estate-details-print-container`]: {
      visibility: 'visible',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '152mm',
    },
  },
  contentSectionContainer: {
    '@media print': {
      padding: '0',
      margin: '0',
    },
    marginTop: 0,
  },
});
