import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { IF6Config } from '@portals/sip-client-data/src/if6/IF6Config';

interface BankData {
  bankCode: string;
  bankName: string;
}

export const useBankDataFromConfig = (): BankData => {
  const bankCode = (ConfigProvider.getConfig() as IF6Config).get('BANK_CODE');
  const bankName = (ConfigProvider.getConfig() as IF6Config).get('BANK_NAME');

  return { bankCode, bankName };
};
