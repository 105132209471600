import axios from 'axios';
import { stringifyUrl } from 'query-string';
import { ParsedUrlQuery } from 'querystring';
import { httpsOverHttp } from 'tunnel';

import { Logger, removeEmptyParams } from '../../utils';

export enum Method {
  DELETE = 'delete',
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
}

export const performSipApiRequest = async (method: Method, route: string, data?: Record<string, any>) => {
  const SIP_API_URL = process.env.SIP_API_URL ?? '';
  const SIP_API_KEY = process.env.SIP_API_KEY ?? '';
  const proxy = isNoProxyUrl(SIP_API_URL)
    ? false
    : {
        host: process.env.SPK_PROXY_HOST,
        port: 80,
      };

  const httpsAgent = proxy.host
    ? httpsOverHttp({
        keepAlive: true,
        proxy,
      })
    : undefined;

  const query: ParsedUrlQuery = {
    api_key: SIP_API_KEY,
  };

  const requestData = typeof data === 'undefined' ? {} : data;

  if (method === Method.GET || method === Method.DELETE) {
    for (const i in requestData) {
      query[i] = requestData[i];
    }
  }

  const apiUrl = stringifyUrl({ url: `${SIP_API_URL}/${route}`, query: removeEmptyParams(query) });
  try {
    if (method === Method.GET) {
      const response = await axios.get(apiUrl, {
        httpsAgent: httpsAgent,
        httpAgent: httpsAgent,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      Logger.info(`[Immobilien] GET ${apiUrl}`);
      return response.data;
    }

    const response = await axios[method](apiUrl, requestData, {
      httpsAgent: httpsAgent,
      httpAgent: httpsAgent,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (e) {
    Logger.error({ Message: '[IMMOBILIEN] request failed', e });
    return null;
  }
};

const isNoProxyUrl = (url: string): boolean => {
  const urlDomain = new URL(url).hostname;
  const noProxyDomains = (process.env.NO_PROXY ?? '').split(',');
  return noProxyDomains.includes(urlDomain);
};
