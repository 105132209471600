import { Form, Formik } from 'formik';
import i18next from 'i18next';
import { forIn } from 'lodash-es';
import React, { useCallback } from 'react';
import { object } from 'yup';

import {
  Criteria,
  getLeaseRangeValidationSchema,
  getLivingSpaceRangeValidationSchema,
  getMarketValueRangeValidationSchema,
  getPriceRangeValidationSchema,
  getPropertySizeRangeValidationSchema,
  getRentRangeValidationSchema,
  getRoomsRangeValidationSchema,
  getTotalSpaceRangeValidationSchema,
  getZipCityEstateIdValidationSchema,
} from '../../../../config';
import type { FilterValuesProps } from '../../../../types';

interface Props {
  filterValues: FilterValuesProps;
  criteria: Criteria | null;
  showAllFilter: boolean;
  changeFilterValues: (changedFilterValues: FilterValuesProps) => void;
  changeCriteria: (criteria: Criteria) => void;
  children: React.ReactNode;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const FiltersFormWrapper: React.FC<Props> = ({
  filterValues,
  criteria,
  showAllFilter,
  changeFilterValues,
  changeCriteria,
  children,
}: Props) => {
  const getValidationSchema = () => {
    switch (criteria) {
      case Criteria.ZIP_CITY_ESTATE_ID:
        return getZipCityEstateIdValidationSchema(t);
      case Criteria.PRICE:
        return getPriceRangeValidationSchema();
      case Criteria.MARKET_VALUE:
        return getMarketValueRangeValidationSchema();
      case Criteria.RENT:
        return getRentRangeValidationSchema();
      case Criteria.LEASE:
        return getLeaseRangeValidationSchema();
      case Criteria.LIVING_SPACE:
        return getLivingSpaceRangeValidationSchema();
      case Criteria.PROPERTY_SIZE:
        return getPropertySizeRangeValidationSchema();
      case Criteria.TOTAL_SPACE:
        return getTotalSpaceRangeValidationSchema();
      case Criteria.ROOMS:
        return getRoomsRangeValidationSchema();
    }
    return object({});
  };

  const getNullAsDefault = (value) => {
    if (value === undefined || value == null || value === '') {
      return null;
    }

    return value;
  };

  const onSubmit = useCallback((values) => {
    const changedValues = {};
    forIn(values, (value, key) => {
      changedValues[key] = getNullAsDefault(value);
    });
    const castValues = getValidationSchema().cast(changedValues) as FilterValuesProps;
    changeFilterValues(castValues);

    if (showAllFilter && criteria !== null) {
      changeCriteria(null);
      return;
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      validateOnChange
      initialValues={filterValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {() => (
        <Form
          style={{
            width: '100%',
          }}
        >
          {children}
        </Form>
      )}
    </Formik>
  );
};

FiltersFormWrapper.displayName = 'FiltersFormWrapper';
