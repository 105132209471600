import { queries, render, RenderOptions } from '@testing-library/react';
import { ReactElement } from 'react';

import { I18NEXT_OPTIONS } from './i18nextmod';
// eslint-disable-next-line import/no-namespace
import * as customQueries from './testQueries';

export function checkValueOfMUITextInput(node: HTMLElement, name: string, expectedValue: string): void {
  const input = node.querySelector(`input[name="${name}"]`) as HTMLInputElement;
  expect(input.value).toBe(expectedValue);
}

export function checkValueOfTextAreaInput(node: HTMLElement, name: string, expectedValue: string): void {
  const input = node.querySelector(`textarea[name="${name}"]`) as HTMLInputElement;
  expect(input.value).toBe(expectedValue);
}

export function checkCheckedStatusOfSwitch(node: HTMLElement, name: string, isChecked: boolean): void {
  const checkboxInput = node.querySelector(`input[name="${name}"]`) as HTMLInputElement;
  expect(checkboxInput.checked).toBe(isChecked);
}

export function checkCheckedStatusOfMUIRadioGroup(node: HTMLElement, value: string, isChecked: boolean): void {
  const checkboxEl = node.querySelector(`input[value="${value}"]`) as HTMLInputElement;
  expect(checkboxEl.checked).toBe(isChecked);
}

export function checkValueOfNumericInput(node: HTMLElement, expectedValue: string | number): void {
  const inputEl = node.getElementsByTagName('input')[0];
  expect(inputEl.value).toBe(expectedValue);
}

export function checkValueOfCheckboxInput(node: HTMLElement, name: string, expectedValue: boolean): void {
  const inputEl = node.querySelector(`input[name="${name}"]`) as HTMLInputElement;
  expect(inputEl.value).toBe(`${expectedValue}`);
}

export function checkValueOfSelectInput(node: HTMLElement, name: string, expectedValue: string): void {
  const inputEl = node.querySelector(`input[name="${name}"]`) as HTMLInputElement;
  expect(inputEl.value).toBe(expectedValue);
}

const customRender = (ui: ReactElement, options?: Omit<RenderOptions, 'queries'>) => {
  return render(ui, { queries: { ...queries, ...customQueries }, ...options });
};

export { I18NEXT_OPTIONS, customRender as render };
