import { useSPKCookie } from '@portals/forms/src/hooks/useSPKCookie/useSPKCookie';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';

interface BankData {
  bankCode: string;
  bankName: string;
}

export const useBankData = (): BankData => {
  const cookieData = useSPKCookie({
    cookieDomain: ConfigProvider.getConfig().get('NEXT_PUBLIC_COOKIE_DOMAIN'),
  });
  const bankCode = cookieData.bankCode;
  const bankName = cookieData.bankName;

  return { bankCode, bankName };
};
