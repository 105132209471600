import { Box, useMediaQuery, useTheme } from '@mui/material';
import { CategoryLinkList } from '@portals/core/src/components/CategoryLinkList/CategoryLinkList';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { ProductLink } from '@portals/core/src/components/ProductLink/ProductLink';
import { EstateDetail as EstateDetailType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React from 'react';

import { Paragraph } from '../../../../icons';
import { CenteredContainer } from '../Shared/CenteredContainer/CenteredContainer';
import { SectionHeadline } from '../Shared/SectionHeadline/SectionHeadline';
import { getStyles } from './BrokerAndProvider.styles';
import { ContactHeader } from './ContactHeader/ContactHeader';
import { ContactInformation } from './ContactInformation/ContactInformation';
interface Props {
  estate: EstateDetailType;
  openImprint: () => void;
  openPrivacyPolicy: () => void;
  openConsumerInformation: () => void;
  setContactFormOpen: (boolean) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const BrokerAndProvider = ({
  estate,
  openImprint,
  openPrivacyPolicy,
  openConsumerInformation,
  setContactFormOpen,
}: Props): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme).brokerAndProvider;
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <CenteredContainer>
        <SectionHeadline headline={t('detailPage.provider')} />
      </CenteredContainer>

      <CenteredContainer disableDefaultPadding>
        <Divider sx={styles.divider} />
      </CenteredContainer>

      <CenteredContainer>
        <Box sx={styles.contactHeaderContainer}>
          <ContactHeader estate={estate} />
          <ContactInformation broker={estate.broker} setContactFormOpen={setContactFormOpen} />
        </Box>
        {!estate.isUserEstate && (
          <Box sx={styles.categoryLinkListContainer} className="estate-link-list">
            <CategoryLinkList>
              <ProductLink onClick={openImprint} startIcon={isLgUp ? <Paragraph /> : null}>
                {t('detailPage.imprint')}
              </ProductLink>
              {estate.contact?.privacyPolicy && (
                <ProductLink onClick={openPrivacyPolicy} startIcon={isLgUp ? <Paragraph /> : null}>
                  {t('detailPage.privacyPolicy')}
                </ProductLink>
              )}
              {estate.contact?.consumerInformation && (
                <ProductLink onClick={openConsumerInformation} startIcon={isLgUp ? <Paragraph /> : null}>
                  {t('detailPage.consumerInformation')}
                </ProductLink>
              )}
            </CategoryLinkList>
          </Box>
        )}
      </CenteredContainer>
    </>
  );
};
BrokerAndProvider.displayName = 'BrokerAndProvider';
