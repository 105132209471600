import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const SparkasseLogoForPrint: typeof SvgIcon & { height: string } = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" viewBox="0 0 150 42" fill="none" {...props}>
    <g clipPath="url(#clip0_391_718)">
      <g clipPath="url(#clip1_391_718)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.30938 4.73504C9.30938 4.08107 9.4344 3.46815 9.68345 2.8943C9.9325 2.32245 10.2662 1.8197 10.6846 1.38606C11.102 0.952416 11.5961 0.612911 12.1671 0.367546C12.738 0.122182 13.3454 0 13.9882 0C14.631 0 15.2344 0.122182 15.7965 0.367546C16.3596 0.612911 16.8537 0.952416 17.28 1.38606C17.7062 1.8197 18.0399 2.32245 18.2811 2.8943C18.5223 3.46815 18.6434 4.08107 18.6434 4.73504C18.6434 5.39001 18.5223 6.00292 18.2811 6.57577C18.0399 7.14762 17.7062 7.65137 17.28 8.08402C16.8537 8.51766 16.3596 8.85716 15.7965 9.10253C15.2344 9.34789 14.631 9.47108 13.9882 9.47108C13.3454 9.47108 12.738 9.34789 12.1671 9.10253C11.5961 8.85716 11.102 8.51766 10.6846 8.08402C10.2662 7.65137 9.9325 7.14762 9.68345 6.57577C9.4344 6.00292 9.30938 5.39001 9.30938 4.73504ZM0 32.265V30.3751H21.44V27.5289H0V17.0764C0 16.4234 0.12108 15.8095 0.362256 15.2366C0.602447 14.6638 0.936155 14.161 1.3624 13.7274C1.78864 13.2948 2.2828 12.9553 2.84587 12.7099C3.40796 12.4645 4.01139 12.3413 4.65518 12.3413H23.3458C23.9886 12.3413 24.596 12.4645 25.1669 12.7099C25.7379 12.9553 26.232 13.2948 26.6494 13.7274C27.0678 14.161 27.3976 14.6638 27.6387 15.2366C27.8799 15.8095 28 16.4234 28 17.0764V18.9662H6.51174V21.8124H28V32.265C28 32.9189 27.8799 33.5328 27.6387 34.1047C27.3976 34.6775 27.0678 35.1803 26.6494 35.6139C26.232 36.0476 25.7379 36.3871 25.1669 36.6314C24.596 36.8768 23.9886 37 23.3458 37H4.65518C4.01139 37 3.40796 36.8768 2.84587 36.6314C2.2828 36.3871 1.78864 36.0476 1.3624 35.6139C0.936155 35.1803 0.602447 34.6775 0.362256 34.1047C0.12108 33.5328 0 32.9189 0 32.265Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.1842 38C46.5049 38 45.8848 37.9571 45.3251 37.8713C44.7634 37.7865 44.262 37.6854 43.819 37.5669C43.3769 37.4484 43.0028 37.3258 42.6975 37.2002C42.3923 37.0735 42.1588 36.9703 42 36.8876L42.6631 34.8721C42.8841 34.9947 43.241 35.1418 43.7329 35.3124C44.2256 35.483 44.7538 35.6168 45.3184 35.7149C45.8839 35.8129 46.4101 35.862 46.8991 35.862C47.8904 35.862 48.6626 35.7088 49.2166 35.4033C49.7697 35.0989 50.1208 34.721 50.2701 34.2735C50.4184 33.8251 50.4921 33.3971 50.4921 32.9905C50.4921 32.3061 50.2825 31.7535 49.8634 31.3347C49.4434 30.9148 48.9372 30.5808 48.3468 30.3326C47.7555 30.0833 47.2187 29.8627 46.7383 29.6665C46.28 29.4949 45.8466 29.3151 45.4389 29.1292C45.0304 28.9413 44.6189 28.6992 44.2027 28.4019C43.7864 28.1046 43.4353 27.7492 43.1501 27.3385C42.8631 26.9279 42.6746 26.5152 42.5827 26.1045C42.4918 25.6929 42.4459 25.2587 42.4459 24.8021C42.4459 24.0697 42.576 23.4057 42.8353 22.8112C43.0946 22.2167 43.4649 21.711 43.9453 21.2953C44.4266 20.8805 45.0112 20.5608 45.7021 20.3361C46.3929 20.1124 47.1689 20 48.032 20C48.6884 20 49.2979 20.0429 49.8634 20.1287C50.428 20.2145 50.8949 20.3116 51.2652 20.4219C51.6355 20.5322 51.9006 20.6068 52.0613 20.6476V22.8602C51.6719 22.705 51.2518 22.5681 50.8021 22.4506C50.3514 22.3321 49.8883 22.2412 49.4108 22.1758C48.9334 22.1104 48.4664 22.0777 48.009 22.0777C46.9862 22.0777 46.1901 22.3015 45.6226 22.7499C45.0533 23.1983 44.7691 23.8419 44.7691 24.6806C44.7691 25.1035 44.8591 25.463 45.038 25.7562C45.2179 26.0494 45.4715 26.3078 45.7997 26.5315C46.1279 26.7563 46.5173 26.9616 46.967 27.1485C47.4177 27.3365 47.9134 27.5316 48.4549 27.7359C49.0511 27.9719 49.6223 28.2201 50.1725 28.4806C50.7217 28.7421 51.204 29.0649 51.6202 29.452C52.0364 29.8392 52.3694 30.3142 52.622 30.876C52.8737 31.4388 53 32.1345 53 32.966C53 33.773 52.868 34.4891 52.6048 35.1163C52.3417 35.7435 51.9599 36.2716 51.4604 36.6986C50.96 37.1266 50.3514 37.4504 49.6347 37.67C48.9171 37.8907 48.0999 38 47.1842 38Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.093 35.209C61.6759 35.209 62.1868 35.0944 62.6268 34.8663C63.0677 34.6372 63.3931 34.3518 63.604 34.0091C63.8138 33.6664 63.972 33.3278 64.0764 32.9934C64.1899 32.6272 64.2751 32.2313 64.3319 31.8068C64.3876 31.3823 64.416 30.9373 64.416 30.4729C64.416 29.4695 64.2944 28.6235 64.0521 27.9341C63.8098 27.2446 63.454 26.7588 62.9846 26.4775C62.5163 26.1951 62.0074 26.056 61.4569 26.056C60.9724 26.056 60.5395 26.1716 60.1593 26.4048C59.7792 26.636 59.4609 26.9613 59.2014 27.3776C58.9429 27.7929 58.7462 28.2901 58.6134 28.87C58.4796 29.449 58.4137 30.0812 58.4137 30.7665V34.0214C58.7766 34.3886 59.177 34.6781 59.614 34.8898C60.0509 35.1026 60.5435 35.209 61.093 35.209ZM56 42V24.2567H58.3042L58.3529 26.1051C58.5708 25.6806 58.878 25.2991 59.2744 24.9595C59.6707 24.6209 60.1127 24.3764 60.6023 24.2261C61.091 24.0747 61.5948 24 62.1118 24C63.2766 24 64.2427 24.3079 65.0111 24.9227C65.7785 25.5395 66.3026 26.3517 66.5803 27.3582C66.8601 28.3668 67 29.3886 67 30.4238C67 31.4928 66.8824 32.4471 66.6472 33.2869C66.4131 34.1267 66.0674 34.8356 65.6102 35.4105C65.154 35.9853 64.5883 36.4242 63.9132 36.7259C63.238 37.0277 62.4636 37.1791 61.5907 37.1791C60.8791 37.1791 60.2648 37.087 59.7467 36.9029C59.2297 36.7198 58.7857 36.4722 58.4137 36.1633V42H56Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.6248 35.1535C74.0598 35.1535 74.4978 35.0839 74.9369 34.9477C75.376 34.8105 75.7244 34.6561 75.98 34.4835C76.2335 34.31 76.4356 34.1475 76.584 33.9942V30.6019H75.6698C74.9555 30.6019 74.335 30.6443 73.8093 30.729C73.2826 30.8138 72.8456 30.9571 72.4962 31.1579C72.1457 31.3587 71.886 31.62 71.7128 31.9419C71.5407 32.2637 71.4541 32.6583 71.4541 33.1254C71.4541 33.5351 71.5407 33.9074 71.7128 34.2414C71.886 34.5754 72.1447 34.8105 72.49 34.9477C72.8353 35.0839 73.2136 35.1535 73.6248 35.1535ZM72.9456 37C72.4117 37 71.9045 36.9304 71.4232 36.7952C70.9429 36.6579 70.5234 36.438 70.1657 36.1363C69.8081 35.8346 69.5246 35.4451 69.3144 34.9658C69.1051 34.4876 69 33.9054 69 33.2223C69 32.2718 69.2226 31.4898 69.6658 30.8733C70.1101 30.2578 70.7635 29.7806 71.6263 29.4436C72.1117 29.2579 72.6559 29.1217 73.2599 29.0329C73.8639 28.9441 74.5411 28.8927 75.2894 28.8765C75.3214 28.8765 75.4028 28.8745 75.5296 28.8694C75.6563 28.8654 75.7914 28.8634 75.9295 28.8634C76.0686 28.8634 76.2037 28.8614 76.3304 28.8573C76.4572 28.8543 76.5417 28.8523 76.584 28.8523V28.1763C76.584 27.5487 76.4655 27.0633 76.2315 26.7213C75.9985 26.3792 75.6378 26.1462 75.1523 26.021C74.6679 25.8969 74.1505 25.8344 73.599 25.8344C72.9085 25.8344 72.2725 25.903 71.6881 26.0392C71.1047 26.1764 70.5461 26.3369 70.0111 26.5225V24.5671C70.7017 24.3905 71.2026 24.2734 71.516 24.2169C71.8283 24.1604 72.2272 24.11 72.7116 24.0656C73.196 24.0212 73.6526 24 74.0794 24C74.9276 24 75.6605 24.0797 76.282 24.2412C76.9025 24.4016 77.4137 24.6518 77.8157 24.9888C78.2187 25.3268 78.5166 25.7577 78.7104 26.2814C78.9031 26.804 79 27.4276 79 28.152V36.7578H76.6829L76.6324 35.6964C76.3871 35.9375 76.0707 36.1565 75.6904 36.3532C75.308 36.551 74.8627 36.7084 74.3577 36.8244C73.8526 36.9405 73.3816 37 72.9456 37Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83 37V24.0751H85.3551L85.4027 26.5207C85.6927 25.9294 86.0438 25.4392 86.457 25.0481C86.8692 24.657 87.299 24.3848 87.7454 24.2305C88.1917 24.0772 88.6205 24 89.0337 24C89.4552 24 89.7773 24.025 90 24.0751V26.4206C89.8012 26.3706 89.5081 26.3455 89.1207 26.3455C88.5014 26.3455 87.9639 26.4602 87.5103 26.6886C87.0556 26.9181 86.6755 27.2112 86.37 27.5689C86.0645 27.9256 85.8367 28.3208 85.6886 28.7536C85.5394 29.1854 85.4659 29.6026 85.4659 30.002V37H83Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93 37V19H95.2824V29.4053L99.7197 24.6091H102.46L97.4603 29.9672C97.9502 30.4141 98.4438 30.917 98.9404 31.4739C99.4369 32.0328 99.921 32.6167 100.391 33.2266C100.86 33.8365 101.316 34.4624 101.756 35.1043C102.195 35.7472 102.61 36.3781 103 37H100.144C99.6622 36.2831 99.1254 35.5292 98.533 34.7404C97.9406 33.9505 97.3673 33.2326 96.8123 32.5867C96.2582 31.9408 95.7483 31.3779 95.2824 30.9V37H93Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.624 35.1535C109.06 35.1535 109.497 35.0839 109.937 34.9477C110.376 34.8105 110.723 34.6561 110.979 34.4835C111.235 34.31 111.436 34.1475 111.583 33.9942V30.6019H110.671C109.955 30.6019 109.335 30.6443 108.809 30.729C108.283 30.8138 107.845 30.9571 107.496 31.1579C107.147 31.3587 106.885 31.62 106.713 31.9419C106.541 32.2637 106.454 32.6583 106.454 33.1254C106.454 33.5351 106.541 33.9074 106.713 34.2414C106.885 34.5754 107.145 34.8105 107.49 34.9477C107.835 35.0839 108.213 35.1535 108.624 35.1535ZM107.946 37C107.412 37 106.903 36.9304 106.423 36.7952C105.942 36.6579 105.523 36.438 105.166 36.1363C104.807 35.8346 104.525 35.4451 104.314 34.9658C104.105 34.4876 104 33.9054 104 33.2223C104 32.2718 104.222 31.4898 104.666 30.8733C105.109 30.2578 105.764 29.7806 106.626 29.4436C107.112 29.2579 107.656 29.1217 108.26 29.0329C108.865 28.9441 109.54 28.8927 110.289 28.8765C110.321 28.8765 110.402 28.8745 110.529 28.8694C110.656 28.8654 110.79 28.8634 110.929 28.8634C111.069 28.8634 111.203 28.8614 111.33 28.8573C111.457 28.8543 111.542 28.8523 111.583 28.8523V28.1763C111.583 27.5487 111.465 27.0633 111.232 26.7213C110.997 26.3792 110.638 26.1462 110.153 26.021C109.668 25.8969 109.149 25.8344 108.599 25.8344C107.908 25.8344 107.271 25.903 106.687 26.0392C106.105 26.1764 105.545 26.3369 105.01 26.5225V24.5671C105.701 24.3905 106.204 24.2734 106.515 24.2169C106.827 24.1604 107.226 24.11 107.712 24.0656C108.196 24.0212 108.653 24 109.08 24C109.927 24 110.66 24.0797 111.281 24.2412C111.901 24.4016 112.414 24.6518 112.817 24.9888C113.219 25.3268 113.517 25.7577 113.71 26.2814C113.903 26.804 114 27.4276 114 28.152V36.7578H111.682L111.632 35.6964C111.386 35.9375 111.071 36.1565 110.689 36.3532C110.307 36.551 109.863 36.7084 109.358 36.8244C108.852 36.9405 108.382 37 107.946 37Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.209 37C120.678 37 120.188 36.9697 119.743 36.9092C119.299 36.8486 118.9 36.774 118.546 36.6852C118.192 36.5964 117.886 36.5056 117.628 36.4087C117.369 36.3119 117.16 36.2231 117 36.1423L117.605 34.3322C118 34.5007 118.358 34.6359 118.677 34.7368C118.997 34.8367 119.368 34.9194 119.789 34.984C120.211 35.0476 120.636 35.0799 121.061 35.0799C121.746 35.0799 122.291 34.9971 122.698 34.8327C123.105 34.6682 123.371 34.4482 123.496 34.1748C123.622 33.9013 123.684 33.6319 123.684 33.3666C123.684 32.9327 123.527 32.5836 123.211 32.3223C122.895 32.0609 122.498 31.849 122.019 31.6886C121.541 31.5272 121.099 31.3708 120.696 31.2174C120.216 31.0489 119.775 30.8723 119.368 30.6867C118.96 30.501 118.607 30.277 118.306 30.0106C118.006 29.7453 117.772 29.4234 117.605 29.045C117.438 28.6666 117.354 28.2045 117.354 27.6566C117.354 27.0694 117.459 26.5457 117.668 26.0876C117.876 25.6285 118.172 25.2461 118.551 24.9414C118.932 24.6357 119.391 24.4016 119.932 24.2412C120.472 24.0797 121.069 24 121.723 24C122.498 24 123.171 24.0484 123.742 24.1453C124.311 24.2412 124.814 24.3572 125.248 24.4944V26.4741C124.753 26.2965 124.229 26.1583 123.678 26.0574C123.127 25.9565 122.521 25.907 121.859 25.907C121.076 25.907 120.498 26.0362 120.126 26.2925C119.753 26.5498 119.567 26.9402 119.567 27.4639C119.567 27.7293 119.634 27.9543 119.767 28.1399C119.899 28.3246 120.088 28.4901 120.331 28.6354C120.574 28.7796 120.873 28.9138 121.227 29.039C121.58 29.1641 121.977 29.2983 122.419 29.4436C123.331 29.7402 124.047 30.055 124.569 30.385C125.089 30.7149 125.457 31.1256 125.675 31.616C125.892 32.1063 126 32.6421 126 33.2223C126 33.858 125.887 34.4119 125.658 34.8871C125.43 35.3624 125.107 35.7559 124.688 36.0707C124.269 36.3835 123.766 36.6176 123.177 36.7699C122.587 36.9233 121.931 37 121.209 37Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.21 37C131.678 37 131.188 36.9697 130.743 36.9092C130.299 36.8486 129.9 36.774 129.547 36.6852C129.192 36.5964 128.886 36.5056 128.628 36.4087C128.369 36.3119 128.16 36.2231 128 36.1423L128.605 34.3322C129 34.5007 129.358 34.6359 129.677 34.7368C129.997 34.8367 130.368 34.9194 130.789 34.984C131.211 35.0476 131.636 35.0799 132.061 35.0799C132.746 35.0799 133.291 34.9971 133.698 34.8327C134.105 34.6682 134.371 34.4482 134.496 34.1748C134.622 33.9013 134.684 33.6319 134.684 33.3666C134.684 32.9327 134.527 32.5836 134.211 32.3223C133.895 32.0609 133.498 31.849 133.019 31.6886C132.541 31.5272 132.099 31.3708 131.696 31.2174C131.216 31.0489 130.775 30.8723 130.368 30.6867C129.96 30.501 129.607 30.277 129.307 30.0106C129.006 29.7453 128.772 29.4234 128.605 29.045C128.438 28.6666 128.355 28.2045 128.355 27.6566C128.355 27.0694 128.459 26.5457 128.668 26.0876C128.876 25.6285 129.172 25.2461 129.551 24.9414C129.932 24.6357 130.391 24.4016 130.932 24.2412C131.472 24.0797 132.069 24 132.724 24C133.498 24 134.171 24.0484 134.742 24.1453C135.311 24.2412 135.814 24.3572 136.248 24.4944V26.4741C135.753 26.2965 135.229 26.1583 134.678 26.0574C134.127 25.9565 133.521 25.907 132.859 25.907C132.076 25.907 131.498 26.0362 131.126 26.2925C130.753 26.5498 130.567 26.9402 130.567 27.4639C130.567 27.7293 130.634 27.9543 130.767 28.1399C130.899 28.3246 131.088 28.4901 131.331 28.6354C131.574 28.7796 131.873 28.9138 132.227 29.039C132.58 29.1641 132.977 29.2983 133.419 29.4436C134.331 29.7402 135.047 30.055 135.569 30.385C136.089 30.7149 136.458 31.1256 136.675 31.616C136.892 32.1063 137 32.6421 137 33.2223C137 33.858 136.887 34.4119 136.659 34.8871C136.43 35.3624 136.107 35.7559 135.688 36.0707C135.269 36.3835 134.766 36.6176 134.177 36.7699C133.587 36.9233 132.931 37 132.21 37Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.364 29.5188H147.615V28.9554C147.615 27.9347 147.419 27.1442 147.027 26.5858C146.635 26.0274 146.02 25.7472 145.183 25.7472C144.3 25.7472 143.627 26.0784 143.163 26.7409C142.698 27.4044 142.432 28.329 142.364 29.5188ZM145.651 37C144.753 37 143.954 36.8489 143.255 36.5457C142.553 36.2425 141.962 35.8092 141.479 35.2458C140.995 34.6844 140.629 34.0029 140.377 33.2054C140.126 32.4078 140 31.5132 140 30.5245C140 29.5588 140.115 28.6742 140.344 27.8717C140.572 27.0711 140.906 26.3816 141.348 25.8073C141.789 25.2329 142.337 24.7875 142.991 24.4723C143.646 24.1571 144.395 24 145.24 24C146.816 24 148.003 24.4983 148.802 25.496C149.6 26.4937 150 27.9217 150 29.781C150 30.3324 149.985 30.8157 149.954 31.231H142.409C142.501 32.4839 142.835 33.4435 143.414 34.11C143.992 34.7765 144.848 35.1097 145.982 35.1097C146.508 35.1097 147.017 35.0717 147.512 34.9946C148.007 34.9196 148.44 34.8255 148.813 34.7134L149.259 36.5327C148.763 36.6938 148.229 36.8109 147.655 36.8869C147.079 36.962 146.412 37 145.651 37Z"
          fill="#FF0000"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_391_718">
        <rect width="150" height="42" fill="#FF0000" />
      </clipPath>
      <clipPath id="clip1_391_718">
        <rect width="150" height="42" fill="#FF0000" />
      </clipPath>
    </defs>
  </SvgIcon>
);

SparkasseLogoForPrint.height = 'inherit';
SparkasseLogoForPrint.muiName = 'SparkasseLogoForPrint';
