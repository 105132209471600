import { Typography } from '@portals/core/src/components/Typography/Typography';
import i18next from 'i18next';
import React from 'react';

import { CenteredContainer } from '../../Shared/CenteredContainer/CenteredContainer';

interface FinanceConditionsSectionTitleProps {
  text: string;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const FinanceConditionsSectionTitle: React.FC<FinanceConditionsSectionTitleProps> = ({ text }) => {
  return (
    <CenteredContainer>
      <Typography variant="h4" className="finance-block-text">
        {t(text)}
      </Typography>
    </CenteredContainer>
  );
};

FinanceConditionsSectionTitle.displayName = 'FinanceConditionsSectionTitle';

export default FinanceConditionsSectionTitle;
