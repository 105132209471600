import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import type { FilterDrawerOptions } from '../FiltersView/FiltersView';

export type ShowResultFilterDrawerType = (
  showAllFilter: FilterDrawerOptions['showAllFilter'],
  criteria: FilterDrawerOptions['criteria']
) => void;

export type UseResultPageFilterDrawerReturnType = {
  resultFilterDrawerOptions: FilterDrawerOptions;
  setResultFilterDrawerOptions: Dispatch<SetStateAction<FilterDrawerOptions>>;
  showResultFilterDrawer: ShowResultFilterDrawerType;
  hideResultFilterDrawer: () => void;
};

export const useFilterDrawer = (): UseResultPageFilterDrawerReturnType => {
  const [resultFilterDrawerOptions, setResultFilterDrawerOptions] = useState<FilterDrawerOptions>({
    visible: false,
    criteria: null,
    showAllFilter: false,
  });

  const showResultFilterDrawer: ShowResultFilterDrawerType = useCallback((showAllFilter, criteria) => {
    setResultFilterDrawerOptions({
      visible: true,
      criteria,
      showAllFilter,
    });
  }, []);

  const hideResultFilterDrawer = useCallback(() => {
    setResultFilterDrawerOptions({
      visible: false,
      criteria: null,
      showAllFilter: false,
    });
  }, []);

  return {
    resultFilterDrawerOptions,
    setResultFilterDrawerOptions,
    showResultFilterDrawer,
    hideResultFilterDrawer,
  };
};
