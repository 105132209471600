import { Box, useTheme } from '@mui/material';
import { Avatar } from '@portals/core/src/components/Avatar/Avatar';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Broker } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import { getStyles } from '../BrokerContact.styles';

interface Props {
  broker: Broker;
  providerCompany: string;
  variant?: 'standard' | 'compact';
}

export const BrokerAvatarCard: React.FunctionComponent<Props> = ({
  broker,
  providerCompany,
  variant = 'standard',
}: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme).brokerAvatarCard;

  const avatarContainerStyles = variant === 'standard' ? styles.standardAvatarContainer : styles.compactAvatarContainer;
  const avatarStyles = variant === 'standard' ? styles.standardAvatarSize : styles.compactAvatarSize;
  const textContainerStyles = variant === 'standard' ? styles.standardTextContainer : styles.compactTextContainer;

  return (
    <>
      <Box sx={avatarContainerStyles}>
        <Avatar sx={avatarStyles} alt={broker.fullSalutation} src={broker.imageUrl} />
      </Box>
      <Box sx={textContainerStyles}>
        <Typography fontWeight="400" variant="h5">
          {broker.fullSalutation}
        </Typography>
        <Typography variant="h6" color="secondary.dark">
          {providerCompany}
        </Typography>
      </Box>
    </>
  );
};

BrokerAvatarCard.displayName = 'BrokerContactDetails';
