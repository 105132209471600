import { FinalContainer, FinalGridContainer, FinalGridItem } from '@portals/core/src/components/FinalGrid/FinalGrid';
import i18next from 'i18next';
import React from 'react';

import { NotificationActionCard } from '../../../components';
import { HeartWithSearch } from '../../../icons';

interface NoResultProps {
  saveSearchButtonLabel?: string;
  onClickSaveSearch?: () => void;
  isSaveSearchButtonDisabled: boolean;
  showHeadlineOnly?: boolean;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const NoResult = ({
  saveSearchButtonLabel,
  onClickSaveSearch,
  isSaveSearchButtonDisabled,
  showHeadlineOnly,
}: NoResultProps): React.ReactElement => {
  return (
    <FinalContainer width="100%" mt={{ xs: 0, md: 0, lg: 0 }}>
      <FinalGridContainer>
        <FinalGridItem
          xs={12}
          md={8}
          lg={8}
          mt={{
            xs: 7,
            md: 0,
            lg: 0,
          }}
          mb={8}
          px={5}
        >
          <NotificationActionCard
            headline={t('noResultHeadline')}
            text={showHeadlineOnly ? '' : t('noResultNotification')}
            buttonLabel={saveSearchButtonLabel}
            onClick={onClickSaveSearch}
            buttonIcon={<HeartWithSearch />}
            isSaveSearchButtonDisabled={isSaveSearchButtonDisabled}
          />
        </FinalGridItem>
      </FinalGridContainer>
    </FinalContainer>
  );
};

NoResult.displayName = 'NoResult';
