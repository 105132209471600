import { Grid, useTheme } from '@mui/material';
import { EstateDetail as EstateDetailType, GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { useCallback } from 'react';

import { GalleryFilterType } from '../../../../components';
import { DetailImage } from './DetailImage/DetailImage';
import { getStyles } from './ImageSection.styles';
import { getAdditionalImageCount, getImageObject } from './ImageSection.utils';
import { PreviewImage } from './PreviewImage/PreviewImage';

interface Props {
  images: Array<GalleryItems>;
  estate: EstateDetailType;
  handleGalleryFilter: (filter: GalleryFilterType | null, galleryPosition: number | null) => void;
}

export const ImageSection = ({ images, estate, handleGalleryFilter }: Props): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const handleOnClick = useCallback(
    (galleryPosition) => () => handleGalleryFilter(GalleryFilterType.IMAGE, galleryPosition),
    [handleGalleryFilter]
  );

  return (
    <Grid container height={1} spacing={2} sx={styles.imageSection}>
      <Grid item xs={12} lg={6} sx={styles.detailImageOuterContainer}>
        <DetailImage
          imageObject={getImageObject(images[0], 'xl', estate.seo.title)}
          additionalImageCount={getAdditionalImageCount(images, 1)}
          onClick={handleOnClick(0)}
        />
      </Grid>
      <Grid item lg={6} sx={styles.previewImageOuterContainer}>
        <Grid container height={1} spacing={2}>
          <PreviewImage imageObject={getImageObject(images[1], 'm', estate.seo.title)} onClick={handleOnClick(1)} />
          <PreviewImage imageObject={getImageObject(images[2], 'm', estate.seo.title)} onClick={handleOnClick(2)} />
          <PreviewImage imageObject={getImageObject(images[3], 'm', estate.seo.title)} onClick={handleOnClick(3)} />
          <PreviewImage
            imageObject={getImageObject(images[4], 'm', estate.seo.title)}
            additionalImageCount={getAdditionalImageCount(images, 5)}
            onClick={handleOnClick(4)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
ImageSection.displayName = 'ImageSection';
