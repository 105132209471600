import { Box, BoxProps, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface ImageObjectProps {
  description: string;
  webpUrl: string | undefined;
  defaultUrl: string;
  defaultImageType: string;
}
interface EstateImageProps extends BoxProps {
  imageObject: ImageObjectProps;
  imageStyle: React.CSSProperties | SxProps;
}

export const EstateImage: React.FC<EstateImageProps> = ({ imageObject, imageStyle, ...props }: EstateImageProps) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 600, height: 400 });
  const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  useEffect(() => {
    if (imageObject) {
      loadImage(setImageDimensions, imageObject.defaultUrl);
    }
  }, []);

  if (!imageObject) {
    return null;
  }

  return (
    <Box height={1} {...props}>
      <picture
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
        }}
      >
        {imageObject.webpUrl && (
          <source srcSet={imageObject.webpUrl} type="image/webp" title={imageObject.description} style={imageStyle} />
        )}
        {imageObject.defaultUrl && (
          <>
            <source
              srcSet={imageObject.defaultUrl}
              type={imageObject.defaultImageType}
              title={imageObject.description}
            />
            <img
              src={imageObject.defaultUrl}
              alt={`${imageObject.description} - ${imageObject.seoTitle}`}
              title={imageObject.description}
              style={imageStyle}
              width={imageDimensions.width}
              height={imageDimensions.height}
            />
          </>
        )}
      </picture>
    </Box>
  );
};

EstateImage.displayName = 'EstateImage';
