import { Box, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import { EnergyEfficiencyClass } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { inRange } from 'lodash-es';
import React, { useCallback } from 'react';

import { ColorRangeImage } from './ColorRangeImage';
import { getStyles } from './EnergyConsumption.styles';
import Label from './Label/Label';
import Marker from './Marker/Marker';
import Scale from './Scale/Scale';

export interface EnergyConsumptionProps {
  efficiencyClass?: string;
  powerConsumption: number;
}

type configValues = { label: EnergyEfficiencyClass; min: number; max: number };

const SCALE_NUMBERS = [0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250];
const SCALE_END = 275;
const ENERGY_EFFICIENCY_CLASSES_CONFIG: configValues[] = [
  { label: 'A+', min: 0, max: 30 },
  { label: 'A', min: 30, max: 50 },
  { label: 'B', min: 50, max: 75 },
  { label: 'C', min: 75, max: 100 },
  { label: 'D', min: 100, max: 130 },
  { label: 'E', min: 130, max: 160 },
  { label: 'F', min: 160, max: 200 },
  { label: 'G', min: 200, max: 250 },
  { label: 'H', min: 250, max: 275 },
];

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EnergyConsumption = ({ efficiencyClass, powerConsumption }: EnergyConsumptionProps): JSX.Element => {
  const theme = useTheme<Theme>();
  const styles = getStyles(theme);

  const numberFormatter = useNumberFormatter({});

  const calculatePercent = useCallback((value: number) => {
    return Number(((100 * value) / SCALE_END).toFixed(2));
  }, []);

  const getLabelElement = (
    <Typography variant="button" fontWeight="700" sx={{ verticalAlign: 'top' }}>
      {t('detailPage.energyConsumptionWithUnit', { consumption: numberFormatter(String(powerConsumption)) })}
    </Typography>
  );

  const getEfficiencyClassFromConfig = () => {
    let letter = '';
    ENERGY_EFFICIENCY_CLASSES_CONFIG.map(({ label, min, max }) => {
      if (inRange(powerConsumption, min, max)) {
        letter = label;
      }
    });
    return letter;
  };

  const efficiencyClassLetter = efficiencyClass ? efficiencyClass : getEfficiencyClassFromConfig();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.spectrum}>
        <ColorRangeImage />
        {ENERGY_EFFICIENCY_CLASSES_CONFIG.map((config) => {
          return (
            <Label
              key={`energy_consumption_label_${config.label}`}
              label={config.label}
              highlighted={config.label === efficiencyClassLetter}
              positionLeftInPercent={calculatePercent(config.min)}
              positionRightInPercent={calculatePercent(config.max)}
            />
          );
        })}
        <Marker
          label={getLabelElement}
          positionInPercent={calculatePercent(Math.min(powerConsumption, SCALE_END))}
          labelAlignment={calculatePercent(powerConsumption) >= 50 ? 'right' : 'left'}
        />
      </Box>

      <Scale scaleNumbers={SCALE_NUMBERS} calculatePercent={calculatePercent} />

      <Typography variant="button" component="div" sx={styles.legend} className="energy-consumption-legend">
        {t('detailPage.energyPassLegend')}
      </Typography>
    </Box>
  );
};

EnergyConsumption.displayName = 'EnergyConsumption';
