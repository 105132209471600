import { Box, Paper } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import { Form } from '@portals/forms/src/containers/Form/Form';
import { SparkasseAssignmentForm } from '@portals/forms/src/forms/SparkasseAssignmentForm/SparkasseAssignmentForm';
import { ArrowRight } from '@portals/icons';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import i18next from 'i18next';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useIF6Context } from '../../../../context';
import { useBankData, useBankDataFromConfig } from '../../../../hooks';
import { FinanceData } from '../../../../types';
import { isFinanceCertificateEnabled } from '../../../../utils';
import { CenteredContainer } from '../Shared/CenteredContainer/CenteredContainer';
import { SectionHeadline } from '../Shared/SectionHeadline/SectionHeadline';
import { getStyles } from './FinanceBlock.styles';
import FinanceCertificate from './FinanceCertificate/FinanceCertificate';
import FinancingDetails from './FinancingDetails/FinancingDetails';
import NotificationSparkasseUnknown from './NotificationSparkasseUnknown';

type BankData = {
  bankCode: string;
  bankName: string;
};

export interface FinanceBlockProps {
  financeData: FinanceData;
  estate: EstateDetail;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const FinanceBlock = ({ financeData, estate }: FinanceBlockProps): ReactElement | null => {
  const styles = getStyles(theme).financeBlock;
  const isIF6Scope = useIF6Context();

  const [bankData, setBankData] = useState<BankData>({ bankCode: '', bankName: '' });
  const [showBankData, setShowBankData] = useState(true);

  const useBankDataHook = isIF6Scope ? useBankDataFromConfig : useBankData;
  const { bankCode, bankName } = useBankDataHook();

  useEffect(() => {
    setBankData({ bankCode, bankName });
  }, [bankCode, bankName]);

  const handleOnBankUpdate = useCallback(({ bankCode, bankName }) => {
    if (bankCode && bankName) {
      setShowSparkasseAssignmentForm(false);
      setBankData({ bankCode, bankName });
    }
  }, []);

  const [showSparkasseAssignmentForm, setShowSparkasseAssignmentForm] = useState(false);

  const sparkasseKnown = typeof bankData.bankCode === 'string' && bankData.bankCode.length > 0;

  const handleOnChooseSparkasseClick = useCallback(() => {
    setShowSparkasseAssignmentForm(true);
  }, []);

  const showFinanceCertificate = !estate.financing.hide && sparkasseKnown && isFinanceCertificateEnabled(bankCode);

  const handleOnBack = useCallback(() => {
    setShowSparkasseAssignmentForm(false);
  }, []);

  const handleNoFinancingData = useCallback(() => {
    setShowBankData((prevState) => !prevState);
  }, []);

  const showFinanceBlock = (isIF6Scope && sparkasseKnown && showBankData) || !isIF6Scope;

  if (showFinanceBlock) {
    return (
      <Box data-testid="finance-block" displayPrint="none" className="finance-block">
        <CenteredContainer>
          <SectionHeadline headline={t('detailPage.finance')} />
        </CenteredContainer>
        <Box display="flex" flexDirection="column" gap={8}>
          {showSparkasseAssignmentForm && (
            <CenteredContainer disableDefaultPadding>
              <Paper sx={styles.paper}>
                <Form initialValues={{ locationName: '' }}>
                  <SparkasseAssignmentForm
                    headline={t('detailPage.selectSparkasseHeader')}
                    subline={t('detailPage.selectSparkasseSubline')}
                    cookieDomain={ConfigProvider.getConfig().get('NEXT_PUBLIC_COOKIE_DOMAIN')}
                    onSelectBank={handleOnBankUpdate}
                    onBack={handleOnBack}
                  />
                </Form>
              </Paper>
            </CenteredContainer>
          )}
          {!showSparkasseAssignmentForm && !sparkasseKnown && (
            <CenteredContainer disableDefaultPadding>
              <Paper sx={styles.paper}>
                <NotificationSparkasseUnknown
                  headline={t('detailPage.noBankSelectedHeadline')}
                  text={t('detailPage.noBankSelectedText')}
                  buttonLabel={t('detailPage.selectBankButtonLabel')}
                  buttonIcon={<ArrowRight />}
                  onClick={handleOnChooseSparkasseClick}
                />
              </Paper>
            </CenteredContainer>
          )}
          {!showSparkasseAssignmentForm && sparkasseKnown && (
            <CenteredContainer disableDefaultPadding>
              <FinancingDetails
                estate={estate}
                financeData={financeData}
                bankCode={bankData.bankCode}
                bankName={bankData.bankName}
                onChooseSparkasseClick={handleOnChooseSparkasseClick}
                handleNoFinancingData={handleNoFinancingData}
              />
            </CenteredContainer>
          )}

          {showFinanceCertificate && <FinanceCertificate bankCode={bankCode} />}
        </Box>
      </Box>
    );
  }
  return null;
};

FinanceBlock.displayName = 'FinanceBlock';
