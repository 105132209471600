import { Box, Grid } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { mapZipCityEstateIdToCollection } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import { get } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { Autocomplete } from '../../../../components';
import { mapZipCityEstateCollectionToSingleString } from '../../../../utils';
interface ZipCityEstateIdFilterProps {
  fieldName: string;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const ZipCityEstateIdFilter: React.FC<ZipCityEstateIdFilterProps> = ({
  fieldName,
}: ZipCityEstateIdFilterProps) => {
  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext();

  const [nothingSelected, setNothingSelected] = useState(false);
  const preSelectedOptions = mapZipCityEstateIdToCollection(get(values, fieldName, ''));

  const onChange = useCallback((options) => {
    const value = mapZipCityEstateCollectionToSingleString(options);
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, value, true);
  }, []);

  const onNothingSelected = useCallback((searchIsEmpty: boolean) => {
    setNothingSelected(!searchIsEmpty);
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, '', true);
  }, []);

  const textFieldProps = {
    error: nothingSelected ? Boolean(errors[fieldName]) : false,
    helperText: nothingSelected ? errors[fieldName] : '',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6, height: '100%' }}>
      <Box px={6} display="flex" flexDirection="column" gap={6}>
        <Typography variant="h3" mb={0}>
          {t('location')}
        </Typography>
      </Box>
      <Grid container columnSpacing={5} px={6}>
        <Grid item xs={12}>
          <Autocomplete
            preSelectedOptions={preSelectedOptions}
            onSelectedOptions={onChange}
            textFieldProps={textFieldProps}
            onNothingSelected={onNothingSelected}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ZipCityEstateIdFilter.displayName = 'ZipCityEstateIdFilter';
