import { SvgIconProps, useTheme } from '@mui/material';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import React from 'react';

import { getStyles } from '../TravelTime.style';

interface Props {
  Icon: React.FC<SvgIconProps>;
  label: string;
}

export const TravelTimeBadge = ({ Icon, label }: Props): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return <Pill avatar={<Icon />} label={label} variant="filled" sx={styles.travelTimeBadgePill} />;
};

TravelTimeBadge.displayName = 'TravelTimeBadge';
