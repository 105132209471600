import { Box, GlobalStyles } from '@mui/material';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import React, { useEffect } from 'react';

import { trackEstateView } from '../../utils';
import { getEstateDetailsStyles } from './EstateDetails.styles';
import { EstateDetailsProps } from './EstateDetails.types';
import { ContentSection, HeadSection } from './index';

export const EstateDetails = ({
  estate,
  showBrokerContactInDrawer,
  injectGlobalStyles = true,
}: EstateDetailsProps): React.ReactElement => {
  const styles = getEstateDetailsStyles();

  useEffect(() => {
    trackEstateView(estate);
  }, [estate]);

  return (
    <>
      {injectGlobalStyles && <GlobalStyles styles={styles} />}

      <Box className="estate-details-container">
        <Box className="estate-details-print-container">
          <HeadSection estate={estate} />
          <FinalContainer disableGaping sx={styles.contentSectionContainer}>
            <ContentSection estate={estate} showBrokerContactInDrawer={showBrokerContactInDrawer} />
          </FinalContainer>
        </Box>
      </Box>
    </>
  );
};

EstateDetails.displayName = 'EstateDetails';
