import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  headerContainer: {
    marginBottom: 0,
    '@media print': {
      padding: '0',
      margin: '0',
    },
  },
  headerActionButtons: {
    marginTop: `-${theme.spacing(3)}`,
    mb: 2,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    order: { xs: 1, lg: 3 },
    displayPrint: 'none',
    '@media print': {
      display: 'none',
    },
  },
  objectNumber: { color: theme.palette.grey[800], '@media print': { fontSize: '8px' } },
  logoForPrintOuterContainer: {
    display: 'flex',
    mb: { xs: theme.spacing(3), lg: theme.spacing(5) },
    order: { xs: 2, lg: 1 },
    '@media print': {
      justifyContent: 'space-between',
    },
  },
  logoForPrintInnerContainer: {
    height: '0px',
    width: '0px',
    opacity: '0',
    '@media print': {
      opacity: '1',
      mb: 8,
      height: '75px',
      width: 'auto',
    },
  },
  titleContainer: {
    order: { xs: 3, lg: 2 },
  },
  title: {
    mb: {
      xs: 6,
      lg: 8,
    },
    '@media print': { fontSize: '28px', mb: 7 },
  },
  imageSectionContainer: {
    maxWidth: theme.breakpoints.values.xl,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: { sm: 8, lg: 12 },
    paddingRight: { sm: 8, lg: 12 },
    '@media print': {
      padding: '0',
    },
  },
  gallerySectionContainer: {
    maxWidth: theme.breakpoints.values.xl,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: { xs: 5, lg: 6 },
    paddingBottom: { xs: 5, lg: 7 },
    displayPrint: 'none',
  },
});
