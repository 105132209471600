import { EstateItem, EstateResponse, MainfactCategory } from '../../ApiClientTypes';

export const mapEstateListItemData = (estate: EstateResponse): EstateItem => {
  const removePriceField = (item, index) => {
    return index > 0;
  };

  const mainFacts = estate.main_facts.filter(removePriceField).map((item) => {
    return {
      category: item.label === 'Zimmer' ? MainfactCategory.ROOMS : MainfactCategory.AREA,
      label: item.label,
      value: item.value,
    };
  });

  const priceData = estate.main_facts[0];

  return {
    id: estate.id,
    title: estate.headline,
    subtitle: estate.geo_label,
    images: estate.gallery,
    priceData: priceData,
    price: estate.main_facts[0]?.value,
    eyeCatcher: estate.eyecatcher,
    mainFacts: mainFacts,
    partnerLogo: estate.partner_logo,
    lat: estate.location.lat,
    lng: estate.location.lon,
  };
};
