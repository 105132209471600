import formsDe from '@portals/forms/translations/de.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import coreImmoDe from '../locales/de/core-immobilien.json';

export const I18NEXT_OPTIONS = {
  lng: 'de',
  fallbackLng: 'de',
  resources: {
    de: {
      ...formsDe,
      ...coreImmoDe,
    },
  },
};

export const initializeI18next = () => {
  i18next.use(initReactI18next).init(I18NEXT_OPTIONS);
  return i18next;
};
