import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import i18next from 'i18next';
import React, { FunctionComponent } from 'react';

import { LoansFinanceData } from '../../../../../types/loans';

interface FinanceConditionsTableProps {
  loansData: LoansFinanceData;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const FinanceConditionsTable: FunctionComponent<FinanceConditionsTableProps> = ({ loansData }) => {
  const numberFormatter = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <Table className="finance-conditions-table" padding="none">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="h5">{t('detailPage.duration')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" textAlign="center">
              {t('detailPage.borrowingRate')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" textAlign="center">
              {t('detailPage.effectiveInterestRate')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" textAlign={{ xs: 'center', sm: 'right' }}>
              {t('detailPage.monthlyRate')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loansData.loansConditions.tenYears && (
          <TableRow key={`loans-${loansData.loansConditions.tenYears.years}`}>
            <TableCell>
              <Typography variant="button">{loansData.loansConditions.tenYears.years} Jahre</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="button">
                {loansData.loansConditions.tenYears.interest.toLocaleString('de-DE')} % p.a.
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="button">
                {loansData.loansConditions.tenYears.yearlyInterest.toLocaleString('de-DE')} % p.a.
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="button" color={theme.palette.green.main} fontWeight="bold">
                {numberFormatter(loansData.loansConditions.tenYears.monthlyPayment.toString())} €
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

FinanceConditionsTable.displayName = 'FinanceConditionsTable';

export default FinanceConditionsTable;
