import { EstateDetail as EstateDetailType, FrontendItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { map } from 'lodash-es';
import React from 'react';

import { useIF6Context } from '../../../../context';
import { isLocationBlock, isOtherDataBlock } from '../../../../utils/estateDetailsUtils';
import { EnergyConsumption } from '../../../EnergyConsumption/EnergyConsumption';
import { EstateLocationMap } from '../EstateLocationMap/EstateLocationMap';
import { CenteredContainer } from '../Shared/CenteredContainer/CenteredContainer';
import { SectionContainer } from '../Shared/SectionContainer/SectionContainer';
import { SectionHeadline } from '../Shared/SectionHeadline/SectionHeadline';
import { TravelTime } from '../TravelTime/TravelTime';
import { insertLocationBlockIfMissing, renderContent, updateOtherDataBlockLabels } from './DynamicBlocks.utils';
import { MoreInformationContent } from './MoreInformationContent/MoreInformationContent';

type Props = {
  estate: EstateDetailType;
  requestContactDetails: () => void;
  onMapOpen: () => void;
};

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const DynamicBlocks = ({ estate, requestContactDetails, onMapOpen }: Props): JSX.Element => {
  const isIF6Scope = useIF6Context();
  const { frontendItems, moreInformationData } = estate;

  insertLocationBlockIfMissing(frontendItems, t);
  updateOtherDataBlockLabels(frontendItems, t);

  return (
    <>
      {map(frontendItems, (frontendItem: FrontendItem) => (
        <React.Fragment key={`section-${frontendItem.name}`}>
          <SectionContainer data-id={`dynamic-block-${frontendItem.name}`} key={frontendItem.label}>
            <CenteredContainer>
              <SectionHeadline headline={frontendItem.label} />
            </CenteredContainer>
            {!isIF6Scope && isLocationBlock(frontendItem) && (
              <EstateLocationMap
                lat={estate.lat}
                lng={estate.lng}
                address={estate.address}
                onMapOpen={onMapOpen}
                requestContactDetails={requestContactDetails}
              />
            )}
            {map(frontendItem.contents, (content, key) => (
              <CenteredContainer key={`${key}-centered`} disableDefaultPadding={content.type == 'attributeTable'}>
                {renderContent(content, key)}
                {isOtherDataBlock(frontendItem) && <MoreInformationContent moreInformation={moreInformationData} />}
              </CenteredContainer>
            ))}
            {frontendItem.name === 'conditionData' &&
              estate.energy &&
              estate.energy.efficiencyClass &&
              estate.energy.powerConsumption && (
                <CenteredContainer width={1} mt={{ xs: 4, lg: 8 }}>
                  <EnergyConsumption
                    efficiencyClass={estate.energy.efficiencyClass}
                    powerConsumption={estate.energy.powerConsumption}
                  />
                </CenteredContainer>
              )}
          </SectionContainer>

          {!isIF6Scope && isLocationBlock(frontendItem) && (
            <SectionContainer sx={{ '@media print': { display: 'none' } }} data-id="dynamic-block-traveltime">
              <CenteredContainer>
                <TravelTime address={estate.address} lat={estate.lat} lng={estate.lng} />
              </CenteredContainer>
            </SectionContainer>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

DynamicBlocks.displayName = 'DynamicBlocks';
