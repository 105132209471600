import { Box, Table, TableBody, TableCell, TableFooter, TableRow } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React, { FunctionComponent } from 'react';

import { LoansFinanceData } from '../../../../../types/loans';
import { FinanceConditionsStyles } from './FinanceConditions.styles';
import FinanceConditionsTooltipButton from './FinanceConditionsTooltipButton';

interface FinanceLoanAmountTableProps {
  estate: EstateDetail;
  loansData: LoansFinanceData;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

const FinanceLoanAmountTable: FunctionComponent<FinanceLoanAmountTableProps> = ({ estate, loansData }) => {
  const numberFormatter = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const tableStyles = FinanceConditionsStyles(theme);

  return (
    <Table className="finance-conditions-table" padding="none" sx={tableStyles}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" className="finance-block-text">
              {t('detailPage.purchasePrice')}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" fontWeight="bold" className="finance-block-text">
              {numberFormatter(estate.priceNumeric.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box display="flex" alignItems="center" gap={3}>
              <Typography variant="body2" className="finance-block-text">
                {t('detailPage.additionalPurchaseCosts')}
              </Typography>
              <FinanceConditionsTooltipButton text="detailPage.additionalPurchaseCostsTooltipText" />
            </Box>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" fontWeight="bold" className="finance-block-text">
              + {numberFormatter(loansData.additionalCosts.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box display="flex" alignItems="center" gap={3}>
              <Typography variant="body2" className="finance-block-text">
                {t('detailPage.equityCapital')}
              </Typography>
              <FinanceConditionsTooltipButton text="detailPage.equityCapitalTooltipText" />
            </Box>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" fontWeight="bold" className="finance-block-text">
              - {numberFormatter(loansData.downPayment.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            <Typography variant="body2" color={theme.palette.green.main}>
              {t('detailPage.loanAmount')}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" fontWeight="bold" color={theme.palette.green.main}>
              {numberFormatter(loansData.loanAmount.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

FinanceLoanAmountTable.displayName = 'FinanceLoanAmountTable';

export default FinanceLoanAmountTable;
