import { Financing } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { forIn } from 'lodash-es';

import { FinanceData } from '../types';

const mapping = {
  objectType: 'objArt',
  referenceId: 'refId',
  place: 'ort',
  zip: 'plz',
  street: 'strasse',
  streetNumber: 'hausNr',
  livingSpace: 'wofl',
  landArea: 'grfl',
  constructionYear: 'baujahr',
  basement: 'keller',
  floorCount: 'anzEtagen',
  garageCount: 'anzGaragen',
  parkingSpaceCount: 'anzStellpl',
  energyEfficiencyStandard: 'effzStd',
  energyEfficiencyClass: 'effzKlasse',
  usageType: 'nutzungsart',
  brokerageFee: 'maklerProvision',
};

export const convertApiParamsToFinanceParams = (financing: Financing): FinanceData => {
  const financingParams = {};
  const apiParams = financing.linkParams;
  if (apiParams) {
    forIn(mapping, (apiParamName, financeQueryParam) => {
      if (apiParamName === 'keller') {
        financingParams[financeQueryParam] = apiParams[apiParamName] === 'J';
      } else {
        financingParams[financeQueryParam] = apiParams[apiParamName];
      }
    });
  }
  return <FinanceData>financingParams;
};
