import i18next from 'i18next';
import React from 'react';

import { NotFoundBox } from './NotFoundBox/NotFoundBox';

export type EstateNotFoundProps = {
  className?: string;
};

export const EstateNotFound = ({ className }: EstateNotFoundProps): JSX.Element => {
  const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

  return (
    <NotFoundBox
      headline={t('detailPage.estateNotFoundHeadline')}
      subline={t('detailPage.estateNotFoundSubline')}
      paddingY={{ xs: 8, lg: 9 }}
      className={className}
    />
  );
};

EstateNotFound.displayName = 'EstateNotFound';
