import { Option } from './estateAd';

export enum EnergyPrimarySourceType {
  OIL = 'equipment_fuel_oil',
  GAS = 'equipment_fuel_gas',
  ELECTRICAL = 'equipment_fuel_electrical',
  SOLAR = 'equipment_fuel_solar',
  GEOTHERMAL = 'equipment_fuel_geothermal',
  DISTANCE = 'equipment_fuel_distance',
  PELLET = 'equipment_fuel_pellet',
  COAL = 'equipment_fuel_coal',
  WOOD = 'equipment_fuel_wood',
  LIQUIDGAS = 'equipment_fuel_liquid_gas',
  LIGHT_GAS = 'equipment_light_gas',
  HEAVY_GAS = 'equipment_heavy_gas',
  DISTRICT_HEATING_STEAM = 'district_heating_steam',
  WOOD_CHIPS = 'wood_chips',
  COAL_COKE = 'coal_coke',
  LOCAL_HEATING = 'local_heating',
  HEAT_SUPPLY = 'heat_supply',
  BIO_ENERGY = 'bio_energy',
  WIND_POWER = 'wind_power',
  HYDRO_POWER = 'hydro_power',
  ENVIRONMENTAL_HEAT = 'environmental_heat',
  CHP_FOSSIL = 'CHP_fossil',
  CHP_RENEWABLE = 'CHP_renewable',
  CHP_REGENERATIVE = 'CHP_regenerative',
  CHP_BIO = 'CHP_bio',
}

export const getAllEnergyPrimarySourceTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EnergyPrimarySourceType.GEOTHERMAL,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.geothermalEnergy'),
    },
    {
      value: EnergyPrimarySourceType.SOLAR,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.solar'),
    },
    {
      value: EnergyPrimarySourceType.PELLET,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.pellet'),
    },
    {
      value: EnergyPrimarySourceType.GAS,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.gas'),
    },
    {
      value: EnergyPrimarySourceType.OIL,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.oil'),
    },
    {
      value: EnergyPrimarySourceType.DISTANCE,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.distance'),
    },
    {
      value: EnergyPrimarySourceType.ELECTRICAL,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.electrical'),
    },
    {
      value: EnergyPrimarySourceType.COAL,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.coal'),
    },
    {
      value: EnergyPrimarySourceType.LIGHT_GAS,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.lightGas'),
    },
    {
      value: EnergyPrimarySourceType.HEAVY_GAS,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.heavyGas'),
    },
    {
      value: EnergyPrimarySourceType.LIQUIDGAS,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.liquidGas'),
    },
    {
      value: EnergyPrimarySourceType.DISTRICT_HEATING_STEAM,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.districtHeatingSteam'),
    },
    {
      value: EnergyPrimarySourceType.WOOD,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.wood'),
    },
    {
      value: EnergyPrimarySourceType.WOOD_CHIPS,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.woodChips'),
    },
    {
      value: EnergyPrimarySourceType.COAL_COKE,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.coalCoke'),
    },
    {
      value: EnergyPrimarySourceType.LOCAL_HEATING,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.localHeating'),
    },
    {
      value: EnergyPrimarySourceType.HEAT_SUPPLY,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.heatSupply'),
    },
    {
      value: EnergyPrimarySourceType.BIO_ENERGY,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.bioEnergy'),
    },
    {
      value: EnergyPrimarySourceType.WIND_POWER,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.windPower'),
    },
    {
      value: EnergyPrimarySourceType.HYDRO_POWER,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.hydroPower'),
    },
    {
      value: EnergyPrimarySourceType.ENVIRONMENTAL_HEAT,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.environmentalHeat'),
    },

    {
      value: EnergyPrimarySourceType.CHP_FOSSIL,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.CHP_fossil'),
    },
    {
      value: EnergyPrimarySourceType.CHP_RENEWABLE,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.CHP_renewable'),
    },
    {
      value: EnergyPrimarySourceType.CHP_REGENERATIVE,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.CHP_regenerative'),
    },
    {
      value: EnergyPrimarySourceType.CHP_BIO,
      label: t('forms.estateAd.energyData.energypassPrimarySource.select.CHP_bio'),
    },
  ];
};
