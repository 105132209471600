import { InputAdornment, SvgIconProps } from '@mui/material';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import React, { MouseEventHandler } from 'react';

interface Props {
  label: string;
  value?: string;
  Icon: React.FC<SvgIconProps>;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export const ReadonlyFilterInput: React.FunctionComponent<Props> = ({ label, value, Icon, onClick }: Props) => (
  <TextField
    fullWidth
    label={label}
    variant="outlined"
    value={value}
    InputProps={{
      readOnly: true,
      startAdornment: (
        <InputAdornment position="start">
          <Icon />
        </InputAdornment>
      ),
    }}
    inputProps={{
      style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingLeft: 0,
      },
    }}
    onClick={onClick}
  />
);

ReadonlyFilterInput.displayName = 'ReadonlyFilterInput';
