import { Option } from './estateAd';

export enum FlatType {
  GROUND_FLOOR = 'ground_floor',
  FLOOR = 'floor',
  ATTIC = 'attic',
  MAISONETTE = 'maisonette',
  LOFT = 'loft',
  PENTHOUSE = 'penthouse',
}

export const getAllFlatTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: FlatType.GROUND_FLOOR,
      label: t('forms.estateAd.estateData.flatTypeOption.groundFloor'),
    },
    {
      value: FlatType.FLOOR,
      label: t('forms.estateAd.estateData.flatTypeOption.floor'),
    },
    {
      value: FlatType.ATTIC,
      label: t('forms.estateAd.estateData.flatTypeOption.attic'),
    },
    {
      value: FlatType.MAISONETTE,
      label: t('forms.estateAd.estateData.flatTypeOption.maisonette'),
    },
    {
      value: FlatType.LOFT,
      label: t('forms.estateAd.estateData.flatTypeOption.loft'),
    },
    {
      value: FlatType.PENTHOUSE,
      label: t('forms.estateAd.estateData.flatTypeOption.penthouse'),
    },
  ];
};
