import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateItem, EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { isEqual, merge } from 'lodash-es';
import { useEffect, useRef } from 'react';

import { ResultListTypes } from '../../../config';
import { Logger, removeBrackets, removeEmptyParams } from '../../../utils';

export type UseFetchMapItemsProps = (
  searchParams: EstateSearchProps,
  resultListType: ResultListTypes,
  mapEstates: EstateItem[],
  setMapEstates: React.Dispatch<React.SetStateAction<EstateItem[]>>,
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>
) => void;

export const useMapEstates: UseFetchMapItemsProps = (
  searchParams,
  resultListType,
  mapEstates,
  setMapEstates,
  setShowLoader
) => {
  const cachedSearchParams = useRef(searchParams);

  useEffect(() => {
    if (
      resultListType === ResultListTypes.MAP &&
      (mapEstates.length === 0 || !isEqual(searchParams, cachedSearchParams.current))
    ) {
      cachedSearchParams.current = searchParams;

      let didCancel = false;

      const fetchMapEstates = async () => {
        const endlessLimit = 100000;
        const query = removeEmptyParams(
          merge({}, removeBrackets(searchParams), {
            limit: endlessLimit,
          })
        );

        setShowLoader(true);

        try {
          setShowLoader(true);
          const estatesResponse = await ApiClientProvider.getApiClient().getEstates(query);

          if (!didCancel) {
            const estates = estatesResponse.estates;
            setMapEstates(estates);
          }
        } catch (e) {
          Logger.error(e);
        } finally {
          setShowLoader(false);
        }
      };

      fetchMapEstates();

      return () => {
        didCancel = true;
      };
    }
  }, [searchParams, resultListType]);
};
