import { Option } from './estateAd';

export enum EnergypassYearType {
  UNTIL_MAY_2014 = 'year_2008',
  FROM_MAY_2014 = 'year_2014',
  NOT_NECCESSARY = 'not_neccessary',
  WITHOUT = 'without',
}

export const getAllEnergypassYearSelectablesTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EnergypassYearType.UNTIL_MAY_2014,
      label: t('forms.estateAd.energyData.energyPass.date.untilMay2014'),
    },
    {
      value: EnergypassYearType.FROM_MAY_2014,
      label: t('forms.estateAd.energyData.energyPass.date.fromMay2014'),
    },
  ];
};
