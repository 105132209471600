import { Box, Grid, useTheme } from '@mui/material';
import { Avatar } from '@portals/core/src/components/Avatar/Avatar';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Broker } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React, { useCallback } from 'react';

import { PhoneNumberButton } from '../../EstateLegalModal/ConsumerInformation/PhoneNumberButton/PhoneNumberButton';
import { getStyles } from '../BrokerAndProvider.styles';
interface Props {
  broker: Broker;
  setContactFormOpen: (boolean) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const ContactInformation = ({ broker, setContactFormOpen }: Props): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme).contactInformation;

  const show = useCallback(() => {
    setContactFormOpen(true);
  }, [setContactFormOpen]);

  return (
    <Grid container spacing={{ xs: 4, sm: 6 }}>
      <Grid item xs={12} sm={7} sx={styles.brokerContainer}>
        <Box displayPrint={broker.imageUrl ? 'initial' : 'none'}>
          <Avatar alt={broker.fullSalutation} src={broker.imageUrl} sx={styles.avatar} />
        </Box>
        <Box sx={styles.phoneNumberContainer}>
          <Typography variant="h5" sx={styles.brokerTypography}>
            {broker.fullSalutation}
          </Typography>
          <Box>
            {broker.phone && <PhoneNumberButton phoneNumberString={broker.phone} iconType="telephone" />}
            {broker.mobilePhone && <PhoneNumberButton phoneNumberString={broker.mobilePhone} iconType="mobile" />}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={5} sx={styles.buttonContainer}>
        <Button fullWidth onClick={show} sx={styles.button}>
          {t('detailPage.contractProvider')}
        </Button>
      </Grid>
    </Grid>
  );
};
ContactInformation.displayName = 'ContactInformation';
