import { SxProps } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';
import React, { useCallback, useEffect, useState } from 'react';

import { Share } from '../../../../icons';
import { Logger } from '../../../../utils';
import { ActionButton } from '../ActionButton';

interface Props {
  shareTitle: string;
  sx?: SxProps<Theme>;
}

export const ActionShareButton: React.FunctionComponent<Props> = ({ ...props }: Props) => {
  const [supportsShare, setSupportsShare] = useState(false);
  useEffect(() => {
    if (navigator.share) {
      setSupportsShare(true);
    }
  }, []);

  const handleOnClick = useCallback(() => {
    navigator
      .share({
        title: props.shareTitle,
        url: location.href,
      })
      .catch((error) => Logger.info(`Error sharing: ${error.message}`));
  }, [props.shareTitle]);

  return supportsShare && <ActionButton icon={<Share fontSize="inherit" />} onClick={handleOnClick} {...props} />;
};

ActionShareButton.displayName = 'ActionShareButton';
