import { join, map, split } from 'lodash-es';
import { ParsedUrlQuery } from 'querystring';

import {
  EnergyEfficiencyClass,
  EstateDetail,
  EstateItem,
  EstateResponse,
  ObjectType,
  UsageType,
  VALID_ENERGY_EFFICIENCY_CLASS_NAMES,
} from '../ApiClientTypes';
import { ConfigProvider } from '../Config';
import { apiFrontendMapping as _apiFrontendMapping } from './ApiFrontendMapping';
import { mapEstateDetailApiResponse as mapEstateDetailApiResponseImmobilienApi } from './ImmobilienApi/EstateResponse';
import { mapEstateListItemData as mapEstateListItemDataImmobilienApi } from './ImmobilienApi/MapEstateListItemData';
import { mapEstateDetailApiResponse as mapEstateDetailApiResponseSipApi } from './SipApi/EstateResponse';
import { mapEstateListItemData as mapEstateListItemDataSipApi } from './SipApi/MapEstateListItemData';

// TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: Reduce to direct export of mapEstateDetailApiResponseImmobilienApi
export const mapEstateDetailApiResponse = (estateResponse): EstateDetail => {
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  return useImmobilienApi
    ? mapEstateDetailApiResponseImmobilienApi(estateResponse)
    : mapEstateDetailApiResponseSipApi(estateResponse);
};

// TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: Reduce to direct export of mapEstateListItemDataImmobilienApi
export const mapEstateListItemData = (estate: EstateResponse): EstateItem => {
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  return useImmobilienApi ? mapEstateListItemDataImmobilienApi(estate) : mapEstateListItemDataSipApi(estate);
};

function isValidName(efficiencyClass): efficiencyClass is EnergyEfficiencyClass {
  return VALID_ENERGY_EFFICIENCY_CLASS_NAMES.includes(efficiencyClass);
}

export const getEnergyData = (data) => {
  const energyEfficiencyClassName = data.class.toUpperCase();
  if (isValidName(energyEfficiencyClassName)) {
    return {
      efficiencyClass: energyEfficiencyClassName,
      powerConsumption: data.consumption,
    };
  }
  return null;
};

export const mapIF6SearchParamsToSipApiParams = (frontendParams: any) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  const sipApiParams = {
    route: `${useImmobilienApi ? 'immobilien-api/estates' : 'estate'}`,
    return_data: 'teaser_list',
  };

  for (const frontendKey in _apiFrontendMapping) {
    if (typeof frontendParams[frontendKey] !== 'undefined') {
      const apiKey = _apiFrontendMapping[frontendKey];
      sipApiParams[apiKey] = frontendParams[frontendKey];
    }
  }

  sipApiParams['zip_city_estate_id'] = getLabelsFromZipCityEstateId(frontendParams['zipCityEstateId']);
  sipApiParams['estate_type'] = sipApiParams['object_type'];
  delete sipApiParams['object_type'];

  return sipApiParams;
};

export const mapZipCityEstateIdToCollection = (value) => {
  if (value === '') {
    return [];
  }

  const zipCityEstateIds = split(value, ';');
  return map(zipCityEstateIds, (zipCityEstateId) => {
    let cityIdValue = split(zipCityEstateId, '__');
    if (cityIdValue.length < 2) {
      cityIdValue = [0, cityIdValue[0]];
    }

    return {
      value: parseInt(cityIdValue[0]),
      label: cityIdValue[1],
    };
  });
};

export const getLabelsFromZipCityEstateId = (value) => {
  const values = mapZipCityEstateIdToCollection(value);
  const labels = map(values, (valueAndLabel) => {
    return valueAndLabel.label;
  });
  return join(labels, ';');
};

export const cleanSearchParams = (query: ParsedUrlQuery): ParsedUrlQuery => {
  // im consumer wird object_type und in der IF6 wird estate_type als api parameter benutzt
  // deswegen muss die Anpassung in beiden Fällen gemacht werden
  const estateType = query.object_type || query.estate_type;

  if (estateType === ObjectType.PROPERTY) {
    delete query.usage_type;
  }

  if (estateType === ObjectType.BUSINESS) {
    query.usage_type = UsageType.BUSINESS;
    delete query.object_type;
    delete query.estate_type;
  }

  query.zip_city_estate_id = getLabelsFromZipCityEstateId(query.zip_city_estate_id);

  if (query.perimeter === '0') {
    delete query.perimeter;
  }

  return query;
};

export const apiFrontendMapping = _apiFrontendMapping;
