import { GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { find, get } from 'lodash-es';

import { generateImageUrls } from '../../../../utils';
import { ImageObject } from './ImageSection.types';

export const getAdditionalImageCount = (images: GalleryItems[], visibleImages: number): number => {
  return images.length - visibleImages;
};

export const getImageObject = (
  galleryImage: GalleryItems | undefined,
  size: string,
  seoTitle: string
): ImageObject | undefined => {
  if (!galleryImage) {
    return undefined;
  }

  const imageResources = get(galleryImage, 'resources', []);
  const image = find(imageResources, { size });

  if (!image) {
    return undefined;
  }

  const imageUrls = generateImageUrls(image.url);
  return {
    description: galleryImage.description,
    webpUrl: imageUrls.webpUrl,
    defaultUrl: imageUrls.defaultUrl,
    defaultImageType: imageUrls.defaultImageType,
    seoTitle,
  };
};
