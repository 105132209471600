import { Option } from './estateAd';

export enum ConstructionPhase {
  OBJECT_CONSTRUCTION_FINISHED = 'object_construction_finished',
  OBJECT_IN_PLANNING = 'object_in_planning',
  OBJECT_IN_CONSTRUCTION = 'object_in_construction',
}

export const getAllObjectPhases = (t: (a: string) => string): Option[] => {
  return [
    {
      value: ConstructionPhase.OBJECT_IN_CONSTRUCTION,
      label: t('forms.estateAd.estateData.constructionPhaseOption.objectInConstruction'),
    },
    {
      value: ConstructionPhase.OBJECT_IN_PLANNING,
      label: t('forms.estateAd.estateData.constructionPhaseOption.objectInPlanning'),
    },
    {
      value: ConstructionPhase.OBJECT_CONSTRUCTION_FINISHED,
      label: t('forms.estateAd.estateData.constructionPhaseOption.objectConstructionFinished'),
    },
  ];
};
