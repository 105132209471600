import { Box } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import i18next from 'i18next';
import React, { useCallback } from 'react';

import { type EstateAdItem } from '../../components';

interface EstateListItemAdProps {
  estate: EstateAdItem;
  handleDeleteDialog?: (e: Event, userEstateId: string) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateListItemAd = ({ estate, handleDeleteDialog }: EstateListItemAdProps): React.ReactElement => {
  const handleDelete = useCallback(
    (e) => {
      if (handleDeleteDialog) {
        handleDeleteDialog(e, estate.userEstateId);
      }
    },
    [handleDeleteDialog, estate.userEstateId]
  );

  return (
    <Box
      pt={5}
      mb="auto"
      display="flex"
      flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
      justifyContent="space-evenly"
      gap={6}
      data-testid="buttons"
      sx={{ width: { xs: '100%', md: '33%' } }}
    >
      <Button color="secondary" onClick={handleDelete} sx={{ whiteSpace: 'nowrap' }}>
        {t('userDashboard.estateAdList.button.close')}
      </Button>
      <Button color="secondary" disabled={estate.state !== 'published'} sx={{ whiteSpace: 'nowrap' }}>
        {t('userDashboard.estateAdList.button.show')}
      </Button>
    </Box>
  );
};

EstateListItemAd.displayName = 'EstateListItemAd';
