import { Typography } from '@portals/core/src/components/Typography/Typography';
import React, { FunctionComponent } from 'react';

interface SectionHeadlineProps {
  headline: string;
}

export const SectionHeadline: FunctionComponent<SectionHeadlineProps> = ({ headline }: SectionHeadlineProps) => {
  return (
    <Typography variant="h3" mb={{ xs: 6, lg: 8 }} sx={{ '@media print': { fontSize: '16px' } }}>
      {headline}
    </Typography>
  );
};
SectionHeadline.displayName = 'SectionHeadline';
