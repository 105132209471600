import { forIn, replace } from 'lodash-es';

import { Energy, EstateDetail } from '../../ApiClientTypes';
import { getEnergyData } from '../ApiClientMapping';

// TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
export const mapEstateDetailApiResponse = (estateResponse): EstateDetail => {
  const partner = estateResponse.partner
    ? {
        headline: estateResponse.partner.headline,
        text: estateResponse.partner.text,
        logoUrl: estateResponse.partner.logo_url,
      }
    : null;

  const mainFacts = [];
  forIn(estateResponse.mainfacts, (value, key) => {
    mainFacts.push({
      label: key,
      value: value,
    });
  });
  const priceNumeric: number = +replace(mainFacts[0].value, new RegExp('(\\.| |€)', 'g'), '');

  const energyData: Energy = estateResponse.energy ? getEnergyData(estateResponse.energy) : null;

  return {
    id: estateResponse.id,
    externId: estateResponse.externId,
    images: estateResponse.galleryImages,
    instituteCode: estateResponse.blz,
    title: estateResponse.title,
    subtitle: estateResponse.subtitle,
    frontendItems: estateResponse.frontend.items,
    moreInformationData: estateResponse.attachments,
    lat: estateResponse.geo.coordinates.lat,
    lng: estateResponse.geo.coordinates.lng,
    address: estateResponse.geo.address,
    partner: partner,
    broker: {
      phone: estateResponse.broker.phone,
      mobilePhone: estateResponse.broker.mobilePhone,
      email: estateResponse.broker.email,
      imageUrl: estateResponse.broker.imageUrl,
      fullSalutation: estateResponse.broker.fullSalutation,
      firstName: estateResponse.broker.name,
      lastName: estateResponse.broker.lastname,
    },
    provider: estateResponse.provider,
    priceIndicator: estateResponse.priceIndicator || null,
    priceNumeric,
    mainFacts: mainFacts,
    energy: energyData,
    financing: estateResponse.financing,
    imprint: {
      company: estateResponse.imprint.company,
      structured: estateResponse.imprint.imprint_struct,
      plain: estateResponse.imprint.imprint_plain,
      info: estateResponse.imprint.imprint_info,
      odr: estateResponse.imprint.odr_text,
    },
    contact: {
      privacyPolicy: estateResponse.contact.privacy,
      consumerInformation: estateResponse.contact.consumer_information,
      contactPhoneRequired: estateResponse.contact.phone_required,
      contactAddressRequired: estateResponse.contact.address_required,
    },
    seo: estateResponse.seo,
    isUserEstate: estateResponse.isUserEstate,
  };
};
