const childDaycare = ['B040020015'];
const restaurants = [
  'B080020042',
  'B080020015',
  'B080020062',
  'B080020020',
  '080020028',
  'B080020024',
  'B080020040',
  'B080020051',
  'B080020046',
  'B080020053',
  'B080020037',
];
const supermarkets = ['B070030037', 'B070030103', 'B070030037', 'B070030069'];
const pharmacy = ['B120020001'];

export const allBranches = [...childDaycare, ...restaurants, ...supermarkets, ...pharmacy];

export type labeledBranch = {
  label: string;
  ids: Array<string>;
};

export const labeledBranches = [
  {
    label: 'supermarkets',
    ids: supermarkets,
  },
  {
    label: 'pharmacy',
    ids: pharmacy,
  },
  {
    label: 'childDaycare',
    ids: childDaycare,
  },
  {
    label: 'restaurants',
    ids: restaurants,
  },
];
