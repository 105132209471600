import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { useCallback } from 'react';

import { EstateLegalModal } from '../EstateLegalModal/EstateLegalModal';
import { BrokerContactForm } from './BrokerContactForm/BrokerContactForm';
import { BrokerContactWrapper } from './BrokerContactWrapper/BrokerContactWrapper';

interface Props {
  estate: EstateDetail;
  contactFormOpen: boolean;
  setContactFormOpen: (boolean) => void;
}

export const BrokerContactModal: React.FunctionComponent<Props> = ({
  estate,
  contactFormOpen,
  setContactFormOpen,
}: Props) => {
  const show = useCallback(() => {
    setContactFormOpen(true);
  }, [setContactFormOpen]);

  const close = useCallback(() => {
    setContactFormOpen(false);
  }, [setContactFormOpen]);

  return (
    <>
      <BrokerContactWrapper broker={estate.broker} providerCompany={estate.provider?.company} handleClick={show} />
      <EstateLegalModal open={contactFormOpen} onClose={close} dialogType="contact">
        <BrokerContactForm estate={estate} showHeadline={false} />
      </EstateLegalModal>
    </>
  );
};

BrokerContactModal.displayName = 'BrokerContactModal';
