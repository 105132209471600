import { Box, Fade, Grid } from '@mui/material';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import React, { useCallback } from 'react';

import { CustomizeSearchButton, EstateResultSortOrder, SearchResultHeadline } from '../../../../components';
import { ResultListTypes } from '../../../../config';
import { EstateResultFilterView } from '../../../EstateResultFilterView/EstateResultFilterView';
import { EstateResultTypeToggle } from '../../../EstateResultFilterView/EstateResultTypeToggle/EstateResultTypeToggle';
import { getStyles } from './FilterAndResultControlView.styles';
import { FilterAndResultControlViewProps } from './FilterAndResultControlView.types';
import { getNumberOfActiveFilters } from './FilterAndResultControlView.utils';

export const FilterAndResultControlView: React.FC<FilterAndResultControlViewProps> = ({
  searchParams,
  totalItems,
  estates,
  onClickSaveSearch,
  saveSearchButtonLabel,
  filterValues,
  showResultFilterDrawer,
  resultListType,
  onResultListToggleChange,
  onResultListSortChange,
  estateResultFilterViewInView,
  estateResultFilterViewRef,
}: FilterAndResultControlViewProps) => {
  const showResultFilterDrawerCallback = useCallback(
    () => showResultFilterDrawer(true, null),
    [showResultFilterDrawer]
  );

  const isHeaderSticky = estateResultFilterViewInView === false;
  const styles = getStyles(isHeaderSticky);

  return (
    <>
      <FinalContainer sx={styles.finalContainer} rowGap={0}>
        <Box sx={styles.headerContainer}>
          <SearchResultHeadline
            searchParams={searchParams}
            totalItems={totalItems}
            onClickSaveSearch={onClickSaveSearch}
            saveSearchButtonLabel={saveSearchButtonLabel}
            isSaveSearchButtonDisabled={false}
          />
        </Box>
        <Box ref={estateResultFilterViewRef} sx={styles.estateResultFilterViewContainer}>
          <EstateResultFilterView
            filterValues={filterValues}
            numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
            showResultFilterDrawer={showResultFilterDrawer}
          />
        </Box>
      </FinalContainer>
      <Box sx={styles.resultListControlsWrapper}>
        <FinalContainer sx={styles.resultListControlsFinalContainer}>
          <Grid container sx={styles.resultListControlsCustomGridContainer}>
            <Grid item xs={12} sx={styles.mobileSearchResultHeadline}>
              <SearchResultHeadline
                searchParams={searchParams}
                totalItems={totalItems}
                onClickSaveSearch={onClickSaveSearch}
                saveSearchButtonLabel={saveSearchButtonLabel}
                isSaveSearchButtonDisabled={false}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={9} lg={8}>
              {estates.length > 0 && (
                <Box sx={styles.estateResultTypeToggle}>
                  <EstateResultTypeToggle value={resultListType} onChange={onResultListToggleChange} />
                  {resultListType === ResultListTypes.LIST && (
                    <Box sx={styles.estateResultSortOrder}>
                      <EstateResultSortOrder
                        value={filterValues.sortBy}
                        onChange={onResultListSortChange}
                        renderValueWithLabel
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={4}>
              <Fade in={!estateResultFilterViewInView}>
                <Box sx={styles.customizeSearchButton}>
                  <CustomizeSearchButton
                    numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
                    onClick={showResultFilterDrawerCallback}
                  />
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </FinalContainer>
      </Box>
    </>
  );
};

FilterAndResultControlView.displayName = 'FilterAndResultControlView';
