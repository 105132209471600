import { Box, Grid, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { EstateDetail as EstateDetailType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { join } from 'lodash-es';
import React from 'react';

import { getStyles } from '../BrokerAndProvider.styles';

interface Props {
  estate: EstateDetailType;
}

export const ContactHeader = ({ estate }: Props): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme).contactHeader;

  const addressArray = [
    `${estate.provider?.address.streetName} ${estate.provider?.address.streetNumber}`,
    `${estate.provider?.address.zip} ${estate.provider?.address.city}`,
  ];
  const isProviderAddressEmpty = Boolean(addressArray.join('').trim().length === 0);
  const hasLogoURL = Boolean(estate.partner?.logoUrl);

  return (
    <Grid container spacing={6} flexDirection={hasLogoURL ? { sm: 'row-reverse' } : 'row'}>
      {hasLogoURL && (
        <Grid item sm={5} sx={styles.partnerOuterContainer}>
          <Box sx={styles.partnerInnerContainer}>
            <Box
              component="img"
              src={estate.partner?.logoUrl}
              title={estate.partner?.text}
              alt={estate.partner?.text}
              sx={styles.partnerImage}
            />
          </Box>
        </Grid>
      )}
      {!isProviderAddressEmpty && estate.provider?.company && (
        <Grid item sm={7} sx={styles.companyContainer}>
          <Typography variant="h4" className="estate-provider-address" sx={styles.companyTypography}>
            {estate.provider?.company}
          </Typography>
          <Typography variant="body1" className="estate-provider-address" sx={styles.addressTypography}>
            {join(addressArray, ', ')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
ContactHeader.displayName = 'ContactHeader';
