import { Box, Link as MUILink, Typography } from '@mui/material';
import { map } from 'lodash-es';
import React, { useCallback, useContext } from 'react';

import {
  Carousel,
  EstateImage,
  EstateItemHeader,
  EstateListItemProps,
  EyeCatcher,
  MainFacts,
  PartnerLogo,
  SaveButton,
} from '../../components';
import { AppConfig } from '../../config';
import { PageNavigationContext } from '../../context';
import { generateImageUrls } from '../../utils';
import { getStyles } from './SimpleEstateDetails.styles';

export const SimpleEstateDetails: React.FC<EstateListItemProps> = ({
  id,
  title,
  subtitle,
  price,
  eyeCatcher,
  mainFacts,
  images,
  partnerLogo,
}) => {
  const styles = getStyles();
  const { estateDetailsPath } = AppConfig;
  const url = `${estateDetailsPath}${encodeURIComponent(id)}.html`;
  const navigate = useContext(PageNavigationContext);

  const generateImageObject = (imageUrl, imageDescription) => {
    const imageUrls = generateImageUrls(imageUrl);
    return {
      description: imageDescription,
      webpUrl: imageUrls.webpUrl,
      defaultUrl: imageUrls.defaultUrl,
      defaultImageType: imageUrls.defaultImageType,
    };
  };

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      navigate(url);
    },
    [navigate, url]
  );

  return (
    <MUILink href={url} sx={styles.link} onClick={handleClick}>
      <Box display="flex" flexWrap="wrap">
        <Box flexGrow="1" flexBasis="0" minWidth="160px">
          <Box
            bgcolor="#ececec"
            borderRadius={2}
            position="relative"
            height={{ xs: '106px', lg: '226px' }}
            minWidth="100%"
            sx={styles.box}
            display="flex"
            justifyContent="center"
            overflow="hidden"
          >
            <Carousel
              sx={{
                height: '226px',
                width: '100%',
                borderRadius: '12px',
                display: { xs: 'none', lg: 'block' },
              }}
            >
              {map(images, (imageUrl) => (
                <EstateImage
                  key={imageUrl}
                  imageObject={generateImageObject(imageUrl, title)}
                  imageStyle={styles.carouselImage as React.CSSProperties}
                />
              ))}
            </Carousel>
            {images.length > 0 && (
              <EstateImage
                key={images[0]}
                imageObject={generateImageObject(images[0], title)}
                imageStyle={styles.image as React.CSSProperties}
                sx={styles.estateImage}
              />
            )}
            {partnerLogo && <PartnerLogo imgSrc={partnerLogo} />}
            <SaveButton id={id} />
          </Box>
        </Box>
        <Box flexGrow="1" flexBasis="0" minWidth="100px" pl={5}>
          <Box display="flex" flexDirection="column" height={1} maxWidth={{ xs: '165px', lg: '100%' }}>
            <Box
              flexGrow={1}
              display={{
                xs: 'none',
                lg: 'block',
              }}
            >
              <Box>
                <EstateItemHeader title={title} subtitle={subtitle} />
                <EyeCatcher list={eyeCatcher} />
              </Box>
            </Box>
            <Box>
              <MainFacts list={mainFacts} />
              <Typography display="inline" variant="h4">
                {price}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </MUILink>
  );
};

SimpleEstateDetails.displayName = 'SimpleEstateDetails';
