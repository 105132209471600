import axios, { AxiosResponse } from 'axios';
import { stringifyUrl } from 'query-string';

import { ApiClient } from '../general/ApiClient';
import {
  AutoCompleteOption,
  EstateContactMessageProps,
  EstateCountResponse,
  EstateDetail,
  EstateListResponse,
  EstateSearchProps,
  FinanceData,
  MapQueryParams,
  TravelTimeQueryParams,
  UserEventData,
} from '../general/ApiClientTypes';
import { ConfigProvider } from '../general/Config';

export class FrontendApiClient implements ApiClient {
  private readonly frontendUrl: string;

  constructor() {
    this.frontendUrl = ConfigProvider.getConfig().get('IMMOBILIEN_FRONTEND_URL');
  }

  public async getEstateDetail(): Promise<EstateDetail> {
    throw new Error('Method not implemented.');
  }

  public async getEstates(query: any): Promise<EstateListResponse> {
    const url = stringifyUrl({ url: `${this.frontendUrl}/api/search`, query }, { arrayFormat: 'bracket' });

    const estatesResponse = await axios.get(url);

    return estatesResponse.data as EstateListResponse;
  }

  public async getEstateCount(searchParams: EstateSearchProps): Promise<EstateCountResponse> {
    const url = stringifyUrl(
      { url: `${this.frontendUrl}/api/estate-total-items`, query: searchParams },
      { arrayFormat: 'bracket' }
    );

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public async getAutoCompleteOptions(term: string): Promise<Array<AutoCompleteOption>> {
    const response = await axios.get(`${this.frontendUrl}/api/autocomplete?term=${term}`);

    return response.data;
  }

  public async getLoans(query: FinanceData): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/loans`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getPolygons(query: MapQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/polygon`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getPois(query: MapQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/poi`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getTravelTimes(query: TravelTimeQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/distance`, query });
    const response = axios.get(uri);

    return response;
  }

  public async clientSendEstateContactMessage(contactMessage: EstateContactMessageProps): Promise<boolean> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/contact-form-submit` }, { arrayFormat: 'bracket' });

    try {
      const response = await axios.post(uri, contactMessage, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.success || false;
    } catch (e) {
      return false;
    }
  }

  public async sendUserEvent(userEvent: UserEventData): Promise<void> {
    const url = stringifyUrl({ url: `${this.frontendUrl}/api/user-event` }, { arrayFormat: 'bracket' });
    try {
      const response = await axios.post(url, userEvent, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return;
    }
  }
}
