import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { useCallback } from 'react';

import { trackBrokerContactSent } from '../../../../../utils';
import { EstateContactForm } from './EstateContactForm/EstateContactForm';

interface Props {
  estate: EstateDetail;
  showHeadline: boolean;
}

export const BrokerContactForm: React.FunctionComponent<Props> = ({ estate, showHeadline }: Props) => {
  const onContactFormSubmit = useCallback(() => {
    trackBrokerContactSent(estate);
  }, [estate]);

  return (
    <EstateContactForm
      estateId={estate.id}
      isUserEstate={estate.isUserEstate}
      instituteCode={estate.instituteCode}
      instituteName={estate.provider?.company}
      brokerFirstName={estate.broker.firstName}
      brokerLastName={estate.broker.lastName}
      brokerFullSalutation={estate.broker.fullSalutation}
      brokerImageUrl={estate.broker.imageUrl}
      phoneRequired={estate.contact?.contactPhoneRequired}
      addressRequired={estate.contact?.contactAddressRequired}
      privacyPolicy={estate.contact?.privacyPolicy}
      consumerInformation={estate.contact?.consumerInformation}
      showHeadline={showHeadline}
      onSubmit={onContactFormSubmit}
    />
  );
};

BrokerContactForm.displayName = 'BrokerContactForm';
