import { Box } from '@mui/material';
import React from 'react';

import { HorizontalSwipeBar, HorizontalSwipeBarStyles as styles } from '../../../../../../components';
import { labeledBranches } from '../../../../../../config';
import { POIControlPanelButton } from './POIControlPanelButton';

export interface POIControlPanelProps {
  onSelect: (number) => void;
  selectedIndex: number;
}

export const POIControlPanel = ({ onSelect, selectedIndex }: POIControlPanelProps): React.ReactElement | null => {
  return (
    <HorizontalSwipeBar>
      <Box sx={styles.poiContainer}>
        <Box sx={styles.buttonContainer}>
          {labeledBranches.map((group, index) => (
            <POIControlPanelButton
              key={`${group.label}-${index}`}
              group={{ label: group.label }}
              index={index}
              selectedIndex={selectedIndex}
              onSelect={onSelect}
            />
          ))}
        </Box>
      </Box>
    </HorizontalSwipeBar>
  );
};

POIControlPanel.displayName = 'POIControlPanel';
