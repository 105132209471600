import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { get } from 'lodash-es';

import { isIF6Context } from '../utils';

const isIF6Scope = isIF6Context();
const BANK_DOMAIN_PLACEHOLDER = '{domainForBankcode}';

const certificatePartners = {
  29050101: {
    domain: 'www.sparkasse-bremen.de',
    enabled: true,
  },
  25050180: {
    domain: 'www.sparkasse-hannover.de',
    enabled: true,
  },
  20050550: {
    domain: 'www.haspa.de',
    enabled: true,
  },
  37050198: {
    domain: 'www.sparkasse-koelnbonn.de',
  },
  51050015: {
    domain: 'www.naspa.de',
  },
  70150000: {
    domain: 'www.sskm.de',
  },
  60450050: {
    domain: 'www.ksklb.de',
  },
  23050101: {
    domain: 'www.spk-luebeck.de',
  },
  46050001: {
    domain: 'www.sparkasse-siegen.de',
  },
  21050170: {
    domain: 'www.foerde-sparkasse.de',
  },
  25950130: {
    domain: 'www.sparkasse-hgp.de',
  },
  30050110: {
    domain: 'www.sskduesseldorf.de',
  },
};

const getDomainForBankcode = (bankCode: string) => {
  return get(certificatePartners, `${bankCode}.domain`, null);
};

export const isFinanceCertificateEnabled = (bankCode: string): boolean => {
  if (!isIF6Scope && get(certificatePartners, `${bankCode}.enabled`, false)) {
    return true;
  }

  return false;
};

export const getExternalFinanceCertificateUrl = (bankCode: string): string => {
  const url = `${ConfigProvider.getConfig().get('FINANCE_CERTIFICATE_URL')}`;
  if (url.indexOf(BANK_DOMAIN_PLACEHOLDER) !== -1) {
    return url.replace(BANK_DOMAIN_PLACEHOLDER, getDomainForBankcode(bankCode));
  }

  return url;
};
