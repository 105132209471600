import { Option } from './estateAd';

export enum EnergypassType {
  CONSUMPTION = 'energypass_type_usage',
  DEMAND = 'energypass_type_demand',
}

export const getAllEnergypassTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EnergypassType.CONSUMPTION,
      label: t('forms.estateAd.energyData.energyPass.type.usage'),
    },
    {
      value: EnergypassType.DEMAND,
      label: t('forms.estateAd.energyData.energyPass.type.demand'),
    },
  ];
};
