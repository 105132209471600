import { Box, Grid, useTheme } from '@mui/material';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { EstateDetail as EstateDetailType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { concat } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { useBookmarkContext } from '../../../context';
import { trackEstatePrint } from '../../../utils';
import { ActionPrintButton, ActionShareButton } from '../../Buttons';
import { GalleryFilterType } from '../../Gallery/GalleryButton/GalleryButton';
import { GallerySection } from './GallerySection/GallerySection';
import { getStyles } from './HeadSection.styles';
import { filterGalleryItemsByType, getImagePath, getVideos } from './HeadSection.utils';
import { ImageSection } from './ImageSection/ImageSection';
import { LogoForPrintView } from './LogoForPrintView/LogoForPrintView';

interface Props {
  estate: EstateDetailType;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const HeadSection = ({ estate }: Props): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const { renderBookmarkButton } = useBookmarkContext();

  const images = filterGalleryItemsByType(estate.images, GalleryFilterType.IMAGE);
  const videos = getVideos(estate.moreInformationData);
  const galleryItems = concat(estate.images, videos);

  const [galleryOptions, setGalleryOptions] = useState({
    open: false,
    filter: null,
    index: 0,
  });

  const triggerPrint = useCallback(() => {
    trackEstatePrint(estate);
    window.print();
  }, [estate]);

  const handleGalleryFilter = useCallback((filter: GalleryFilterType | null, galleryPosition: number | null) => {
    setGalleryOptions({ filter, open: true, index: galleryPosition || 0 });
  }, []);

  const handleGalleryClose = useCallback(() => {
    setGalleryOptions((prevValue) => ({ ...prevValue, open: false }));
  }, []);

  const handlePredicate = useCallback(
    (image) => {
      if (!galleryOptions.filter) {
        return true;
      }
      return image.type === galleryOptions.filter;
    },
    [galleryOptions.filter]
  );

  return (
    <>
      <FinalContainer className="header-container" sx={styles.headerContainer}>
        <Grid container>
          <Grid item className="header-action-buttons" xs={12} lg="auto" sx={styles.headerActionButtons}>
            <ActionShareButton shareTitle={`${estate.title}, ${estate.subtitle}`} />
            <ActionPrintButton onButtonClick={triggerPrint} />
            {renderBookmarkButton && renderBookmarkButton(estate.id)}
          </Grid>

          <Grid item xs={12} sx={styles.logoForPrintOuterContainer}>
            <Box sx={styles.logoForPrintInnerContainer}>
              <LogoForPrintView imagePath={getImagePath()} />
            </Box>
            {!estate.isUserEstate && (
              <Typography variant="body2" sx={styles.objectNumber} className="object-number">
                {t('detailPage.objectNumber', {
                  id: estate.id,
                  externId: estate.externId,
                  interpolation: { escapeValue: false },
                })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm md lg sx={styles.titleContainer}>
            <Typography variant="h2" sx={styles.title}>
              {estate.title}
            </Typography>
          </Grid>
        </Grid>
      </FinalContainer>
      <FinalContainer disableGutters sx={styles.imageSectionContainer}>
        <ImageSection images={images} estate={estate} handleGalleryFilter={handleGalleryFilter} />
      </FinalContainer>
      <FinalContainer disableGutters sx={styles.gallerySectionContainer}>
        <GallerySection
          items={galleryItems}
          onClick={handleGalleryFilter}
          predicate={handlePredicate}
          galleryOptions={galleryOptions}
          onClose={handleGalleryClose}
        />
      </FinalContainer>
    </>
  );
};
HeadSection.displayName = 'HeadSection';
