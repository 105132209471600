import React from 'react';

export interface LoginProvider {
  realm: string;
  keycloakUrl: string;
  clientId: string;
  redirectUri: string;
  userApiUrl: string;
  logoutUri: string;
  userAreaUri: string;
}

const host =
  typeof location !== 'undefined' ? `${location.protocol}//${location.hostname}` : process.env.NEXT_PUBLIC_HOST;

export const loginConfig: LoginProvider = {
  realm: process.env.SSO_REALM,
  keycloakUrl: process.env.SSO_URL,
  clientId: process.env.SSO_CLIENT_ID,
  redirectUri: `${host}/ssoframe/confirm.html`,
  userApiUrl: process.env.SIP_USER_API_URL,
  logoutUri: process.env.SIP_USER_LOGOUT,
  userAreaUri: process.env.SIP_USER_AREA,
};

export const LoginContext = React.createContext(loginConfig);
