import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

interface AdditionalImageLabelProps {
  additionalImageCount: number;
}

export const AdditionalImageLabel: React.FunctionComponent<AdditionalImageLabelProps> = ({
  additionalImageCount,
}: AdditionalImageLabelProps) => {
  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      justifyContent="center"
      alignItems="center"
      displayPrint="none"
      display="flex"
    >
      <Typography variant="h2" m={0} color="#FFFFFF" className="gallery-image-count">
        {`+${additionalImageCount}`}
      </Typography>
    </Box>
  );
};
AdditionalImageLabel.displayName = 'AdditionalImageLabel';
