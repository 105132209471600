import { Box } from '@mui/material';
import React from 'react';

import { SparkasseLogoForPrint } from './SparkasseLogoForPrint/SparkasseLogoForPrint';

interface Props {
  imagePath?: string;
  imageHeight?: number;
  altText?: string;
}

export const LogoForPrintView = ({ imagePath, imageHeight, altText }: Props): JSX.Element => {
  const DEFAULT_IMAGE_HEIGHT = 100;
  const DEFAULT_ALT_TEXT = '';
  const DEFAULT_LOGO_FOR_PRINT_VIEW = (
    <SparkasseLogoForPrint data-testid="head-section-logo-print" sx={{ fontSize: '150px' }} />
  );

  return (
    <Box>
      {!imagePath ? (
        DEFAULT_LOGO_FOR_PRINT_VIEW
      ) : (
        <img
          src={imagePath}
          alt={altText ? altText : DEFAULT_ALT_TEXT}
          height={`${imageHeight ? imageHeight : DEFAULT_IMAGE_HEIGHT} px`}
        />
      )}
    </Box>
  );
};

LogoForPrintView.displayName = 'LogoForPrintView';
