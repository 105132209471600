import { SxProps } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';
import React, { MouseEventHandler } from 'react';

import { Print } from '../../../../icons';
import { ActionButton } from '../ActionButton';

interface Props {
  sx?: SxProps<Theme>;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
}

export const ActionPrintButton: React.FunctionComponent<Props> = ({ onButtonClick, ...props }: Props) => {
  return <ActionButton icon={<Print fontSize="inherit" />} onClick={onButtonClick} {...props} />;
};

ActionPrintButton.displayName = 'ActionPrintButton';
