import { Button } from '@portals/core/src/components/Button/Button';
import { Mobile, Telephone } from '@portals/icons';
import React from 'react';

type Props = {
  phoneNumberString: string;
  iconType: 'mobile' | 'telephone';
};

export const PhoneNumberButton = ({ phoneNumberString, iconType }: Props): JSX.Element => {
  return (
    <Button
      size="small"
      variant="inverted"
      startIcon={iconType === 'mobile' ? <Mobile /> : <Telephone />}
      sx={{
        '&:hover': {
          cursor: 'default',
          backgroundColor: 'transparent',
        },
        '@media print': { fontSize: '13px' },
        pr: 0,
        textAlign: 'left',
      }}
    >
      {phoneNumberString}
    </Button>
  );
};
PhoneNumberButton.displayName = 'PhoneNumberButton';
