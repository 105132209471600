import { useTheme } from '@mui/material';
import { Link } from '@portals/core/src/components/Link/Link';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import { ArrowRight } from '@portals/icons/src/ArrowRight/ArrowRight';
import i18next from 'i18next';
import React, { FunctionComponent, MouseEventHandler } from 'react';

interface RequestFullAddressLinkProps {
  onClick: MouseEventHandler<HTMLAnchorElement>;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const RequestFullAddressLink: FunctionComponent<RequestFullAddressLinkProps> = ({
  onClick,
}: RequestFullAddressLinkProps) => {
  const theme = useTheme<Theme>();

  return (
    <Link
      color="inherit"
      onClick={onClick}
      sx={{ cursor: 'pointer', paddingY: { xs: 3, lg: 5 }, display: 'inline-flex' }}
      displayPrint="none"
    >
      <Typography variant="button" sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {t('requestFullAddress')}
        <ArrowRight sx={{ marginLeft: theme.spacing(3), fontSize: 'inherit' }} />
      </Typography>
    </Link>
  );
};

RequestFullAddressLink.displayName = 'RequestFullAddressLink';
