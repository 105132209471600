import { createClient } from 'redis';

import { Logger } from '../utils';

const InstanceIndex = 'redisConnection';

export const cachingAvailable = (): boolean => {
  return typeof process.env.REDIS_URL !== 'undefined';
};

export class RedisClientConnectionProvider {
  private static clearConnection() {
    if (global[InstanceIndex] === null) {
      return;
    }
    global[InstanceIndex] = null;
  }
  private static createConnection() {
    Logger.info('CREATE NEW REDIS CONNECTION!!!');
    const clientConnection = createClient({
      url: process.env.REDIS_URL,
      socket: {
        reconnectStrategy: () => {
          throw new Error('stopping redis reconnection');
        },
      },
    });
    clientConnection.on('error', () => {
      this.clearConnection();
    });
    return clientConnection;
  }
  public static async getClientConnection() {
    if (!global[InstanceIndex]) {
      global[InstanceIndex] = RedisClientConnectionProvider.createConnection();
    }

    if (!global[InstanceIndex].isOpen) {
      try {
        await global[InstanceIndex].connect();
      } catch (e) {
        Logger.warn({ Message: `could not connect to ${process.env.REDIS_URL}`, e });
      }
    }
    return global[InstanceIndex];
  }
}

export const readFromCache = async (key: string) => {
  if (!cachingAvailable()) {
    Logger.warn('no redis configured');
    return null;
  }

  const startTime = process.hrtime();
  const cacheClient = await RedisClientConnectionProvider.getClientConnection();

  if (cacheClient === null) {
    Logger.warn('no redis client for read available');
    return null;
  }
  const cacheResult = await cacheClient.get(key);
  if (cacheResult === null) {
    return null;
  }
  try {
    const durationInMilliseconds = getDurationInMilliseconds(startTime);
    Logger.info(`READ FROM CACHE for ${key} - ${durationInMilliseconds.toLocaleString()} ms`);
    return JSON.parse(cacheResult);
  } catch (e) {
    Logger.info(`error parsing json. Data is: "${cacheResult}"`);
    Logger.error(e);
  }
};

export const saveExpiringToCache = async (key: string, expire: number, val: string) => {
  if (cachingAvailable()) {
    const cacheClient = await RedisClientConnectionProvider.getClientConnection();
    if (cacheClient === null) {
      Logger.warn('no redis client for write available');
      return null;
    }
    try {
      await cacheClient.SETEX(key, expire, val);
      Logger.info(`write to Cache: ${key}`);
    } catch (e) {
      Logger.info({ Message: 'Error while saving to Cache', e });
    }
  }
};

export const saveToCache = async (key: string, val: string) => {
  if (cachingAvailable()) {
    const cacheClient = await RedisClientConnectionProvider.getClientConnection();
    if (cacheClient === null) {
      Logger.warn('no redis client for write available');
      return null;
    }

    try {
      await cacheClient.SET(key, val);
      Logger.info(`write permanent to Cache:, ${key}`);
    } catch (e) {
      Logger.info({ Message: 'Error while saving to Cache', e });
    }
  }
};

async function executeAndCache(key: string, expire: number, getFunction: () => any) {
  let value;
  try {
    const timeBefore = new Date().getTime();
    value = await getFunction();
    const totalTime = new Date().getTime() - timeBefore;
    const expireDate = new Date().getTime() + expire * 1000;

    if (value && value.noCache) {
      delete value.noCache;
      return value;
    }
    await saveToCache(key, JSON.stringify({ value, expireDate }));
    Logger.info(`Updated Cache: ${key} took ${totalTime}ms valid until ${new Date(expireDate).toISOString()}`);
  } catch (e) {
    Logger.error(e);
  }
  return value;
}

export const getCachedValue = async (key: string, expire: number, getFunction: () => any) => {
  let result = await readFromCache(key);
  if (result === undefined || result === null || expire === 0) {
    const value = await executeAndCache(key, expire, getFunction);
    result = { value };
  } else if (result.expireDate < new Date().getTime()) {
    Logger.info(`${key} is expired ${new Date(result.expireDate).toLocaleString()}`);
    //eslint-disable-next-line no-console
    executeAndCache(key, expire, getFunction).catch(console.error);
  }
  return result.value;
};

const getDurationInMilliseconds = (start) => {
  const NS_PER_SEC = 1e9;
  const NS_TO_MS = 1e6;
  const diff = process.hrtime(start);
  return (diff[0] * NS_PER_SEC + diff[1]) / NS_TO_MS;
};
