import { Box, Grid } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';

import { getPerimeterOptions } from '../../../../config';
import { EstateResultPerimeter } from '../../../EstateResultFilterView/EstateResultPerimeter/EstateResultPerimeter';

interface Props {
  fieldName: string;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const PerimeterFilter: React.FunctionComponent<Props> = ({ fieldName }: Props) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();

  const onChange = useCallback(
    (value) => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, value, true);
    },
    [setFieldTouched, setFieldValue, fieldName]
  );

  return (
    <Box px={6}>
      <Typography variant="h3">{t('perimeter')}</Typography>
      <Grid container columnSpacing={5}>
        <Grid item xs={12}>
          <EstateResultPerimeter value={get(values, fieldName)} onChange={onChange} options={getPerimeterOptions(t)} />
        </Grid>
      </Grid>
    </Box>
  );
};

PerimeterFilter.displayName = 'PerimeterFilter';
