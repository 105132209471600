import { isFrontend } from '@portals/root/src/utils/nextSSRUtils';
import { useEffect, useState } from 'react';

export type useLocationHashType = {
  isActive: boolean;
  toggleHash?: () => boolean;
  setHash?: (value: boolean) => void;
};

export const useLocationHash: (url: string) => useLocationHashType = (url: string) => {
  if (url.indexOf('#') !== 0) {
    url = `#${url}`;
  }
  const [isActive, setIsActive] = useState(isFrontend() && window.location.hash === url);
  const handlePopState = () => {
    setIsActive(window.location.hash === url);
  };
  useEffect(() => {
    addEventListener('popstate', handlePopState);
    return () => {
      removeEventListener('popstate', handlePopState);
    };
  });
  if (!isFrontend()) {
    return { isActive: false };
  }

  const setHash = (value: boolean) => {
    if (value) {
      // eslint-disable-next-line @if6/fi-no-location
      window.location.hash = url;
    } else {
      // Prevents the browser from scrolling to the top of the page.
      // eslint-disable-next-line @if6/fi-no-location
      window.location.hash = '/';
    }
    setIsActive(value);
  };
  const toggleHash = () => {
    setHash(!isActive);
    return !isActive;
  };

  return {
    isActive,
    toggleHash,
    setHash,
  };
};
