import { Box, useTheme } from '@mui/material';
import React from 'react';

import { EstateImage } from '../../../../EstateImage/EstateImage';
import { getStyles } from '../ImageSection.styles';
import { ImageObject } from '../ImageSection.types';

interface DetailImageProps {
  imageObject: ImageObject;
  onClick: React.MouseEventHandler;
}

export const DetailImage: React.FunctionComponent<DetailImageProps> = ({ imageObject, onClick }: DetailImageProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.estateImageContainer} onClick={onClick} className="image-container">
      {imageObject && <EstateImage imageObject={imageObject} imageStyle={styles.estateImage} />}
    </Box>
  );
};
DetailImage.displayName = 'DetailImage';
