import { Option } from './estateAd';

export enum EnergypassAvailablenessType {
  ENERGYPASS_NOT_REQUIRED = 'energypass_not_required',
  ENERGYPASS_AVAILABLE_FOR_INSPECTION = 'energypasss_available_for_inspection',
  ENERGYPASS_AVAILABLE = 'energypass_available',
}

export const getAllEnergypassAvailablenessTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: EnergypassAvailablenessType.ENERGYPASS_NOT_REQUIRED,
      label: t('forms.estateAd.energyData.energyPass.radioGroup.energypassNotRequired'),
    },
    {
      value: EnergypassAvailablenessType.ENERGYPASS_AVAILABLE_FOR_INSPECTION,
      label: t('forms.estateAd.energyData.energyPass.radioGroup.energypasssAvailableForInspection'),
    },
    {
      value: EnergypassAvailablenessType.ENERGYPASS_AVAILABLE,
      label: t('forms.estateAd.energyData.energyPass.radioGroup.energypassAvailable'),
    },
  ];
};

export default EnergypassAvailablenessType;
