import { MenuItem, Select } from '@mui/material';
import i18next from 'i18next';
import { map } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { getPerimeterLabelFromOptionsByValue, type OptionProps } from '../../../config';

interface EstateResultPerimeterProps {
  value?: number;
  onChange: (value: number) => void;
  options: OptionProps[];
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateResultPerimeter: React.FC<EstateResultPerimeterProps> = ({
  value,
  options,
  onChange,
}: EstateResultPerimeterProps) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSelectedValue(value);
      onChange(value);
    },
    [onChange]
  );

  const renderValue = useCallback(
    (value) => {
      return getPerimeterLabelFromOptionsByValue(t, value);
    },
    [t]
  );

  return (
    <Select
      fullWidth
      variant="outlined"
      value={selectedValue}
      onChange={handleChange}
      renderValue={renderValue}
      sx={{
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
          marginRight: 0,
        },
      }}
    >
      {map(options, (option) => (
        <MenuItem disableRipple disableTouchRipple key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

EstateResultPerimeter.displayName = 'EstateResultPerimeter';
