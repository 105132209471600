import { Box, useTheme } from '@mui/material';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { ChevronLeft } from '@portals/icons/src/ChevronLeft/ChevronLeft';
import { ChevronRight } from '@portals/icons/src/ChevronRight/ChevronRight';
import i18next from 'i18next';
import React, { SetStateAction } from 'react';
import MaterialUiCarousel from 'react-material-ui-carousel';

import { getStyles } from '../GalleryModal/GalleryModal.styles';
import { GalleryCarouselItemType } from './GalleryCarouselItem';

export interface Props {
  galleryCarouselItems: Array<GalleryCarouselItemType> | Array<GalleryCarouselItemType>;
  onActiveSlide: (state: SetStateAction<number>) => void;
  currentImagePosition: number;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const GalleryCarousel = ({
  galleryCarouselItems,
  onActiveSlide,
  currentImagePosition,
}: Props): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const showNavButtons = galleryCarouselItems.length > 1;

  const NavigationButton = ({ onClick, ariaLabel, Icon }) => (
    <IconButton
      circular
      color="default"
      component="button"
      onClick={onClick}
      sx={styles.navButton}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
      aria-label={ariaLabel}
    >
      <Icon />
    </IconButton>
  );

  return (
    <MaterialUiCarousel
      index={currentImagePosition}
      onChange={onActiveSlide}
      animation="slide"
      autoPlay={false}
      fullHeightHover={false}
      indicators={false}
      changeOnFirstRender={true}
      navButtonsAlwaysVisible
      swipe
      navButtonsWrapperProps={{
        style: {
          top: 'calc(50% - 20px)',
          bottom: '0',
          height: '54px',
        },
      }}
      NavButton={({ onClick, next, prev }) => {
        return (
          showNavButtons && (
            <Box sx={{ mx: { xs: theme.spacing(5), lg: theme.spacing(8) } }}>
              {next && <NavigationButton onClick={onClick} ariaLabel={t('gallery.nextItem')} Icon={ChevronRight} />}
              {prev && <NavigationButton onClick={onClick} ariaLabel={t('gallery.previousItem')} Icon={ChevronLeft} />}
            </Box>
          )
        );
      }}
      sx={{
        width: '100%',
      }}
    >
      {galleryCarouselItems}
    </MaterialUiCarousel>
  );
};

GalleryCarousel.displayName = 'GalleryCarousel';
