import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (): Record<string, SxProps<Theme>> => ({
  divider: {
    '@media print': { display: 'none' },
  },
  container: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 5, lg: 6 },
    paddingY: { xs: 5, lg: 6 },
    paddingX: { xs: 5, lg: 0 },
    '@media print': {
      flexDirection: 'row',
    },
  },
  mainFact: {
    paddingX: { xs: 0, lg: 6 },
  },
  mainFactLabel: {
    '@media print': { fontSize: '13px' },
  },
  mainFactValue: {
    '@media print': { fontSize: '16px' },
  },
});
