import { Option } from './estateAd';

export enum HeatingType {
  BLOCK_HEATING_STATION = 'block_heating_station',
  ELECTRIC_HEATING = 'electric_heating',
  ONEFLOOR = 'equipment_heating_onefloor',
  DISTANCE = 'equipment_heating_distance',
  UNDERFLOOR = 'equipment_heating_underfloor',
  HEATING_GAS = 'heating_gas',
  HEATING_PELLET = 'heating_pellet',
  NIGHT_STORAGE_HEATER = 'night_storage_heater',
  STOVE_HEATING = 'stove_heating',
  OIL_HEATING = 'oil_heating',
  SOLAR_HEATING = 'solar_heating',
  HEAT_PUMP = 'heat_pump',
  CENTRAL = 'equipment_heating_central',
}

export const getAllHeatingTypes = (t: (a: string) => string): Option[] => {
  return [
    {
      value: HeatingType.BLOCK_HEATING_STATION,
      label: t('forms.estateAd.energyData.heatingType.select.blockHeatingStation'),
    },
    {
      value: HeatingType.ELECTRIC_HEATING,
      label: t('forms.estateAd.energyData.heatingType.select.electricHeating'),
    },
    {
      value: HeatingType.ONEFLOOR,
      label: t('forms.estateAd.energyData.heatingType.select.oneFloorHeating'),
    },
    {
      value: HeatingType.DISTANCE,
      label: t('forms.estateAd.energyData.heatingType.select.distance'),
    },
    {
      value: HeatingType.UNDERFLOOR,
      label: t('forms.estateAd.energyData.heatingType.select.underfloor'),
    },
    {
      value: HeatingType.HEATING_GAS,
      label: t('forms.estateAd.energyData.heatingType.select.heatingGas'),
    },
    {
      value: HeatingType.HEATING_PELLET,
      label: t('forms.estateAd.energyData.heatingType.select.heatingPellet'),
    },
    {
      value: HeatingType.NIGHT_STORAGE_HEATER,
      label: t('forms.estateAd.energyData.heatingType.select.nightStorageHeater'),
    },
    {
      value: HeatingType.STOVE_HEATING,
      label: t('forms.estateAd.energyData.heatingType.select.stoveHeating'),
    },
    {
      value: HeatingType.OIL_HEATING,
      label: t('forms.estateAd.energyData.heatingType.select.oilHeating'),
    },
    {
      value: HeatingType.SOLAR_HEATING,
      label: t('forms.estateAd.energyData.heatingType.select.solarHeating'),
    },
    {
      value: HeatingType.HEAT_PUMP,
      label: t('forms.estateAd.energyData.heatingType.select.heatPump'),
    },
    {
      value: HeatingType.CENTRAL,
      label: t('forms.estateAd.energyData.heatingType.select.central'),
    },
  ];
};
