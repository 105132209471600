import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
      p: 0,
    },
  },
  dialogHeader: {
    textAlign: 'center',
    mb: { xs: 5, lg: 6 },
    mt: { lg: 7 },
    mx: 'auto',
    width: 1,
    position: 'relative',
    color: theme.palette.text.primary,
    maxWidth: theme.breakpoints.values.lg,
  },
  closeButtonContainer: {
    textAlign: 'right',
    position: { lg: 'absolute' },
    right: { lg: 0 },
    top: 0,
    display: { lg: 'flex' },
    alignItems: 'center',
    height: { lg: '100%' },
    width: { lg: 90 },
  },
  closeButton: {
    display: { lg: 'none' },
    color: 'inherit',
  },
  closeIcon: {
    fontSize: 14,
  },
  closeIconButton: {
    display: { xs: 'none', lg: 'flex' },
    border: 'none',
    color: 'inherit',
  },
  dialogContent: {
    aspectRatio: 'none',
    overflow: 'hidden',
    flexGrow: 1,
    px: { xs: 3, sm: 5, lg: 12 },
    maxWidth: theme.breakpoints.values.lg,
    m: '0 auto',
    width: 1,
    '& .leaflet-popup': {
      mb: 9,
    },
    '& .leaflet-popup-content-wrapper ': {
      transform: `translateX(30%)`,
    },
    '& .leaflet-popup-tip': {
      width: '14px',
      height: '14px',
    },
    '& .leaflet-popup-close-button': {
      display: 'none',
    },
    '& .leaflet-popup-content': {
      ...theme.typography.button,
      m: 5,
    },
  },
  mapWrapper: {
    height: '100%',
    flexGrow: 1,
  },
  dialogFooter: {
    mb: { xs: 5, lg: 7 },
    display: 'flex',
    justifyContent: 'center',
  },
});
