import { Box } from '@mui/material';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import { theme } from '@portals/core/src/themes/sde/main';
import { Area, Rooms } from '@portals/icons';
import { EstateMainFact } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React from 'react';

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const MainFacts: React.FC<{ list: EstateMainFact[] }> = ({ list }) => {
  if (list?.length === 0) {
    return null;
  }

  return (
    <Box mb={3}>
      {list.map((item, key) => (
        <Pill
          key={key}
          avatar={item.category === 'ROOMS' ? <Rooms /> : <Area />}
          label={item.category === 'ROOMS' ? `${item.value} ${t('room')}` : item.value}
          variant="filled"
          className="mainfact-pill"
          sx={{
            backgroundColor: theme.palette.grey[100],
            marginRight: 3,
            marginTop: { xs: 1, sm: 0 },
            padding: 2,
            fontSize: theme.typography.fontSize,
            cursor: 'inherit',
          }}
        />
      ))}
    </Box>
  );
};

MainFacts.displayName = 'MainFacts';
