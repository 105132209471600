import { Config, sharedConfig } from '../general/Config';

export interface frontendConfig extends sharedConfig {
  IMMOBILIEN_FRONTEND_URL?: string;
}

export type FrontendConfigKey = keyof frontendConfig;

export class FrontendConfig extends Config {
  constructor() {
    super();

    this.config = {
      NEXT_PUBLIC_COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
      NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
      NEXT_PUBLIC_GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
      NEXT_PUBLIC_GTM_ENV: process.env.NEXT_PUBLIC_GTM_ENV,
      YELLOWMAPS_SYSTEM_PARTNER: process.env.YELLOWMAPS_SYSTEM_PARTNER,
      YELLOWMAP_TILE_URL: process.env.YELLOWMAP_TILE_URL,
      YELLOWMAP_AUTOCOMPLETE_URL: process.env.YELLOWMAP_AUTOCOMPLETE_URL,
      YELLOWMAPS_SECURITY_ID: process.env.YELLOWMAPS_SECURITY_ID,
      YELLOWMAP_API_URL: process.env.YELLOWMAP_API_URL,
      LOGGING_ENDPOINT: process.env.LOGGING_ENDPOINT,
      LOCALSTORAGE_BOOKMARKS: process.env.LOCALSTORAGE_BOOKMARKS,
      FINANCE_CERTIFICATE_URL: process.env.FINANCE_CERTIFICATE_URL,
      USE_IMMOBILIEN_API: process.env.USE_IMMOBILIEN_API,
      IMMOBILIEN_API_URL: process.env.IMMOBILIEN_API_URL,
      IMMOBILIEN_API_KEY: process.env.IMMOBILIEN_API_KEY,
      IMMOBILIEN_FRONTEND_URL: process.env.IMMOBILIEN_FRONTEND_URL,
    };
  }

  public get(key: FrontendConfigKey): string {
    return this.config[key] || undefined;
  }

  public set(key: FrontendConfigKey, value: string): void {
    this.config[key] = value;
  }
}
