import { Box, Stack } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

interface Props {
  headline: string;
  text: string;
  buttonLabel?: string;
  onClick?: () => void;
  buttonIcon?: React.ReactElement;
}

export default function NotificationSparkasseUnknown({
  headline,
  text,
  buttonLabel,
  onClick,
  buttonIcon,
}: Props): React.ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={{ xs: 8, lg: 9 }}>
      <Stack direction="column" gap={6} px={5}>
        {headline && (
          <Typography variant="h3" color="text.primary">
            {headline}
          </Typography>
        )}
        {text && (
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        )}
      </Stack>
      <Button onClick={onClick} endIcon={buttonIcon}>
        {buttonLabel}
      </Button>
    </Box>
  );
}

NotificationSparkasseUnknown.displayName = 'NotificationSparkasseUnknown';
