import { Box } from '@mui/material';
import type {
  EstateDetail as EstateDetailType,
  ImprintData,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import React, { useCallback, useState } from 'react';

import {
  convertApiParamsToFinanceParams,
  trackBrokerContactOpen,
  trackBrokerImprint,
  trackEstateOpenMap,
} from '../../../utils';
import {
  BrokerAndProvider,
  BrokerContactDrawer,
  BrokerContactModal,
  CenteredContainer,
  DynamicBlocks,
  EstateLegalModal,
  FactBlock,
  FinanceBlock,
  LegalDialogTypes,
  SectionContainer,
} from '../..';
import { getStyles } from './ContentSection.styles';

interface ContentSectionProps {
  estate: EstateDetailType;
  showBrokerContactInDrawer?: boolean;
}

export const ContentSection: React.FunctionComponent<ContentSectionProps> = ({
  estate,
  showBrokerContactInDrawer = true,
}) => {
  const styles = getStyles();

  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [legalDialogProps, setLegalDialogProps] = useState({
    open: false,
    dialogType: null as LegalDialogTypes,
    content: null as string | ImprintData,
  });

  const requestContactDetails = useCallback(() => {
    trackBrokerContactOpen(estate);
    setContactFormOpen(true);
  }, [estate]);

  const handleContactFormOpen = useCallback(
    (open: boolean): void => {
      trackBrokerContactOpen(estate);
      setContactFormOpen(open);
    },
    [estate]
  );

  const onMapOpen = useCallback(() => {
    trackEstateOpenMap(estate);
  }, [estate]);

  const openImprint = useCallback(() => {
    trackBrokerImprint(estate);
    setLegalDialogProps({
      open: true,
      dialogType: 'imprint',
      content: estate.imprint,
    });
  }, [estate]);

  const openPrivacyPolicy = useCallback(() => {
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    if (useImmobilienApi) {
      window.open(estate.contact?.privacyPolicy, '_blank');
    } else {
      // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
      setLegalDialogProps({
        open: true,
        dialogType: 'privacyPolicy',
        content: estate.contact?.privacyPolicy,
      });
    }
  }, [estate.contact?.privacyPolicy]);

  const openConsumerInformation = useCallback(() => {
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    if (useImmobilienApi) {
      window.open(estate.contact?.consumerInformation, '_blank');
    } else {
      // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
      setLegalDialogProps({
        open: true,
        dialogType: 'consumerInformation',
        content: estate.contact?.consumerInformation,
      });
    }
  }, [estate.contact?.consumerInformation]);

  const closeLegalDialog = useCallback(() => {
    setLegalDialogProps((prevState) => {
      return { ...prevState, open: false };
    });
  }, []);

  const isFinancableEstate = !estate.financing.hide;

  return (
    <>
      <Box className="estate-details-data-container" sx={styles.contentSection}>
        <FactBlock estate={estate} requestContactDetails={requestContactDetails} />
        <SectionContainer sx={styles.brokerSectionWrapper}>
          <CenteredContainer sx={styles.brokerSectionContainer}>
            {showBrokerContactInDrawer ? (
              <BrokerContactDrawer
                estate={estate}
                contactFormOpen={contactFormOpen}
                setContactFormOpen={handleContactFormOpen}
              />
            ) : (
              <BrokerContactModal
                estate={estate}
                contactFormOpen={contactFormOpen}
                setContactFormOpen={handleContactFormOpen}
              />
            )}
          </CenteredContainer>
        </SectionContainer>
        <DynamicBlocks estate={estate} requestContactDetails={requestContactDetails} onMapOpen={onMapOpen} />
        {isFinancableEstate && (
          <SectionContainer>
            <FinanceBlock estate={estate} financeData={convertApiParamsToFinanceParams(estate.financing)} />
          </SectionContainer>
        )}
        <SectionContainer disableDefaultMarginBottom sx={styles.brokerAndProviderSectionWrapper}>
          <BrokerAndProvider
            estate={estate}
            openImprint={openImprint}
            openPrivacyPolicy={openPrivacyPolicy}
            openConsumerInformation={openConsumerInformation}
            setContactFormOpen={handleContactFormOpen}
          />
        </SectionContainer>
      </Box>
      <EstateLegalModal
        open={legalDialogProps.open}
        onClose={closeLegalDialog}
        dialogType={legalDialogProps.dialogType}
        content={legalDialogProps.content}
      />
    </>
  );
};
ContentSection.displayName = 'ContentSection';
