import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { GlobalTypography } from '@portals/core/src/components/GlobalTypography/GlobalTypography';
import { NavigationProvider } from '@portals/core/src/components/Navigation/Provider';
import { theme } from '@portals/core/src/themes/immobilien/main';
import { ServiceBoxContextProvider } from '@portals/root/src/contexts/service-box';
import { SparkasseAssignmentFormProvider } from '@portals/root/src/contexts/sparkasse-assignment-form';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

import { IF6Provider } from '../context';
import { initializeI18next } from '../utils/i18nextmod';

const emotionCache = createCache({ key: 'css', prepend: true });

type StorybookProviderProps = {
  children: React.ReactElement;
};

export const StorybookProvider: React.FC<StorybookProviderProps> = ({ children }) => {
  const i18n = initializeI18next();

  return (
    <IF6Provider>
      <CacheProvider value={emotionCache}>
        <I18nextProvider i18n={i18n}>
          <SparkasseAssignmentFormProvider>
            <SnackbarProvider maxSnack={3}>
              <ThemeProvider theme={theme}>
                <ServiceBoxContextProvider>
                  <NavigationProvider>
                    <CssBaseline />
                    <GlobalTypography />
                    {children}
                  </NavigationProvider>
                </ServiceBoxContextProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </SparkasseAssignmentFormProvider>
        </I18nextProvider>
      </CacheProvider>
    </IF6Provider>
  );
};
