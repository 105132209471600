import { notSpecifiedOptionValue } from '../../utils';
import { Option } from './estateAd';

export enum ParkingSpaceTypes {
  CARPORT = 'equipment_parking_carport',
  DUPLEX = 'equipment_parking_duplex',
  OUTSIDE = 'equipment_parking_outside',
  GARAGE = 'equipment_parking_garage',
  DECK = 'equipment_parking_deck',
  UNDERGROUND = 'equipment_parking_underground',
}

export const getAllParkingSpaces = (t: (a: string) => string): Option[] => {
  return [
    {
      value: notSpecifiedOptionValue,
      label: t('forms.estateAd.data.notSpecified'),
    },
    {
      value: ParkingSpaceTypes.CARPORT,
      label: t('forms.estateAd.estateData.parkingSpaceOption.carport'),
    },
    {
      value: ParkingSpaceTypes.DUPLEX,
      label: t('forms.estateAd.estateData.parkingSpaceOption.duplex'),
    },
    {
      value: ParkingSpaceTypes.OUTSIDE,
      label: t('forms.estateAd.estateData.parkingSpaceOption.outside'),
    },
    {
      value: ParkingSpaceTypes.GARAGE,
      label: t('forms.estateAd.estateData.parkingSpaceOption.garage'),
    },
    {
      value: ParkingSpaceTypes.DECK,
      label: t('forms.estateAd.estateData.parkingSpaceOption.deck'),
    },
    {
      value: ParkingSpaceTypes.UNDERGROUND,
      label: t('forms.estateAd.estateData.parkingSpaceOption.underground'),
    },
  ];
};
