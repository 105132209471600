import { notSpecifiedOptionValue } from '../../utils';
import { Option } from './estateAd';

export enum PropertyDevelopmentTypes {
  NONE = 'none',
  PARTLY = 'partly',
  FULL = 'full',
}

export const getAllPropertyDevelopmentOptions = (t: (a: string) => string): Option[] => {
  return [
    {
      value: notSpecifiedOptionValue,
      label: t('forms.estateAd.data.notSpecified'),
    },
    {
      value: PropertyDevelopmentTypes.NONE,
      label: t('conditionDevelopmentOptions.none'),
    },
    {
      value: PropertyDevelopmentTypes.PARTLY,
      label: t('conditionDevelopmentOptions.partly'),
    },
    {
      value: PropertyDevelopmentTypes.FULL,
      label: t('conditionDevelopmentOptions.full'),
    },
  ];
};
