import { Box, SxProps } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { FinalContainer, FinalGridContainer, FinalGridItem } from '@portals/core/src/components/FinalGrid/FinalGrid';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { merge } from 'lodash-es';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useInViewRef } from 'rooks';

import { EstateListItem, FloatingButton } from '../../components';
import { HeartWithSearch } from '../../icons';
import { Logger, removeBrackets, removeEmptyParams, trackEstateListItem } from '../../utils';
import { styles } from './ListView.styles';
import { ListViewProps } from './ListView.types';

const TRIGGER_INDICATOR = 5;

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const ListView = ({
  totalItems,
  estates,
  showLoader,
  isEstateList,
  saveSearchButtonLabel,
  showSaveSearchButton = true,
  onClickSaveSearch,
  isSaveSearchButtonDisabled,
  currentPage,
  setShowLoader,
  searchParams,
  setEstates,
  showLoadMoreButton,
  limit = 10,
  renderBookmarkButton,
  renderEstateLink,
}: ListViewProps): React.ReactElement => {
  const [triggerLoadNextEstatesRef, triggerLoadNextEstatesInView] = useInViewRef();
  const [isLoadMoreButtonClicked, setIsLoadMoreButtonClicked] = useState(false);
  const [alreadyTrackedEstates, setAlreadyTrackedEstates] = useState([]);

  useEffect(() => {
    for (const listItem of estates) {
      if (alreadyTrackedEstates.includes(listItem.id)) {
        continue;
      }

      trackEstateListItem(listItem.id);
      setAlreadyTrackedEstates((prevState) => {
        return [...prevState, listItem.id];
      });
    }
  }, [alreadyTrackedEstates, estates]);

  useEffect(() => {
    const fetchEstates = async () => {
      if (typeof currentPage === 'undefined') {
        return;
      }

      currentPage.current = currentPage.current + 1;
      const query = removeEmptyParams(
        merge({}, removeBrackets(searchParams), {
          page: currentPage.current,
        })
      );

      if (limit) {
        query.limit = limit;
      }

      try {
        setShowLoader(true);
        const estatesResponse = await ApiClientProvider.getApiClient().getEstates(query);
        const newEstates = estatesResponse.estates;
        const pageCount = estatesResponse.pageCount;
        setEstates((prevState) => {
          return [...prevState, ...newEstates];
        });
        currentPage.current = Math.min(pageCount, currentPage.current);
      } catch (e) {
        Logger.error(e);
      } finally {
        setShowLoader(false);
        if (isLoadMoreButtonClicked) {
          setIsLoadMoreButtonClicked(false);
        }
      }
    };

    if (
      (fetchEstates && !showLoader && estates.length > 0 && triggerLoadNextEstatesInView) ||
      isLoadMoreButtonClicked
    ) {
      fetchEstates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding missing deps will cause re-rendering loop
  }, [triggerLoadNextEstatesInView, isLoadMoreButtonClicked]);

  const estateList = useMemo(() => {
    const estatesCount = estates.length;
    return estates.map((estate: EstateItem, index: number) => {
      const isLoadNextItemsTriggerItem =
        totalItems > estatesCount && estatesCount - TRIGGER_INDICATOR === index && !showLoadMoreButton;

      const estateItem = (
        <EstateListItem
          title={estate.title}
          id={estate.id}
          price={estate.price}
          eyeCatcher={estate.eyeCatcher}
          images={estate.images}
          partnerLogo={estate.partnerLogo}
          mainFacts={estate.mainFacts}
          subtitle={estate.subtitle}
          bookmarkButton={renderBookmarkButton && renderBookmarkButton(estate.id)}
          ref={isLoadNextItemsTriggerItem ? triggerLoadNextEstatesRef : undefined}
        />
      );
      return (
        <Fragment key={estate.id}>{renderEstateLink ? renderEstateLink(estate.id, estateItem) : estateItem}</Fragment>
      );
    });
  }, [estates, showLoadMoreButton, totalItems, triggerLoadNextEstatesRef, renderEstateLink, renderBookmarkButton]);

  const LoadingControls = () => {
    const triggerLoadMore = useCallback(() => {
      setIsLoadMoreButtonClicked(true);
    }, []);

    return (
      <>
        {showLoader && !showLoadMoreButton && (
          <Box sx={styles.loadingControlContainer}>
            <LoadingDots />
          </Box>
        )}
        {showLoadMoreButton === true && totalItems > (estates?.length || 0) && (
          <Box sx={styles.loadingControlContainer}>
            <Button loading={showLoader} color="primary" onClick={triggerLoadMore}>
              {t('showMoreResults')}
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <FinalContainer
      className="list-view-container"
      sx={(isEstateList ? styles.gridContainerEstateList : styles.gridContainer) as SxProps}
    >
      <FinalGridContainer>
        <FinalGridItem
          className="list-view-grid-item"
          xs={12}
          md={12}
          lg={12}
          sx={(isEstateList ? styles.gridItemEstateList : styles.gridItemPlainList) as SxProps}
        >
          {estateList}
          <LoadingControls />
        </FinalGridItem>
      </FinalGridContainer>
      {!isSaveSearchButtonDisabled && (
        <FloatingButton
          triggerOptions={{
            threshold: showSaveSearchButton ? 100 : Number.MAX_SAFE_INTEGER,
          }}
          onClick={onClickSaveSearch}
        >
          <Box sx={styles.saveSearchButtonContainer}>
            <Box sx={styles.saveSearchButtonLabel}>{saveSearchButtonLabel}</Box>
            <HeartWithSearch />
          </Box>
        </FloatingButton>
      )}
    </FinalContainer>
  );
};

ListView.displayName = 'ListView';
